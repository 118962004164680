import { useEffect, useCallback } from 'react'

export function setRequiredErrors(result: any, model: any, properties: Array<string>, requiredMessage: string) {
    properties.forEach((property) => {
        if (!model[property]) {
            result[property] = requiredMessage
        }
    })
}

export function useWindowResizeElementUpdate(elementId: string, elementAction: Function) {
    const action = useCallback(
        (element: any) => {
            if (element) {
                elementAction(element)
            }
        },
        [elementAction]
    )

    useEffect(() => {
        const resizeHandler = () => {
            action(document.getElementById(elementId))
        }

        window.addEventListener('resize', resizeHandler)
        return () => window.removeEventListener('resize', resizeHandler)
    }, [elementId, action])

    return action
}

function calcWidthToQuarter(element: any) {
    element.style.width = window.innerWidth * 0.25 + 'px'
}
export function useCalcWidthToQuarter(elementId: string) {
    return useWindowResizeElementUpdate(elementId, calcWidthToQuarter)
}

function calcHeightToBottom(element: any) {
    element.style.height = window.innerHeight - element.getBoundingClientRect().top + 'px'
}
export function useCalcHeightToBottom(elementId: string) {
    return useWindowResizeElementUpdate(elementId, calcHeightToBottom)
}

function calcHeightToBottomPartialList(element: any) {
    element.style.height = window.innerHeight - (element.parentElement.parentElement.getBoundingClientRect().top+74) + 'px'
}
export function useCalcHeightToBottomPartialList(elementId: string) {
    return useWindowResizeElementUpdate(elementId, calcHeightToBottomPartialList)
}

function calcHeightToBottomOfDialog(element: any) {
    element.style.height = window.innerHeight - (element.getBoundingClientRect().top + 100) + 'px'
}
export function useCalcHeightToBottomOfDialog(elementId: string) {
    return useWindowResizeElementUpdate(elementId, calcHeightToBottomOfDialog)
}