import {
    asyncActionCreator,
} from './../../shared/redux/actions/common'
import { FETCH_CHARGER_INTEGRATIONS } from '../const'
import { getJson } from '../../shared/utils/api'
import { } from '../const/index'


export const fetchChargerIntegrations = () => {
    return asyncActionCreator(FETCH_CHARGER_INTEGRATIONS, () => getJson(`integration`, 'GET'))
}