import React, { useState, useEffect } from 'react'
import {
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    IconButton,
    CircularProgress,
    Button,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { tableStyles } from './TableStyles'
import moment from 'moment'
import EnhancedTableHead from './EnhancedTableHead'
import { Data, Order } from '../HistoryTypes'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import EmailIcon from '@material-ui/icons/Email'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSessions, sortByProperty, sortByOrder, sendInvoiceForCharging } from '../../../redux/actions/sessions'
import { ChargerConnectorType, ChargerConnectorSocketType, ChargingInitSources } from '../../../shared/types/charger'
import { config, getMainTenantId, getToken } from '../../../config'
import { getOffset } from '../../../shared/utils/localTime'

import { exportFile } from '../../../shared/utils/export'
export default function HistoryTable() {
    const { t } = useTranslation()
    const classes = tableStyles()
    const dispatch = useDispatch()

    const [order, setOrder] = useState<Order>('desc')
    const [orderBy, setOrderBy] = useState<keyof Data>('startedAt')
    const [page, setPage] = useState(0)
    const [rowsPerPage] = useState(30)
    const [dataKey, setDataKey] = useState<number>(0)

    const sessionsState = useSelector((state: any) => state.sessions.items)
    const filter = useSelector((state: any) => state.sessions.filter)
    const sort = useSelector((state: any) => state.sessions.sort)
    const tenantId = useSelector((state: any) => state.login.value.user.id)

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
        dispatch(sortByProperty(property))
        dispatch(sortByOrder(isAsc ? 'desc' : 'asc'))
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
        fetchData(newPage)
    }

    const fetchData = (page?: number) => {
        dispatch(fetchSessions(dataKey, sessionsState, Number(page) + 1, rowsPerPage, filter, sort))
    }

    useEffect(() => {
        if (sessionsState.executed && sessionsState.key !== dataKey) {
            fetchData()
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, dataKey, sessionsState, filter, sort])

    useEffect(() => {
        setDataKey(dataKey + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, sort])

    const onExport = (id: string) => {
        exportFile(config.proxy, config.api, `ChargingSession/invoice/${id}`, getToken(), getOffset())
    }

    const onSend = (id: string) => {
        dispatch(sendInvoiceForCharging(id))
    }

    const determineUserType = (row: any) => {
        if (row.idTag && row.whitelistAuthentication) return t('users.whitelist')
        else if (!row.user) return t('users.anonymous')
        else if (row.user.vip) return t('users.vip')
        return t('users.standard')
    }

    const determineConnectorSocketType = (row: any) => {
        const connector: string =
            row.chargingConnector.type === ChargerConnectorType.Ac
                ? t('locations.connectorTypeAc')
                : t('locations.connectorTypeDc')
        let socket: string
        switch (row.chargingConnector.socketType) {
            case ChargerConnectorSocketType.Type1: {
                socket = t('locations.connectorSocketTypeType1')
                break
            }
            case ChargerConnectorSocketType.Type2: {
                socket = t('locations.connectorSocketTypeType2')
                break
            }
            case ChargerConnectorSocketType.CHAdeMO: {
                socket = t('locations.connectorSocketTypeChademo')
                break
            }
            case ChargerConnectorSocketType.CCS: {
                socket = t('locations.connectorSocketTypeCcs')
                break
            }
            default: {
                socket = t('common.none')
                break
            }
        }
        return `${socket} (${connector})`
    }

    const determineChargingInitSource = (row: any) => {
        let chargingInitSource: string
        switch (row.chargingInitSource) {
            case ChargingInitSources.SEAK: {
                chargingInitSource = 'SEAK'
                break
            }
            case ChargingInitSources.HUBJECT: {
                chargingInitSource = 'HUBJECT'
                break
            }
            default: {
                chargingInitSource = t('common.none')
                break
            }
        }
        return chargingInitSource
    }

    return (
        <Paper className={classes.paper}>
            <TableContainer style={{ height: '100%', overflowY: 'scroll' }}>
                <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={sessionsState.value.count}
                    />
                    {!sessionsState.executed ? (
                        <TableCell colSpan={8} align="center" className={classes.circularProgressCell}>
                            <div className={classes.circularProgressWrapper}>
                                <CircularProgress size={40} />
                            </div>
                        </TableCell>
                    ) : (
                        <TableBody style={{ width: '100%' }}>
                            {sessionsState.value.items?.map((row: any) => {
                                if (
                                    row.location != null &&
                                    row.charger != null &&
                                    row.chargingConnectorId != null &&
                                    row.startedAt
                                ) {
                                    const customKey = `${row.location?.id}${row.charger?.id}${row.chargingConnectorId?.id}${row.startedAt}`
                                    console.log(row.hasInvoice)
                                    return (
                                        <TableRow key={customKey}>
                                            <TableCell align="left">{row.location.name}</TableCell>
                                            <TableCell>{row.charger.name}</TableCell>
                                            <TableCell>{determineConnectorSocketType(row)}</TableCell>
                                            <TableCell align="left">{moment(row.startedAt).format('LLL')}</TableCell>
                                            <TableCell>{row.consumedWh / 1000}</TableCell>
                                            <TableCell>
                                                {row.endedAt ? row.length : t('users.sessionInProgress')}
                                            </TableCell>
                                            <TableCell>
                                                {row.endedAt ? row.price : t('users.sessionInProgress')}
                                            </TableCell>
                                            <TableCell align="left">{row.pricingCondition?.name}</TableCell>
                                            <TableCell align="left">{row.oldPricelistInfo}</TableCell>
                                            <TableCell align="left">{row.user?.email ?? t('common.none')}</TableCell>
                                            <TableCell align="left">{determineUserType(row)}</TableCell>
                                            <TableCell align="left">{row.idTag ?? t('common.none')}</TableCell>
                                            <TableCell align="left">
                                                {row.user?.carLicensePlate ?? t('common.none')}
                                            </TableCell>
                                            <TableCell align="left">{determineChargingInitSource(row)}</TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    onClick={() => onExport(row.id)}
                                                    disabled={
                                                        !row.endedAt ||
                                                        determineChargingInitSource(row) === 'HUBJECT' ||
                                                        !row.hasInvoice
                                                    }
                                                >
                                                    <PictureAsPdfIcon />
                                                </IconButton>
                                            </TableCell>
                                            {tenantId && tenantId === getMainTenantId() && (
                                                <TableCell align="center">
                                                    <IconButton
                                                        onClick={() => onSend(row.id)}
                                                        disabled={
                                                            !row.endedAt ||
                                                            determineChargingInitSource(row) === 'HUBJECT' ||
                                                            !row.hasInvoice
                                                        }
                                                    >
                                                        <EmailIcon />
                                                    </IconButton>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    )
                                }
                            })}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[30]}
                component={Paper}
                count={sessionsState.value.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                className={classes.pagination}
            />
        </Paper>
    )
}
