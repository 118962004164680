import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import { errorDismiss, logout } from '../../shared/redux/actions/common'
import AuthorizationError from '../../shared/utils/authorizationError'
import ValidationError from '../../shared/utils/validationError'
import { getErrorMessage } from '../../shared/utils/validation'

const ErrorDialog = () => {
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const error = useSelector((state: any) => state.error.current)
    const codeToMessageMapWithKeys = useSelector((state: any) => state.error.codeToMessageMap)
    const codeToMessageMap = codeToMessageMapWithKeys
        ? codeToMessageMapWithKeys
            .filter((mapItem: Array<string>) => Boolean(mapItem) && mapItem.length === 2)
            .map((mapItem: Array<string>) => [mapItem[0], t(mapItem[1])])
        : []

    useEffect(() => {
        if (error && error instanceof AuthorizationError) {
            dispatch(logout())
            dispatch(errorDismiss())
        }
    }, [dispatch, error])

    return (
        <Dialog
            open={Boolean(error && !(error instanceof AuthorizationError))}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {Boolean(error) && (
                <>
                    <DialogTitle id="alert-dialog-title">{t('app.errorTitle')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {error instanceof ValidationError
                                ? getErrorMessage(error, codeToMessageMap, t('common.unknownValidationFailure'))
                                : `${t('app.errorDetails')} (${error})`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => dispatch(errorDismiss())} variant="contained" color="primary">
                            {t('common.close')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}

export default ErrorDialog
