import React from 'react'
import { TableHead, TableRow, TableCell } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Data } from '../LogsTypes'

export default function EnhancedTableHead() {
    const { t } = useTranslation()

    interface HeadCell {
        disablePadding: boolean
        id: keyof Data
        label: string
        numeric: boolean
    }

    const headCells: HeadCell[] = [
        { id: 'chargerId', numeric: false, disablePadding: true, label: t('logs.chargerId') },
        { id: 'type', numeric: false, disablePadding: true, label: t('logs.type') },
        { id: 'created', numeric: false, disablePadding: true, label: t('logs.created') },
        { id: 'payload', numeric: false, disablePadding: true, label: t('logs.payload') },
    ]

    return (
        <TableHead style={{ zIndex: 0, position: 'relative' }}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
