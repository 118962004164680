import React, { SFC, useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Dialog,
    DialogTitle,
    makeStyles,
    DialogContent,
    DialogActions,
    Grid,
    Tab,
    Tabs,
    MenuItem,
} from '@material-ui/core'
import { Select } from 'mui-rff'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import { Charger, IntegrationType } from '../../../shared/types/charger'
import { ValidationErrors } from 'final-form'
import { useDispatch, useSelector } from 'react-redux'
import { addCharger, editCharger, fetchLocationForDetail } from '../../../redux/actions/locations'
import { setRequiredErrors } from '../../../utils/ui'
import { TabPanel } from '../../../shared/components/tabPanel'
import { DialogProgress } from '../../../shared/components/dialogProgress'
import { DialogConfirmButton } from '../../../shared/components/dialogConfirmButton'
import { RichEditor } from '../../../core/richEditor/RichEditor'
import { fetchChargerIntegrations } from '../../../redux/actions/chargerIntegrations'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { fetchAllChargerTags } from '../../../redux/actions/tags'
import { TagCharger, TagModel } from '../../../shared/types/tag'

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiPaper-root': {
            height: '633px',
            width: '600px',
        },
        '& .MuiFilledInput-root': {
            borderRadius: theme.spacing(0.5),
        },
        '& .MuiFilledInput-root::before': {
            border: 0,
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .MuiTab-root': {
            flex: 1,
            minWidth: '0px',
        },
        '& .RichEditor-editor': {
            flex: 1,
        },
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: `${theme.spacing(1, 3, 0, 3)} !important`,
    },
    dialogActions: {
        padding: theme.spacing(1.5, 3, 3, 3),
    },
    tabContent: {
        paddingTop: theme.spacing(2),
    },
    textTabContent: {
        paddingTop: theme.spacing(2),
        height: '300px',
        flex: 1,
    },
    fullWidth: {
        width: '100%',
    },
    form: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        flex: 1,
    },
    dummy: {
        display: 'none',
    },
    fullHeight: {
        height: '100%',
    },
}))

interface ChargerAddEditDialogProps {
    open: boolean
    isAdd: boolean
    onClose(charger?: Charger): void
    initialModel: Charger
}
const ChargerAddEditDialog: SFC<ChargerAddEditDialogProps> = ({ open, isAdd, onClose, initialModel }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const stateSelector = isAdd
        ? (state: any) => state.locations.chargers.add
        : (state: any) => state.locations.chargers.edit

    const [tabIndex, setTabIndex] = useState<number>(0)

    const [richEditor, setRichEditor] = useState({
        navigation: initialModel.textInstructionForNavigation,
        charging: initialModel.textInstructionHowToStartCharging,
        service: initialModel.serviceNote,
    })

    const allIntegrations = useSelector((state: any) =>
        state.chargerIntegrations.items.value == null ? [] : state.chargerIntegrations.items.value
    )
    const chargerIntegrations = useSelector((state: any) => initialModel.integrations)
    const [integrationArray, setIntegrationArray] = useState<string[]>([])

    const allTags = useSelector((state: any) => state.tags.items.charger.value)
    const [filteredTags, setFilteredTags] = useState([])
    const [chargerTags, setChargerTags] = useState<TagCharger[]>([])
    const [chargerTagsMapped, setChargerTagsMapped] = useState<TagModel[]>([])

    useEffect(() => {
        let array: string[] = []
        if (chargerIntegrations) {
            array = chargerIntegrations.split(',')
        }
        setIntegrationArray(array)
    }, [chargerIntegrations])

    const onDialogClose = (charger?: Charger) => {
        setRichEditor({
            navigation: initialModel.textInstructionForNavigation,
            charging: initialModel.textInstructionHowToStartCharging,
            service: initialModel.serviceNote,
        })
        onClose(charger)
    }

    const handleIntegrationChange = (event: any, newValue: IntegrationType[]) => {
        const integrationIds = newValue.map((integration) => integration.id)
        setIntegrationArray(integrationIds)
    }
    const handleTagChange = (event: any, newValue: TagModel[]) => {
        // Set the new chargerTags state
        setChargerTagsMapped(newValue)

        const newChargerTags: TagCharger[] = newValue.map((tag) => ({
            tagId: tag.id,
            chargerId: initialModel.id,
        }))

        // Set the new chargerTags state
        setChargerTags(newChargerTags)
    }

    useEffect(() => {
        dispatch(fetchChargerIntegrations())
        dispatch(fetchAllChargerTags())
    }, [dispatch])

    useEffect(() => {
        setRichEditor({
            navigation: initialModel.textInstructionForNavigation,
            charging: initialModel.textInstructionHowToStartCharging,
            service: initialModel.serviceNote,
        })
        setChargerTags(initialModel.tags || [])
    }, [initialModel])

    const onRichEditorChange = (property: string, value: string) => {
        setRichEditor({ ...richEditor, [property]: value })
    }

    useEffect(() => {
        if (chargerTags && allTags.length && initialModel) {
            const filtered = allTags.filter((tag: TagModel) => tag.tenantId === initialModel.tenantId)
            setFilteredTags(filtered)

            // Filter allTags to select only those TagModel objects whose id matches TagCharger.tagId
            const selectedTags = filtered.filter((tag: TagModel) =>
                chargerTags.some((tagCharger: TagCharger) => tag.id === tagCharger.tagId)
            )
            setChargerTagsMapped(selectedTags)
        } else {
            setChargerTagsMapped([])
        }
    }, [chargerTags, allTags, initialModel])

    return (
        <Dialog className={classes.dialog} open={open}>
            <DialogProgress stateSelector={stateSelector} />
            <DialogTitle>{isAdd ? t('locations.newCharger') : t('locations.editCharger')}</DialogTitle>
            <Form
                onSubmit={async (model) => {
                    const newChargerObj = {
                        ...model,
                        integrations: integrationArray.join(','),
                        textInstructionForNavigation: richEditor.navigation,
                        textInstructionHowToStartCharging: richEditor.charging,
                        serviceNote: richEditor.service,
                        tags: chargerTags,
                    }
                    const result: any = await dispatch(isAdd ? addCharger(newChargerObj) : editCharger(newChargerObj))
                    await dispatch(fetchLocationForDetail(initialModel.locationId))
                    if (result) {
                        onClose(result)
                    }
                }}
                initialValues={initialModel}
                validate={(model: Charger): ValidationErrors => {
                    const result: any = {}
                    setRequiredErrors(
                        result,
                        model,
                        ['name', 'manufacturer', 'externalId'],
                        t('common.required')
                    )
                    return result
                }}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                        <DialogContent className={classes.dialogContent}>
                            <Tabs value={tabIndex} onChange={(event: any, newValue: number) => setTabIndex(newValue)}>
                                <Tab label={t('locations.basic')} color="primary" />
                                <Tab label={t('locations.navigationInstructionsHeader')} />
                                <Tab label={t('locations.chargingInstructionsHeader')} />
                                <Tab label={t('locations.serviceNoteHeader')} />
                            </Tabs>
                            <TabPanel value={tabIndex} index={0} padding={0} className={classes.tabContent}>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="name"
                                            fullWidth
                                            required
                                            multiline
                                            inputProps={{
                                                maxLength: 150,
                                                rows: 1,
                                            }}
                                            type="text"
                                            variant="filled"
                                            label={t('common.name')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="manufacturer"
                                            fullWidth
                                            required
                                            multiline
                                            inputProps={{
                                                maxLength: 250,
                                                rows: 1,
                                            }}
                                            type="text"
                                            variant="filled"
                                            label={t('locations.manufacturer')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="externalId"
                                            fullWidth
                                            required
                                            inputProps={{
                                                maxLength: 250,
                                            }}
                                            type="text"
                                            variant="filled"
                                            label={t('locations.externalId')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="brand"
                                            fullWidth
                                            multiline
                                            inputProps={{
                                                maxLength: 250,
                                                autoComplete: 'off',
                                                rows: 1,
                                            }}
                                            type="text"
                                            variant="filled"
                                            label={t('locations.brand')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="linkToForum"
                                            fullWidth
                                            multiline
                                            inputProps={{
                                                maxLength: 512,
                                                rows: 1,
                                            }}
                                            type="text"
                                            variant="filled"
                                            label={t('locations.forumLink')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            options={allIntegrations}
                                            getOptionLabel={(integration: IntegrationType) => integration.name}
                                            value={allIntegrations.filter((integration: IntegrationType) =>
                                                integrationArray.includes(integration.id)
                                            )}
                                            onChange={handleIntegrationChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    name={''}
                                                    {...params}
                                                    variant="filled"
                                                    label="Integrations"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            options={filteredTags}
                                            getOptionLabel={(tag: TagModel) => tag.name}
                                            value={chargerTagsMapped}
                                            onChange={handleTagChange} // Uncomment this line if you have an onChange handler
                                            renderInput={(params) => (
                                                <TextField name={''} {...params} variant="filled" label="Tags" />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1} padding={0} className={classes.textTabContent}>
                                {' '}
                                <Grid item xs={12} className={classes.fullHeight}>
                                    <TextField
                                        name="dummy"
                                        fullWidth
                                        multiline
                                        rows={12}
                                        type="text"
                                        className={classes.dummy}
                                        variant="filled"
                                        label={t('locations.navigationInstructions')}
                                    />
                                    <RichEditor
                                        label={t('locations.navigationInstructions')}
                                        property="navigation"
                                        initialValue={richEditor.navigation}
                                        onChange={onRichEditorChange}
                                    />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={2} padding={0} className={classes.textTabContent}>
                                <Grid item xs={12} className={classes.fullHeight}>
                                    <TextField
                                        name="dummy"
                                        fullWidth
                                        multiline
                                        rows={12}
                                        type="text"
                                        className={classes.dummy}
                                        variant="filled"
                                        label={t('locations.chargingInstructions')}
                                    />
                                    <RichEditor
                                        label={t('locations.chargingInstructions')}
                                        property="charging"
                                        initialValue={richEditor.charging}
                                        onChange={onRichEditorChange}
                                    />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={tabIndex} index={3} padding={0} className={classes.textTabContent}>
                                <Grid item xs={12} className={classes.fullHeight}>
                                    <TextField
                                        name="dummy"
                                        fullWidth
                                        multiline
                                        rows={12}
                                        type="text"
                                        className={classes.dummy}
                                        variant="filled"
                                        label={t('locations.serviceNote')}
                                    />
                                    <RichEditor
                                        label={t('locations.serviceNote')}
                                        property="service"
                                        initialValue={richEditor.service}
                                        onChange={onRichEditorChange}
                                    />
                                </Grid>
                            </TabPanel>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={() => {
                                    onDialogClose()
                                }}
                                color="primary"
                            >
                                {t('common.cancel')}
                            </Button>
                            <DialogConfirmButton
                                label={isAdd ? t('common.create') : t('common.change')}
                                stateSelector={stateSelector}
                            />
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    )
}

export default ChargerAddEditDialog
