// eslint-disable-file react-hooks/exhaustive-deps //
import React, { FC, useEffect, useState, SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Dialog,
    DialogTitle,
    Divider,
    makeStyles,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    Snackbar,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Alert, { Color } from '@material-ui/lab/Alert'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { useDispatch, useSelector } from 'react-redux'
import { promiseError } from '../../../shared/redux/actions/common'
import { changeConfigurationChargers, fetchConfigurationChargers } from '../../../redux/actions/chargerConfiguration'
import {
    resetCharger,
    changeConnectorAvailability,
    uploadChargerCsv,
    unlockConnector,
} from '../../../redux/actions/locations'
import {
    Charger,
    ChargerConnector,
    getLabelForChargerConnectorSocketType,
    getLabelForChargerConnectorType,
} from '../../../shared/types/charger'
import { ConfirmationDialog } from '../../../shared/components/confirmationDialog'
import { updateConnectorAvailability } from '../../../redux/actions/locations'
import { filterByChargerId, filterByType } from '../../../redux/actions/logs'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiFilledInput-root': {
            borderRadius: theme.spacing(0.5),
        },
        '& .MuiFilledInput-root::before': {
            border: 0,
        },
        '& .MuiGrid-container': {
            padding: theme.spacing(2, 1),
        },
        '& .MuiGrid-item': {
            display: 'flex',
        },
        '& .MuiTableCell-sizeSmall': {
            padding: theme.spacing(0.5, 1),
        },
        '& .MuiTableRow-root:last-child .MuiTableCell-sizeSmall': {
            borderBottom: 0,
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .MuiTab-root': {
            minWidth: '135px',
        },
        '& .MuiDialog-paper': {
            maxWidth: '720px !important',
        },
        '& .MuiFormControl-root': {
            width: '100%',
        },
        '& .MuiButton-containedSecondary:hover': {
            color: '#ffffff',
        },
    },
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
    },
    dividerMarginBottom: {
        marginBottom: theme.spacing(2),
    },
    fullWidth: {
        width: '100%',
    },
    divider: {
        minWidth: '400px',
    },
    uploadInput: {
        display: 'none',
    },
    textOk: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
    },
    textNok: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
    },
    spacedButtons: {
        '& button, label': {
            display: 'inline-block',
            margin: theme.spacing(1),
        },
    },
    centerButtons: {
        justifyContent: 'center',
    },
    connectorId: {
        minWidth: '55px',
        display: 'inline-block',
    },
    connectorType: {
        minWidth: '125px',
        display: 'inline-block',
    },
    connectorStatus: {
        minWidth: '85px',
        display: 'inline-block',
    },
    connectorButtons: {
        minWidth: '300px',
        display: 'inline-block',
    },
    input: {
        margin: '5px',
    },
}))

interface ChargerSettingsDialogProps {
    open: boolean
    onClose(): void
    initialModel: Charger
}
interface IAlertMessage {
    message: string | undefined
    autohide: boolean
    severity: Color | undefined
}
const defaultAlert: IAlertMessage = {
    message: undefined,
    autohide: false,
    severity: undefined,
}

const ChargerSettingsDialog: FC<ChargerSettingsDialogProps> = ({ open, onClose, initialModel }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const classes = useStyles()
    const history = useHistory()

    const locationDetail = useSelector((state: any) => state.locations.detail.value)
    const chargerConnectors =
        locationDetail?.chargers && initialModel
            ? locationDetail.chargers.find((charger: Charger) => charger.id === initialModel.id)?.connectors
            : []

    const charger =
        locationDetail?.chargers && initialModel
            ? locationDetail.chargers.find((charger: Charger) => charger.id === initialModel.id)
            : ''

    const externalId = charger.externalId
    const resetChargerState = useSelector((state: any) => state.locations.chargers.resetCharger)
    const connectorAvailabilityState = useSelector((state: any) => state.locations.chargers.changeConnectorAvailability)
    const unlockConnectorState = useSelector((state: any) => state.locations.chargers.unlockConnector)
    const uploadChargerCsvState = useSelector((state: any) => state.locations.chargers.uploadChargerCsv)

    const chargerConfigurationResult = useSelector((state: any) => state.chargerConfiguration)
    const [configurationLoaded, setConfigurationLoaded] = useState<boolean>(false)
    const [configurationLoadSuccess, setConfigurationLoadSuccess] = useState<boolean>(false)

    const [confirmDialogOpen, setConfirmDialogOpen] = useState({ avail: false, reset: false, unlock: false })
    const [confirmDialogProps, setConfirmDialogProps] = useState<any>()
    const [apiResponseMessage, setApiResponseMessage] = useState(defaultAlert)

    const [configKey, setConfigKey] = useState<string>('')
    const [configValue, setConfigValue] = useState<string>('')
    const [configValuePlaceholder, setConfigValuePlaceholder] = useState<string>('')
    
    const ocppCommunicationSuccess = 0
    const ocppCommunicationFailed = 1
    const ocppCommunicationScheduled = 2
    const ocppCommunicationDeviceNotConnected = 3
    const ocppCommunicationFailedForcedSuccess = 4

    const createAlertMessage = (msgCode: number): void => {
        let msgCodes: any = []
        msgCodes[ocppCommunicationSuccess] = {
            message: t('locations.operationCompleted'),
            severity: 'success',
        }
        msgCodes[ocppCommunicationFailed] = {
            message: t('locations.operationFailed'),
            severity: 'error',
        }
        msgCodes[ocppCommunicationScheduled] = {
            message: t('locations.operationScheduled'),
            severity: 'warning',
        }
        msgCodes[ocppCommunicationDeviceNotConnected] = {
            message: t('locations.deviceNotConnected'),
            severity: 'error',
        }
        msgCodes[ocppCommunicationFailedForcedSuccess] = {
            message: t('locations.chargerSocketNotConnectedButAvailabilityChangedToInoperative'),
            severity: 'success',
        }

        setApiResponseMessage({
            message: msgCodes[msgCode].message,
            autohide: false,
            severity: msgCodes[msgCode].severity,
        })
    }

    // const redirectToLogs = (externalId: any) => {
    //     dispatch(filterByChargerId(externalId))
    //     dispatch(filterByType('GetConfiguration'))
    //     history.push('/logs')
    // }

    const handleChargerReset = (resetType: number) => {
        setApiResponseMessage(defaultAlert)
        setConfirmDialogProps({ resetType })
        setConfirmDialogOpen({ ...confirmDialogOpen, reset: true })
    }

    const handleConnectorAvailability = (connectorId: string, availType: number) => {
        setApiResponseMessage(defaultAlert)
        setConfirmDialogProps({ connectorId, availType })
        setConfirmDialogOpen({ ...confirmDialogOpen, avail: true })
    }

    const handleConnectorUnlock = (connectorId: string) => {
        setApiResponseMessage(defaultAlert)
        setConfirmDialogProps({ connectorId })
        setConfirmDialogOpen({ ...confirmDialogOpen, unlock: true })
    }

    const handleGetConfiguration = (externalId: string) => {
        setConfigurationLoaded(true)
        dispatch(fetchConfigurationChargers(externalId))
    }

    const handleSetConfiguration = () => {
        dispatch(changeConfigurationChargers(charger.id, configKey, configValue))
    }

    const handleFileUpload = async (ev: any) => {
        ev.persist()
        if (ev.target.files[0]) {
            const fileExt = ev.target.files[0].name.split('.').pop().toString().toLowerCase()
            const allowedExt = ['csv', 'txt']
            if (allowedExt.indexOf(fileExt) > -1) {
                const uploadType = ev.target.id === 'inputUploadNew' ? 1 : 0
                await dispatch(uploadChargerCsv(initialModel.id, uploadType, ev.target.files[0]))
                ev.target.value = ''
            } else {
                dispatch(promiseError(t('locations.uploadedFileNotSupported')))
            }
        }
    }

    const handleKeyChange = (configKey: string) => {
        setConfigKey(configKey);
 
        if (configKey === 'AllowOfflineTxForUnknownId' || configKey === 'AuthorizationCacheEnabled') {
            setConfigValuePlaceholder('boolean')
        } else {
            setConfigValuePlaceholder('integer')
        }
    }

    const connectorToString = (connector: ChargerConnector) => {
        const type = getLabelForChargerConnectorType(connector.type, t)
        const socketType = getLabelForChargerConnectorSocketType(connector.socketType, t)
        return `${socketType} ${type}`
    }

    const isDisabled = () => {
        if (configKey && configValue) {
            return false
        } else {
            return true
        }
    }

    const possibleKeys = [
        'AuthorizeRemoteTxRequests',
        'HeartbeatInterval',
        'MeterValueSampleInterval',
        'TransactionMessageAttempts',
        'AllowOfflineTxForUnknownId',
        'AuthorizationCacheEnabled',
    ]

    useEffect(() => {
        if (configurationLoaded && chargerConfigurationResult?.items) {
            setConfigurationLoadSuccess(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chargerConfigurationResult])

    useEffect(() => {
        if (resetChargerState.executed) {
            setConfirmDialogOpen({ ...confirmDialogOpen, reset: false })

            if (resetChargerState.value !== null) {
                createAlertMessage(resetChargerState.value)
            }
            if (resetChargerState.error && resetChargerState.executed) {
                dispatch(promiseError(resetChargerState.error))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetChargerState])

    useEffect(() => {
        if (connectorAvailabilityState.executed && confirmDialogProps) {
            setConfirmDialogOpen({ ...confirmDialogOpen, avail: false })
            if (connectorAvailabilityState.value !== null) {
                if (
                    connectorAvailabilityState.value === ocppCommunicationSuccess ||
                    connectorAvailabilityState.value === ocppCommunicationFailedForcedSuccess
                ) {
                    const { connectorId, availType } = confirmDialogProps
                    dispatch(updateConnectorAvailability(initialModel.id, connectorId, availType))
                }
                createAlertMessage(connectorAvailabilityState.value)
            }
            if (connectorAvailabilityState.error) {
                dispatch(promiseError(connectorAvailabilityState.error))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectorAvailabilityState])

    useEffect(() => {
        if (unlockConnectorState.executed) {
            setConfirmDialogOpen({ ...confirmDialogOpen, unlock: false })
            if (unlockConnectorState.value !== null) {
                createAlertMessage(unlockConnectorState.value)
            }
            if (unlockConnectorState.error) {
                dispatch(promiseError(unlockConnectorState.error))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unlockConnectorState])

    useEffect(() => {
        if (uploadChargerCsvState.executed) {
            if (uploadChargerCsvState.value !== null) {
                createAlertMessage(uploadChargerCsvState.value)
            }
            if (uploadChargerCsvState.error) {
                dispatch(promiseError(uploadChargerCsvState.error))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadChargerCsvState])

    return (
        <>
            <Dialog className={classes.dialog} open={open}>
                <DialogTitle>{t('locations.chargerSettings')}</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">{t('locations.resetCharger')}</Typography>
                    <Divider className={classes.dividerMarginBottom} />
                    <Grid container alignItems="center" className={classes.spacedButtons}>
                        <Grid item xs={12} className={classes.centerButtons}>
                            <Button
                                color="secondary"
                                size="small"
                                variant="contained"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    handleChargerReset(1)
                                }}
                            >
                                {t('locations.softwareReset')}
                            </Button>
                            <Button
                                color="secondary"
                                size="small"
                                variant="contained"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    handleChargerReset(0)
                                }}
                            >
                                {t('locations.hardwareReset')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle1">{t('locations.connectors')}</Typography>
                    <Divider className={classes.dividerMarginBottom} />
                    <Grid container alignItems="center" className={classes.spacedButtons}>
                        <Grid item xs={12} className={classes.centerButtons}>
                            <Table size="small">
                                <TableBody>
                                    {chargerConnectors && chargerConnectors.length ? (
                                        chargerConnectors.map((connector: any) => (
                                            <TableRow key={connector.id}>
                                                <TableCell>
                                                    <span className={classes.connectorId}>
                                                        ID: {connector.externalId}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className={classes.connectorType}>
                                                        {connectorToString(connector)}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    {connector.availability ? (
                                                        <span
                                                            className={`${classes.textNok} ${classes.connectorStatus}`}
                                                        >
                                                            {t('common.notAvailable')}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            className={`${classes.textOk} ${classes.connectorStatus}`}
                                                        >
                                                            {t('common.available')}
                                                        </span>
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div className={classes.connectorButtons}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            size="small"
                                                            key={`availconnector.id`}
                                                            onClick={(ev: SyntheticEvent) => {
                                                                ev.preventDefault()
                                                                handleConnectorAvailability(
                                                                    connector.id,
                                                                    connector.availability ? 0 : 1
                                                                )
                                                            }}
                                                        >
                                                            {t('locations.changeAvailability')}
                                                        </Button>

                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="secondary"
                                                            onClick={(ev: SyntheticEvent) => {
                                                                ev.preventDefault()
                                                                handleConnectorUnlock(connector.id)
                                                            }}
                                                            key={`unlockconnector.id`}
                                                        >
                                                            {t('common.unlock')}
                                                        </Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell align="center">
                                                <Typography>{t('locations.noConnectorsToList')}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle1">{t('locations.sendRfidList')}</Typography>
                    <Divider className={classes.dividerMarginBottom} />
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} className={`${classes.spacedButtons} ${classes.centerButtons}`}>
                            <input
                                accept=".csv,.txt"
                                className={classes.uploadInput}
                                id="inputUploadNew"
                                onChange={handleFileUpload}
                                onClick={() => setApiResponseMessage(defaultAlert)}
                                type="file"
                            />
                            <label htmlFor="inputUploadNew">
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(ev: SyntheticEvent) => ev.stopPropagation()}
                                    startIcon={<CloudUploadIcon />}
                                >
                                    {t('locations.sendRfidListNew')}
                                </Button>
                            </label>
                            <input
                                accept=".csv,.txt"
                                className={classes.uploadInput}
                                id="inputUploadUpdate"
                                onChange={handleFileUpload}
                                onClick={() => setApiResponseMessage(defaultAlert)}
                                type="file"
                            />
                            <label htmlFor="inputUploadUpdate">
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    onClick={(ev: SyntheticEvent) => ev.stopPropagation()}
                                    startIcon={<CloudUploadIcon />}
                                >
                                    {t('locations.sendRfidListUpdate')}
                                </Button>
                            </label>
                        </Grid>
                    </Grid>
                    
                    {/* TODO TODO - commented get/set configuration etc */}

                    {/* <Typography variant="subtitle1">{t('locations.getConfigurationTitle')}</Typography>
                    <Divider className={classes.dividerMarginBottom} />
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} className={`${classes.spacedButtons} ${classes.centerButtons}`}>
                            <Button
                                color="secondary"
                                size="small"
                                variant="contained"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    handleGetConfiguration(externalId)
                                }}
                            >
                                {t('locations.getConfigurationStart')}
                            </Button>
                            <Button
                                color="secondary"
                                size="small"
                                variant="contained"
                                disabled={!configurationLoadSuccess}
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    redirectToLogs(externalId)
                                }}
                            >
                                {t('locations.showGetConfigurationResult')}
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid item xs={6}>
                                <FormControl variant="filled" className={classes.input}>
                                    <InputLabel id={`ac1Label`}>
                                        key
                                    </InputLabel>
                                    <Select
                                        name="key"
                                        labelId={`ac1Label`}
                                        fullWidth
                                        onChange={(event: any) => handleKeyChange(event.target.value)}
                                    >
                                        {possibleKeys.map((item, i) => (
                                            <MenuItem key={i} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="value"
                                    inputProps={{
                                        maxLength: 150,
                                    }}
                                    type="text"
                                    variant="filled"
                                    label="value"
                                    className={classes.input}
                                    onChange={(event: any) => setConfigValue(event.target.value)}
                                    placeholder={configValuePlaceholder}
                                    value={configValue}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={`${classes.spacedButtons} ${classes.centerButtons}`}>
                            <Button
                                color="secondary"
                                size="small"
                                variant="contained"
                                disabled={isDisabled()}
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    handleSetConfiguration()
                                }}
                            >
                                Change configuration
                            </Button>
                        </Grid>
                    </Grid>  */}
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setApiResponseMessage(defaultAlert)
                            onClose()
                        }}
                        color="primary"
                    >
                        {t('common.close')}
                    </Button>
                </DialogActions>
                {apiResponseMessage.message && (
                    <Snackbar
                        open={Boolean(apiResponseMessage.message)}
                        autoHideDuration={Boolean(apiResponseMessage.autohide) ? 1000 : undefined}
                    >
                        <Alert
                            onClose={() => setApiResponseMessage(defaultAlert)}
                            severity={apiResponseMessage.severity}
                        >
                            {apiResponseMessage.message}
                        </Alert>
                    </Snackbar>
                )}
            </Dialog>

            <ConfirmationDialog
                title={t('locations.resetTitle')}
                open={confirmDialogOpen.reset}
                content={t('locations.resetContent')}
                onCancel={() => setConfirmDialogOpen({ ...confirmDialogOpen, reset: false })}
                onConfirm={async () => {
                    const { resetType } = confirmDialogProps
                    await dispatch(resetCharger(initialModel, resetType))
                }}
                confirmLabel={t('common.reset')}
                cancelLabel={t('common.cancel')}
                inProgress={!resetChargerState.executed}
            />

            <ConfirmationDialog
                title={t('locations.changeAvailabilityTitle')}
                open={confirmDialogOpen.avail}
                content={t('locations.changeAvailabilityContent')}
                onCancel={() => {
                    setConfirmDialogOpen({ ...confirmDialogOpen, avail: false })
                }}
                onConfirm={async () => {
                    const { connectorId, availType } = confirmDialogProps
                    await dispatch(changeConnectorAvailability(connectorId, availType))
                }}
                confirmLabel={t('common.change')}
                cancelLabel={t('common.cancel')}
                inProgress={!connectorAvailabilityState.executed}
            />

            <ConfirmationDialog
                title={t('locations.unlockTitle')}
                open={confirmDialogOpen.unlock}
                content={t('locations.unlockContent')}
                onCancel={() => {
                    setConfirmDialogOpen({ ...confirmDialogOpen, unlock: false })
                }}
                onConfirm={async () => {
                    const { connectorId } = confirmDialogProps
                    await dispatch(unlockConnector(connectorId))
                }}
                confirmLabel={t('common.unlock')}
                cancelLabel={t('common.cancel')}
                inProgress={!unlockConnectorState.executed}
            />
        </>
    )
}

export default ChargerSettingsDialog
