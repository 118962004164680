import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTags, deleteTag } from '../../redux/actions/tags'
import { Grid, makeStyles, Typography, Button } from '@material-ui/core'
import { TagModel } from '../../shared/types/tag'
import { useTranslation } from 'react-i18next'
import TagList from './tagList/TagList'
import TagCard from './tagCard/TagCard'
import { useCalcHeightToBottom } from '../../utils/ui'
import TagAddEditDialog from './tagAddEditDialog/TagAddEditDialog'
import { DeleteConfirmationDialog } from '../../shared/components/deleteConfirmationDialog'
import { fetchTenants } from '../../redux/actions/tenants'
// import { getMainTenantId } from '../../config'

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        padding: theme.spacing(1),
    },
    actionButtonWrapper: {
        padding: theme.spacing(1, 0),
        textAlign: 'right',
    },
    listWrapper: {
        paddingTop: theme.spacing(2),
    },
    cardWrapper: {
        paddingTop: theme.spacing(2),
        width: '100%',
    },
}))

export default function Tags() {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [selectedTag, setSelectedTag] = useState<TagModel>()

    const [addEditDialogState, setAddEditDialogState] = useState({
        open: false,
        isAdd: true,
        onClose: (tag: TagModel) => {},
        initialModel: undefined as TagModel | undefined,
    })
    const [addedTag, setAddedTag] = useState<TagModel>()
    const [editedTag, setEditedTag] = useState<TagModel>()
    const [tagToDelete, setTagToDelete] = useState<TagModel>()

    const tags = useSelector((state: any) => state.tags.items.all.value)
    // const search = useSelector((state: any) => state.tags.search.value)
    const deletedTag = useSelector((state: any) => state.tags.delete.value)
    const tagDeleteExecuted = useSelector((state: any) => state.tags.delete.executed)
    // const tenantId = useSelector((state: any) => state.login.value.tags.id)
    // const mainTenantId = getMainTenantId();

    const tagCardHeightFill = useCalcHeightToBottom('tagsCardWrapper')

    const openAddDialog = () => {
        setAddEditDialogState({
            open: true,
            isAdd: true,
            onClose: onAddDialogClose,
            initialModel: undefined,
        })
    }

    const onAddDialogClose = (tag?: TagModel) => {
        setAddEditDialogState({ ...addEditDialogState, open: false })
        if (tag) {
            setAddedTag(tag)
        }
    }

    const onEditDialogClose = (tag?: TagModel) => {
        setAddEditDialogState({ ...addEditDialogState, open: false })
        if (tag) {
            setEditedTag(tag)
        }
    }

    const onTagSelected = (tag: TagModel) => {
        setSelectedTag(tag)
    }

    const onTagDelete = (tag: TagModel) => {
        setTagToDelete(tag)
    }

    const onTagEdit = (tag: TagModel) => {
        setAddEditDialogState({
            open: true,
            isAdd: false,
            onClose: onEditDialogClose,
            initialModel: tag,
        })
    }
    const handleRemoveTag = async () => {
        const tagToDeleteDefined = tagToDelete as TagModel
        await dispatch(deleteTag(tagToDeleteDefined))
        setTagToDelete(undefined)
    }

    useEffect(() => {
        dispatch(fetchTags())
    }, [dispatch, addedTag, editedTag, deletedTag])

    useEffect(() => {
        dispatch(fetchTenants())
    }, [dispatch])

    useEffect(() => {
        if (tags.length > 0) {
            if (selectedTag != null) {
                const tagToSelect = tags.find((x: any) => x.id === selectedTag.id)
                if (tagToSelect != null) {
                    setSelectedTag(tagToSelect)
                } else {
                    setSelectedTag(tags[0])
                }
            } else {
                setSelectedTag(tags[0])
            }
        } else {
            setSelectedTag(undefined)
        }
    }, [tags, selectedTag])

    return (
        <>
            <Grid container spacing={0}>
                <Grid item sm={2} className={classes.headerWrapper}>
                    <Typography align="left" variant="h3">
                        {t('tags.title')}
                    </Typography>
                </Grid>
                <Grid item sm={10} className={classes.actionButtonWrapper}>
                    <Button
                        onClick={() => {
                            openAddDialog()
                        }}
                        variant="contained"
                        color="primary"
                    >
                        {t('tags.newTag')}
                    </Button>
                    {addEditDialogState.open && <TagAddEditDialog {...addEditDialogState} />}
                </Grid>
                <Grid item sm={4} className={classes.listWrapper}>
                    <TagList selectedTag={selectedTag} tags={tags} onTagSelected={onTagSelected} />
                </Grid>
                <Grid item sm={8} className={classes.cardWrapper}>
                    <div id="tagCardWrapper" ref={tagCardHeightFill}>
                        {selectedTag && (
                            <TagCard
                                tag={selectedTag}
                                onDelete={() => onTagDelete(selectedTag)}
                                onEdit={() => onTagEdit(selectedTag)}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            <DeleteConfirmationDialog
                open={Boolean(tagToDelete)}
                onConfirm={async () => {
                    handleRemoveTag()
                }}
                onCancel={() => setTagToDelete(undefined)}
                inProgress={!tagDeleteExecuted}
            />
        </>
    )
}
