import React, { SFC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Dialog,
    DialogTitle,
    makeStyles,
    DialogContent,
    DialogActions,
    Grid,
    Checkbox,
    Typography,
    CardContent,
    ListItem,
    Card,
    CircularProgress,
    Tab,
    Tabs,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Location } from '../../../shared/types/location'
import { DialogConfirmButton } from '../../../shared/components/dialogConfirmButton'
import { Form } from 'react-final-form'
import SearchBar from '../../../core/searchBar/SearchBar'
import { fetchUsers, searchUsers } from '../../../redux/actions/users'
import { NoData } from '../../../shared/components/NoData'
import { UserModel } from '../../../shared/types/user'
import { fetchLocationWhitelist } from '../../../redux/actions/locationWhitelist'
import { TabPanel } from '../../../shared/components/tabPanel'
import { useCalcHeightToBottomOfDialog } from '../../../utils/ui'

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiFilledInput-root': {
            borderRadius: theme.spacing(0.5),
        },
        '& .MuiFilledInput-root::before': {
            border: 0,
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& .DraftEditor-editorContainer': {
            maxHeight: 'calc(100vh - 272px)',
        },
    },
    form: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        flex: 1,
    },
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
    },
    search: {
        paddingLeft: theme.spacing(1),
    },
    list: {
        overflowY: 'auto',
        //marginTop: theme.spacing(3),
    },
    listItem: {
        margin: theme.spacing(1, 1, 2, 1),
        display: 'flex',
        '& .Mui-selected': {
            borderBottom: `3px solid ${theme.palette.primary.main}`,
            paddingBottom: theme.spacing(2) - 3,
        },
    },
    listContent: {
        flex: '1 0 auto',
        textAlign: 'left',
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
    },
    listItemButton: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'start',
        padding: theme.spacing(2),
    },
    listContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    progress: {
        textAlign: 'center',
    },
    dataInfoWrapper: {
        textAlign: 'center',
        paddingLeft: theme.spacing(1),
    },
    checkbox: {
        color: '#6100ED',
        marginBottom: '4px',
        paddingLeft: '0px',
        paddingBottom: '0px',
        paddingTop: '0px',
        paddingRight: '8px',
    },
    tab: {
        width: '50%',
    },
    content: {
        overflow: 'hidden',
    },
}))

interface LocationWhitelistEditDialogProps {
    open: boolean
    onClose(whitelist?: string[]): void
    initialModel: Location
}
const LocationWhitelistEditDialog: SFC<LocationWhitelistEditDialogProps> = ({ open, onClose, initialModel }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const usersExecuted = useSelector((state: any) => state.users.items.executed)
    const search = useSelector((state: any) => state.users.search.value)
    const users = useSelector((state: any) => state.users.items.value)
    const whitelist = useSelector((state: any) => state.locationWhitelist.items.value)
    const [checked, setChecked] = useState(new Array(users.length).fill(false))
    const [beforeSubmit, setBeforeSubmit] = useState<boolean>(true)
    const tenantId = useSelector((state: any) => state.login.value.user.id)

    const whitelistUsers: UserModel[] = []

    const [tabIndex, setTabIndex] = useState<number>(0)

    const locationId = initialModel?.id

    const handleSearchChanged = (search: string) => {
        dispatch(searchUsers(search))
    }

    const isUserInWhitelist = (user: UserModel) => {
        if (whitelist.includes(user.id)) {
            whitelistUsers.push(user)
            return true
        } else {
            return false
        }
    }

    const handleWhitelistChange = (event: any, user: UserModel, pos: number) => {
        if (event.target.checked && !isUserInWhitelist(user)) {
            whitelist.push(user.id)
        } else {
            var i = whitelist.indexOf(user.id)
            if (i !== -1) {
                whitelist.splice(i, 1)
            }
        }

        const updatedCheckedState = checked.map((item, index) => (index === pos ? !item : item))
        setChecked(updatedCheckedState)
    }

    const listHeightFill = useCalcHeightToBottomOfDialog('listWrapper')

    const onDialogClose = (whitelist?: string[]) => {
        onClose(whitelist)
        setBeforeSubmit(true)
    }

    useEffect(() => {
        dispatch(fetchUsers(search, tenantId))
    }, [dispatch, search, tenantId])

    useEffect(() => {
        if (locationId) {
            dispatch(fetchLocationWhitelist(locationId))
        }
    }, [dispatch, locationId])

    return (
        <Dialog className={classes.dialog} open={open}>
            <DialogTitle>Edit Location Whitelist</DialogTitle>
            <Form
                onSubmit={async (model) => {
                    model = {
                        ...model,
                    }
                    onDialogClose(whitelist)
                }}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                        <DialogContent className={classes.content}>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Grid item xs={10} className={classes.search}>
                                        <SearchBar
                                            placeholder={t('users.search')}
                                            value={search}
                                            onChange={handleSearchChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={2} className={classes.progress}>
                                        {!usersExecuted && <CircularProgress size={30} />}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Tabs
                                        value={tabIndex}
                                        onChange={(event: any, newValue: number) => setTabIndex(newValue)}
                                    >
                                        <Tab label="All users" color="primary" className={classes.tab} />
                                        <Tab label="Whitelist" className={classes.tab} />
                                    </Tabs>
                                    <Grid id="listWrapper" item xs={12} className={classes.list} ref={listHeightFill}>
                                        <TabPanel value={tabIndex} index={0}>
                                            {usersExecuted && users.length === 0 ? (
                                                <div className={classes.dataInfoWrapper}>
                                                    <NoData text={t('users.noUsers')} />
                                                </div>
                                            ) : (
                                                users.map((user: UserModel, index: number) => (
                                                    <Card key={user.id} className={classes.listItem} elevation={1}>
                                                        <ListItem>
                                                            <div className={classes.listContentWrapper}>
                                                                <CardContent className={classes.listContent}>
                                                                    <Typography variant="h6">{`${user.firstName} ${user.lastName}`}</Typography>
                                                                    <Typography component="div" variant="caption">
                                                                        {user.email}
                                                                    </Typography>
                                                                    <Typography component="div">
                                                                        <Checkbox
                                                                            className={classes.checkbox}
                                                                            checked={isUserInWhitelist(user)}
                                                                            onChange={(event: any) =>
                                                                                handleWhitelistChange(
                                                                                    event,
                                                                                    user,
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </Typography>
                                                                </CardContent>
                                                            </div>
                                                        </ListItem>
                                                    </Card>
                                                ))
                                            )}
                                        </TabPanel>
                                        <TabPanel value={tabIndex} index={1}>
                                            {usersExecuted && users.length === 0 ? (
                                                <div className={classes.dataInfoWrapper}>
                                                    <NoData text={t('users.noUsers')} />
                                                </div>
                                            ) : (
                                                whitelistUsers.map((user: UserModel, index: number) => (
                                                    <Card key={user.id} className={classes.listItem} elevation={1}>
                                                        <ListItem>
                                                            <div className={classes.listContentWrapper}>
                                                                <CardContent className={classes.listContent}>
                                                                    <Typography variant="h6">{`${user.firstName} ${user.lastName}`}</Typography>
                                                                    <Typography component="div" variant="caption">
                                                                        {user.email}
                                                                    </Typography>
                                                                    <Typography component="div">
                                                                        <Checkbox
                                                                            className={classes.checkbox}
                                                                            checked={true}
                                                                            onChange={(event: any) =>
                                                                                handleWhitelistChange(
                                                                                    event,
                                                                                    user,
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    </Typography>
                                                                </CardContent>
                                                            </div>
                                                        </ListItem>
                                                    </Card>
                                                ))
                                            )}
                                        </TabPanel>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button onClick={() => onDialogClose()} color="primary">
                                {t('common.cancel')}
                            </Button>
                            <DialogConfirmButton
                                label={t('common.save')}
                                onClick={() => {
                                    if (beforeSubmit) {
                                        setBeforeSubmit(false)
                                    }
                                }}
                            />
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    )
}

export default LocationWhitelistEditDialog
