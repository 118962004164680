import React, { SFC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LocationListItem } from '../../../shared/types/location'
import { Grid, CircularProgress, Card, CardContent, Typography, ListItem, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SearchBar from '../../../core/searchBar/SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { searchLocations, fetchListLocations } from '../../../redux/actions/locations'
import { useCalcHeightToBottomPartialList } from '../../../utils/ui'
import { NoData } from '../../../shared/components/NoData'
import { Image } from '../../../shared/components/Image'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    list: {
        overflowY: 'auto',
        marginTop: theme.spacing(3),
    },
    listItem: {
        margin: theme.spacing(1, 1, 2, 1),
        display: 'flex',
        '& .Mui-selected': {
            borderBottom: `3px solid ${theme.palette.primary.main}`,
            paddingBottom: theme.spacing(2) - 3,
        },
    },
    listItemButton: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'start',
        padding: theme.spacing(2),
    },
    listContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    listContent: {
        flex: '1 0 auto',
        textAlign: 'left',
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
    },
    imageWrapper: {
        flex: '1 0 auto',
        textAlign: 'right',
        marginLeft: theme.spacing(1),
    },
    image: {
        width: '80px',
        height: '80px',
    },
    search: {
        paddingLeft: theme.spacing(1),
    },
    progress: {
        textAlign: 'center',
    },
    loadMoreButton: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    dataInfoWrapper: {
        textAlign: 'center',
    },
}))

interface LocationListProps {
    selectedLocationId?: string
    onLocationSelected(location: LocationListItem): void
}
const LocationList: SFC<LocationListProps> = ({ selectedLocationId, onLocationSelected }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const tenantId = useSelector((state: any) => state.login.value.user.id)
    const locationsState = useSelector((state: any) => state.locations.listItems)
    const mapExecuted = useSelector((state: any) => state.locations.mapItems.executed)
    const search = useSelector((state: any) => state.locations.search.value)
    
    const editedLocation = useSelector((state: any) => state.locations.edit.value)
    const addedLocation = useSelector((state: any) => state.locations.add.value)
    const deletedLocation = useSelector((state: any) => state.locations.delete.value)

    const [dataKey, setDataKey] = useState<number>(0)

    const handleSearchChanged = (search: string) => {
        dispatch(searchLocations(search))
    }

    const listHeightFill = useCalcHeightToBottomPartialList('listWrapper')

    useEffect(() => {
        if (locationsState.executed && locationsState.key !== dataKey) {
            dispatch(fetchListLocations(dataKey, search, locationsState, tenantId))
        }
    }, [dispatch, dataKey, search, locationsState, tenantId])

    useEffect(() => {
        setDataKey(dataKey - 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, editedLocation])

    useEffect(() => {
        setDataKey(dataKey + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, addedLocation, deletedLocation])

    return (
        <>
            <Grid container>
                <Grid item xs={10} className={classes.search}>
                    <SearchBar placeholder={t('locations.search')} value={search} onChange={handleSearchChanged} />
                </Grid>
                <Grid item xs={2} className={classes.progress}>
                    {(locationsState.executed === false || mapExecuted.executed === false) && (
                        <CircularProgress size={30} />
                    )}
                </Grid>
                <Grid id="listWrapper" item xs={12} className={classes.list} ref={listHeightFill}>
                    {locationsState.value.items ? locationsState.value.items.map((location: LocationListItem) => (
                        <Card key={location.id} className={classes.listItem} elevation={1}>
                            <ListItem
                                button
                                selected={location.id === selectedLocationId}
                                onClick={() => onLocationSelected(location)}
                                className={classes.listItemButton}
                            >
                                <div className={classes.listContentWrapper}>
                                    <CardContent className={classes.listContent}>
                                        <Typography variant="h6">{location.name}</Typography>
                                        <Typography component="div" variant="caption">
                                            {t('locations.chargersCount', {
                                                countTotal: location.connectorCount,
                                                countWorking: location.workingConnectors,
                                                countInUse: location.availableConnectors,
                                            })}
                                        </Typography>
                                        <Typography component="div" variant="caption">
                                            <Link to="/history">
                                            {t('locations.chargesToday', {
                                                count: location.chargingSessionsCount ?? 0,
                                            })}
                                            {location.chargingSessionsInProgressCount !== 0 && (
                                                <span>
                                                    {t('locations.chargesInProgress', {
                                                        count: location.chargingSessionsInProgressCount ?? 0,
                                                    })}
                                                </span>
                                            )}
                                            </Link>
                                        </Typography>
                                    </CardContent>
                                </div>
                                <div className={classes.imageWrapper}>
                                    <Image
                                        className={classes.image}
                                        imageClassName={classes.image}
                                        url={location.images.length > 0 ? location.images[0].url : undefined}
                                    />
                                </div>
                            </ListItem>
                        </Card>
                    )) : ""}
                    {locationsState.hasMorePages && locationsState.executed && (
                        <Button
                            className={classes.loadMoreButton}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                dispatch(fetchListLocations(dataKey, search, locationsState, tenantId))
                            }}
                        >
                            {t('common.loadMore')}
                        </Button>
                    )}
                    {locationsState.totalCount === 0 && (
                        <div className={classes.dataInfoWrapper}>
                            <NoData text={t('locations.noLocations')} />
                        </div>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default LocationList
