import React from 'react'
import SearchBar from '../../../core/searchBar/SearchBar'
import { Form } from 'react-final-form'
import AsyncAutoCompleteFormItem from '../../../shared/components/asyncAutoCompleteFormItem'
import { Button, Grid, Checkbox } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { filterStyles } from './FilterStyles'
import { useSelector, useDispatch } from 'react-redux'
import {
    searchSessions,
    filterBySocket,
    filterByUser,
    filterByInitSource,
    filterWithInvoiceOnly,
} from '../../../redux/actions/sessions'
import { UserClassProfile } from '../../../shared/types/user'
import { ChargerConnectorSocketType, ChargingInitSources } from '../../../shared/types/charger'
import Typography from '@material-ui/core/Typography'

export default function HistoryFilter() {
    const searchString = useSelector((state: any) => state.sessions.filter.search.value)
    const socketTypes = useSelector((state: any) => state.sessions.filter.socketType.value)
    const userTypes = useSelector((state: any) => state.sessions.filter.userType.value)
    const chargingInitSourceTypes = useSelector((state: any) => state.sessions.filter.chargingInitSource.value)
    const showOnlyWithInvoice = useSelector((state: any) => state.sessions.filter.showOnlyWithInvoice.value)

    const { t } = useTranslation()
    const classes = filterStyles()
    const dispatch = useDispatch()

    const handleSearchChanged = (newSearchString: string) => {
        dispatch(searchSessions(newSearchString))
    }

    const handleShowOnlyWithInvoice = () => {
        dispatch(filterWithInvoiceOnly(!showOnlyWithInvoice))
    }

    const onFormSubmit = (values: any) => {
        const filterSockets = values.socketTypes
            ?.filter((socket: any) => socket.id !== -1)
            .map((socket: any) => socket.id)
            .sort((a: number, b: number) => a - b)
        const filterUsers = values.userTypes
            ?.filter((user: any) => user.id !== -1)
            .map((user: any) => user.id)
            .sort((a: number, b: number) => a - b)
        const filterInitSources = values.chargingInitSourceTypes
            ?.filter((source: any) => source.id !== -1)
            .map((source: any) => source.id)
            .sort((a: number, b: number) => a - b)
        dispatch(filterBySocket(filterSockets))
        dispatch(filterByUser(filterUsers))
        dispatch(filterByInitSource(filterInitSources))
    }

    const classProfileOptionMapper = (x: any) => x
    const typeConnectorOptionMapper = (x: any) => x
    const chargingInitSourceTypesMapper = (x: any) => x

    const all = { id: -1, label: t('common.all') }
    const standardClassProfile = { id: UserClassProfile.Standard, label: t('users.standard') }
    const anonymousClassProfile = { id: UserClassProfile.Anonymous, label: t('users.anonymous') }

    const type1 = { id: ChargerConnectorSocketType.Type1, label: t('locations.connectorSocketTypeType1') }
    const type2 = { id: ChargerConnectorSocketType.Type2, label: t('locations.connectorSocketTypeType2') }
    const CHAdeMO = { id: ChargerConnectorSocketType.CHAdeMO, label: t('locations.connectorSocketTypeChademo') }
    const CCS = { id: ChargerConnectorSocketType.CCS, label: t('locations.connectorSocketTypeCcs') }

    const SEAK = { id: ChargingInitSources.SEAK, label: 'SEAK' }
    const HUBJECT = { id: ChargingInitSources.HUBJECT, label: 'HUBJECT' }

    let userOptions: any[] = []
    if (userTypes) {
        userOptions = userTypes.map((type: number) => {
            switch (type) {
                case UserClassProfile.Standard:
                    return standardClassProfile
                case UserClassProfile.Anonymous:
                    return anonymousClassProfile
                default:
                    return null
            }
        })
    } else userOptions.push(all)

    let socketOptions: any[] = []
    if (socketTypes) {
        socketOptions = socketTypes.map((type: number) => {
            switch (type) {
                case ChargerConnectorSocketType.Type1:
                    return type1
                case ChargerConnectorSocketType.Type2:
                    return type2
                case ChargerConnectorSocketType.CHAdeMO:
                    return CHAdeMO
                case ChargerConnectorSocketType.CCS:
                    return CCS
                default:
                    return null
            }
        })
    } else socketOptions.push(all)

    let chargingInitSources: any[] = []
    if (chargingInitSourceTypes) {
        chargingInitSources = chargingInitSourceTypes.map((type: number) => {
            switch (type) {
                case ChargingInitSources.SEAK:
                    return SEAK
                case ChargingInitSources.HUBJECT:
                    return HUBJECT
                default:
                    return null
            }
        })
    } else chargingInitSources.push(all)

    const classProfilesFetcher = () =>
        new Promise((resolve) => {
            resolve([all, standardClassProfile, anonymousClassProfile])
        })

    const connectorTypeFetcher = () =>
        new Promise((resolve) => {
            resolve([all, type1, type2, CHAdeMO, CCS])
        })

    const chargingInitSourceFetcher = () =>
        new Promise((resolve) => {
            resolve([all, SEAK, HUBJECT])
        })

    return (
        <Grid container className={classes.formWrapper}>
            <Grid item xs={12}>
                <Form
                    onSubmit={onFormSubmit}
                    render={({ handleSubmit, form }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container>
                                <Grid item xs={2} className={classes.searchWrapper}>
                                    <div>
                                        <SearchBar
                                            placeholder={t('history.search')}
                                            value={searchString}
                                            onChange={handleSearchChanged}
                                        />
                                        <Typography align="left" variant="inherit">
                                            {t('history.onlyWithInvoice')}
                                            <Checkbox
                                                name="showOnlyWithInvoice"
                                                style={{
                                                    color: '#6100ED',
                                                    marginBottom: '4px',
                                                }}
                                                checked={showOnlyWithInvoice}
                                                onChange={handleShowOnlyWithInvoice}
                                            />
                                        </Typography>
                                    </div>
                                </Grid>

                                <Grid item xs={3} className={classes.filter}>
                                    <AsyncAutoCompleteFormItem
                                        form={form}
                                        label={t('users.classProfiles')}
                                        field="userTypes"
                                        dataFetcher={classProfilesFetcher}
                                        optionMapper={classProfileOptionMapper}
                                        initialOptions={userOptions}
                                    />
                                </Grid>
                                <Grid item xs={3} className={classes.filter}>
                                    <AsyncAutoCompleteFormItem
                                        form={form}
                                        label={t('history.connectorType')}
                                        field="socketTypes"
                                        dataFetcher={connectorTypeFetcher}
                                        optionMapper={typeConnectorOptionMapper}
                                        initialOptions={socketOptions}
                                    />
                                </Grid>
                                <Grid item xs={3} className={classes.filter}>
                                    <AsyncAutoCompleteFormItem
                                        form={form}
                                        label={t('history.initSource')}
                                        field="chargingInitSourceTypes"
                                        dataFetcher={chargingInitSourceFetcher}
                                        optionMapper={chargingInitSourceTypesMapper}
                                        initialOptions={chargingInitSources}
                                    />
                                </Grid>
                                <Grid item xs={1} className={classes.filterButtonWrapper}>
                                    <Button variant="contained" color="primary" type="submit">
                                        {t('history.filter')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
            </Grid>
        </Grid>
    )
}
