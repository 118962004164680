import React, { SFC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Dialog,
    DialogTitle,
    makeStyles,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    Checkbox,
} from '@material-ui/core'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import { UserModel } from '../../../shared/types/user'
import { ValidationErrors } from 'final-form'
import { useDispatch, useSelector } from 'react-redux'
import { addUser, editUser } from '../../../redux/actions/users'
import { setRequiredErrors } from '../../../utils/ui'
import { validateEmail } from '../../../shared/utils/validation'
import { DialogProgress } from '../../../shared/components/dialogProgress'
import { DialogConfirmButton } from '../../../shared/components/dialogConfirmButton'
import { CountrySelect } from '../../../shared/components/countrySelect'
import { TagModel, TagUser } from '../../../shared/types/tag'
import { fetchAllUserTags } from '../../../redux/actions/tags'
import { Autocomplete } from '@material-ui/lab'
import { getChargeTenantId, getMainTenantId } from '../../../config'
import { AdminEditModel, fetchTenants } from '../../../redux/actions/tenants'

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiFilledInput-root': {
            borderRadius: theme.spacing(0.5),
        },
        '& .MuiFilledInput-root::before': {
            border: 0,
        },
    },
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'hidden',
    },
}))

interface UserAddEditDialogProps {
    isAdd: boolean
    onClose(user?: UserModel): void
    initialModel?: UserModel
}
const UserAddEditDialog: SFC<UserAddEditDialogProps> = ({ isAdd, onClose, initialModel }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const stateSelector = isAdd ? (state: any) => state.users.add : (state: any) => state.users.edit
    const [showHidden, setShowHidden] = useState<boolean>(false)

    const tenantId = useSelector((state: any) => state.login.value.user.id)
    const tenantsState = useSelector((state: any) => state.tenants)
    const [userTenants, setUserTenants] = useState<string>(initialModel?.tenants || '')
    const [allTenants, setAllTenants] = useState<AdminEditModel[]>([]);

    const [tenantsMapped, setTenantsMapped] = useState<AdminEditModel[]>([])

    const mainTenantId = getMainTenantId()
    
    const allTags = useSelector((state: any) => state.tags.items.user.value)
    const [userTags, setUserTags] = useState<TagUser[]>([])
    const [userTagsMapped, setUserTagsMapped] = useState<TagModel[]>([])

    const handleShowHiddenChange = () => {
        setShowHidden(!showHidden)
    }

    const handleTagChange = (event: any, newValue: TagModel[]) => {
        // Set the new chargerTags state
        setUserTagsMapped(newValue)

        const newUserTags: TagUser[] = newValue.map((tag) => ({
            tagId: tag.id,
            userId: initialModel?.id,
        }))

        // Set the new chargerTags state
        setUserTags(newUserTags)
    }
    const handleTenantChange = (event: any, newValue: AdminEditModel[]) => {
        // Set the new chargerTags state
        setTenantsMapped(newValue)
        setUserTenants(newValue.map(tenant => tenant.id).join(','));
    } 
    
    const setTenants = () => {
        if (tenantId !== mainTenantId) {
            if (isAdd) {
                return tenantId
            } else {
                // check if it is not already included
                if (initialModel && !initialModel?.tenants.includes(tenantId)) {
                    return (initialModel.tenants += ',' + tenantId)
                }
            }
        } else {
            if (isAdd) {
                return getChargeTenantId();
            } else {
                return userTenants
            }
        }
    }

    useEffect(() => {
        if (initialModel && initialModel.tags && allTags) {
            // Filter allTags to select only those TagModel objects whose id matches TagCharger.tagId
            const selectedTags = allTags.filter((tag: TagModel) =>
                initialModel.tags.some((tagUser: TagUser) => tag.id === tagUser.tagId)
            )
            setUserTagsMapped(selectedTags)
        }
    }, [initialModel, allTags])

    useEffect(() => {
        if (initialModel && initialModel.tenants && allTenants) {
            const userTenants = allTenants.filter((tenant: AdminEditModel) => initialModel.tenants.includes(tenant.id));
            setTenantsMapped(userTenants);
        }
    }, [initialModel, allTenants])

    useEffect(() => {
        if (tenantsState && tenantsState.value) {
            const allTenants = tenantsState.value.filter((tenant: AdminEditModel) => tenant.id !== mainTenantId);
            setAllTenants(allTenants);
        }
    }, [initialModel, tenantsState])

    useEffect(() => {
        if (initialModel?.showHiddenLocation) {
            setShowHidden(initialModel.showHiddenLocation)
        }
    }, [initialModel])

    useEffect(() => {
        dispatch(fetchAllUserTags())
        dispatch(fetchTenants())
    }, [dispatch])

    return (
        <Dialog className={classes.dialog} open={true}>
            <DialogProgress stateSelector={stateSelector} />
            <DialogTitle>{isAdd ? t('users.newUser') : t('users.editUser')}</DialogTitle>

            <Form
                onSubmit={async (model) => {
                    const newUserObj = {
                        ...model,
                        showHiddenLocation: showHidden,
                        tags: userTags,
                        tenants: setTenants(),
                    }

                    const result: any = await dispatch(
                        isAdd ? addUser(newUserObj as UserModel) : editUser(newUserObj as UserModel)
                    )
                    if (result) {
                        onClose(result)
                    }
                }}
                initialValues={initialModel}
                validate={(model: any): ValidationErrors => {
                    const result: any = {}

                    let requiredFields = ['firstName', 'lastName', 'email']
                    if (isAdd) {
                        requiredFields = [...requiredFields, 'password', 'confirmedPassword']
                        if (model.password && model.confirmedPassword && model.password !== model.confirmedPassword) {
                            result.confirmedPassword = t('users.passwordsMustMatch')
                        }
                    }
                    if (!isAdd && initialModel?.paymentRequestId) {
                        requiredFields = [...requiredFields, 'street', 'zip', 'city', 'country']
                    }
                    setRequiredErrors(result, model, requiredFields, t('common.required'))
                    if (model.email && !validateEmail(model.email)) {
                        result.email = t('users.invalidEmail')
                    }
                    return result
                }}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                        <DialogContent>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        name="firstName"
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 64,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('users.firstName')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="lastName"
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 64,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('users.lastName')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="carLicensePlate"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 64,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('users.carLicensePlate')}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        name="email"
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 320,
                                        }}
                                        type="email"
                                        variant="filled"
                                        label={t('users.email')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="phone"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 32,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('users.phone')}
                                    />
                                </Grid>
                                {isAdd && (
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="password"
                                                fullWidth
                                                required
                                                inputProps={{
                                                    maxLength: 64,
                                                }}
                                                type="password"
                                                variant="filled"
                                                label={t('users.password')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="confirmedPassword"
                                                fullWidth
                                                required
                                                inputProps={{
                                                    maxLength: 64,
                                                }}
                                                type="password"
                                                variant="filled"
                                                label={t('users.confirmPassword')}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={9}>
                                    <TextField
                                        name="street"
                                        fullWidth
                                        required={
                                            initialModel?.paymentRequestId !== null &&
                                            initialModel?.paymentRequestId !== undefined
                                        }
                                        inputProps={{
                                            maxLength: 256,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('users.street')}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        name="zip"
                                        fullWidth
                                        required={
                                            initialModel?.paymentRequestId !== null &&
                                            initialModel?.paymentRequestId !== undefined
                                        }
                                        inputProps={{
                                            maxLength: 64,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('users.zip')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="city"
                                        fullWidth
                                        required={
                                            initialModel?.paymentRequestId !== null &&
                                            initialModel?.paymentRequestId !== undefined
                                        }
                                        inputProps={{
                                            maxLength: 256,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('users.city')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CountrySelect
                                        name="country"
                                        required={
                                            initialModel?.paymentRequestId !== null &&
                                            initialModel?.paymentRequestId !== undefined
                                        }
                                        label={t('users.state')}
                                        variant="filled"
                                    ></CountrySelect>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="companyId"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 64,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('users.companyId')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="taxId"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 64,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('users.taxId')}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        name="vatId"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 64,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('users.vatId')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="rfid"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('users.rfid')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        options={allTags}
                                        getOptionLabel={(tag: TagModel) => tag.name}
                                        value={userTagsMapped}
                                        onChange={handleTagChange}
                                        renderInput={(params) => (
                                            <TextField name={''} {...params} variant="filled" label="Tags" />
                                        )}
                                    />
                                </Grid>
                                <Grid hidden={!(tenantId && tenantId === mainTenantId)} item xs={12}>
                                    <Autocomplete
                                        multiple
                                        options={allTenants}
                                        getOptionLabel={(tenant: AdminEditModel) => tenant.login}
                                        value={tenantsMapped}
                                        onChange={handleTenantChange}
                                        renderInput={(params) => (
                                            <TextField name={''} {...params} variant="filled" label="Tenants" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption" style={{ fontSize: '18px', color: '#000000' }}>
                                        <Checkbox
                                            name="showHiddenLocation"
                                            checked={showHidden}
                                            onChange={handleShowHiddenChange}
                                            style={{
                                                color: '#6100ED',
                                                marginBottom: '4px',
                                                paddingLeft: '0px',
                                                paddingBottom: '0px',
                                                paddingTop: '0px',
                                                paddingRight: '8px',
                                            }}
                                        />
                                        {t('users.showHiddenLocation')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={() => {
                                    onClose()
                                }}
                                color="primary"
                            >
                                {t('common.cancel')}
                            </Button>
                            <DialogConfirmButton
                                label={isAdd ? t('common.create') : t('common.change')}
                                stateSelector={stateSelector}
                            />
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    )
}

export default UserAddEditDialog
