import React, { SFC } from 'react'
import { useTranslation } from 'react-i18next'
import { UserModel } from '../../../shared/types/user'
import { Grid, CircularProgress, Card, CardContent, Typography, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SearchBar from '../../../core/searchBar/SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { searchUsers } from '../../../redux/actions/users'
import { useCalcHeightToBottomPartialList } from '../../../utils/ui'
import { NoData } from '../../../shared/components/NoData'

const useStyles = makeStyles((theme) => ({
    list: {
        overflowY: 'auto',
        marginTop: theme.spacing(3),
    },
    listItem: {
        margin: theme.spacing(1, 1, 2, 1),
        display: 'flex',
        '& .Mui-selected': {
            borderBottom: `3px solid ${theme.palette.primary.main}`,
            paddingBottom: theme.spacing(2) - 3,
        },
    },
    listItemButton: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'start',
        padding: theme.spacing(2),
    },
    listContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    listContent: {
        flex: '1 0 auto',
        textAlign: 'left',
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
    },
    search: {
        paddingLeft: theme.spacing(1),
    },
    progress: {
        textAlign: 'center',
    },
    dataInfoWrapper: {
        textAlign: 'center',
        paddingLeft: theme.spacing(1),
    },
}))

interface UserListProps {
    users: UserModel[]
    selectedUser?: UserModel
    onUserSelected(user: UserModel): void
}
const UserList: SFC<UserListProps> = ({ users, selectedUser, onUserSelected }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const usersExecuted = useSelector((state: any) => state.users.items.executed)

    const search = useSelector((state: any) => state.users.search.value)

    const handleSearchChanged = (search: string) => {
        dispatch(searchUsers(search))
    }

    const listHeightFill = useCalcHeightToBottomPartialList('listWrapper')

    return (
        <>
            <Grid container>
                <Grid item xs={10} className={classes.search}>
                    <SearchBar placeholder={t('users.search')} value={search} onChange={handleSearchChanged} />
                </Grid>
                <Grid item xs={2} className={classes.progress}>
                    {!usersExecuted && <CircularProgress size={30} />}
                </Grid>
                <Grid id="listWrapper" item xs={12} className={classes.list} ref={listHeightFill}>
                    {usersExecuted && users.length === 0 ? (
                        <div className={classes.dataInfoWrapper}>
                            <NoData text={t('users.noUsers')} />
                        </div>
                    ) : (
                            users.map((user) => (
                                <Card key={user.id} className={classes.listItem} elevation={1}>
                                    <ListItem
                                        button
                                        selected={user === selectedUser}
                                        onClick={() => onUserSelected(user)}
                                        className={classes.listItemButton}
                                    >
                                        <div className={classes.listContentWrapper}>
                                            <CardContent className={classes.listContent}>
                                                <Typography variant="h6">{`${user.firstName} ${user.lastName}`}</Typography>
                                                <Typography component="div" variant="caption">
                                                    {user.email}
                                                </Typography>
                                            </CardContent>
                                        </div>
                                    </ListItem>
                                </Card>
                            ))
                        )}
                </Grid>
            </Grid>
        </>
    )
}

export default UserList
