import {
    asyncActionCreator,
    asyncPagedActionCreator, valueHolderActionCreator,
} from './../../shared/redux/actions/common'
import {
    FETCH_SESSIONS_HISTORY,
    SEARCH_SESSIONS,
    FILTER_BY_CONNECTOR,
    FILTER_BY_SOCKET,
    FILTER_BY_USER,
    FILTER_BY_INIT_SOURCE,
    SORT_BY_PROPERTY,
    SORT_BY_ORDER,
    FILTER_BY_INVOICE_ONLY,
    RESEND_INVOICE
} from '../const'
import { getJson, sendJson } from '../../shared/utils/api'


export const searchSessions = (search: string) => {
    return valueHolderActionCreator(SEARCH_SESSIONS, search)
}

export const filterByConnector = (connectorType: number) => {
    return valueHolderActionCreator(FILTER_BY_CONNECTOR, connectorType)
}

export const filterBySocket = (socketType: string) => {
    return valueHolderActionCreator(FILTER_BY_SOCKET, socketType)
}

export const filterByUser = (userType: string) => {
    return valueHolderActionCreator(FILTER_BY_USER, userType)
}

export const filterByInitSource = (initSource: string) => {
    return valueHolderActionCreator(FILTER_BY_INIT_SOURCE, initSource)
}

export const sortByProperty = (property: string) => {
    return valueHolderActionCreator(SORT_BY_PROPERTY, property)
}

export const sortByOrder = (order: string) => {
    return valueHolderActionCreator(SORT_BY_ORDER, order)
}

export const filterWithInvoiceOnly = (onlyWithInvoice: boolean) => {
    return valueHolderActionCreator(FILTER_BY_INVOICE_ONLY, onlyWithInvoice)
}

export const sendInvoiceForCharging = (id: string) => {
    return asyncActionCreator(RESEND_INVOICE, () => sendJson(`ChargingSession/sendInvoice/${id}`, 'POST'), {})
}
// export const sendInvoiceForCharging = (id: string) => {
//     sendJson(`ChargingSession/sendInvoice/${id}`, 'POST')
// }

export const fetchSessions = (key: any, state: any, page?: number, rowsPerPage?: number, filter?: any, sort?: any) => {
    let url = 'ChargingSession/history?'
    if (filter.search.value) {
        url += `SearchQuery=${filter.search.value}&`
    }
    if (filter.connectorType.value?.length !== 0) {
        filter.connectorType.value?.sort((a: number, b: number) => a - b).map((connector: string) => url += `ConnectorTypes=${connector}&`)
    }
    if (filter.socketType.value?.length !== 0) {
        filter.socketType.value?.sort((a: number, b: number) => a - b).map((socket: string) => url += `SocketTypes=${socket}&`)
    }
    if (filter.userType.value?.length !== 0) {
        filter.userType.value?.sort((a: number, b: number) => a - b).map((user: string) => url += `UserTypes=${user}&`)
    }
   
    if (filter.chargingInitSource.value?.length !== 0) {
        filter.chargingInitSource.value?.sort((a: number, b: number) => a - b).map((chargingInitSource: string) => url += `ChargingInitSources=${chargingInitSource}&`)
    }
    url += `OnlyWithInvoice=${filter.showOnlyWithInvoice.value}&`
    if (sort.property && sort.order) {
        switch (sort.property.value) {
            case 'location': { url += `Sort=${sort.property.value}.name ${sort.order.value}`; break }
            case 'charger': { url += `Sort=${sort.property.value}.name ${sort.order.value}`; break }
            case 'connector': { url += `Sort=chargingConnector.socketType ${sort.order.value}`; break }
            case 'pricelist': { url += `Sort=priceList.name ${sort.order.value}`; break }
            case 'user': { url += `Sort=${sort.property.value}.email ${sort.order.value}`; break }
            case 'userType': { url += `Sort=usertype ${sort.order.value}`; break }
            case 'idTag': { url += `Sort=idTag ${sort.order.value}`; break }
            case 'car': { url += `Sort=${sort.property.value}.carLicensePlate ${sort.order.value}`; break }
            case 'initSource': { url += `Sort=chargingInitSource ${sort.order.value}`; break }
            case 'export_invoice': break
            default: url += `Sort=${sort.property.value} ${sort.order.value}`
        }

    }

    return asyncPagedActionCreator(
        FETCH_SESSIONS_HISTORY,
        (pageIndex, pageSize) => getJson(url + `&page=${pageIndex}&pageSize=${pageSize}`),
        key,
        state,
        page,
        rowsPerPage
    )
}

export const queryBuilder = (filter?: any, sort?: any) => {
    let url = ''
    if (filter.search.value) {
        url += `SearchQuery=${filter.search.value}&`
    }
    if (filter.connectorType.value?.length !== 0) {
        filter.connectorType.value?.map((connector: string) => url += `ConnectorTypes=${connector}&`)
    }
    if (filter.socketType.value?.length !== 0) {
        filter.socketType.value?.map((socket: string) => url += `SocketTypes=${socket}&`)
    }
    if (filter.userType.value?.length !== 0) {
        filter.userType.value?.map((user: string) => url += `UserTypes=${user}&`)
    }
    if (sort.property && sort.order)
        url += `Sort=${sort.property.value} ${sort.order.value}`

    return url
}

