import React from "react";
import { Autocomplete } from "mui-rff";
import { TextField } from "mui-rff";
import { TagTypes } from "../types/tag";

export type TagType = {
    label: string;
    code: string;
};

type TagTypeSelectProps = {
    name: string;
    required: boolean;
    label: string;
    variant: any;
    startValue: any;
};

export function TagTypeSelect({ name, required, label, variant, startValue }: TagTypeSelectProps) {
    return (
        <Autocomplete
            id="country-select-demo"
            style={{ width: "100%" }}
            name={name}
            label=""
            options={TagTypes}
            autoHighlight
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.code}
            renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    required={required}
                    label={label}
                    variant={variant}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}
