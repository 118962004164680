import { LocationListItem, LocationInfo } from './../../shared/types/location'
import {
    ADD_CHARGER,
    DELETE_CHARGER,
    EDIT_CHARGER,
    FETCH_LOCATION_FOR_DETAIL,
    ADD_LOCATION,
    EDIT_LOCATION,
    DELETE_LOCATION,
    UPLOAD_LOCATION_PHOTOS,
    DELETE_LOCATION_PHOTO,
    UPLOAD_CHARGER_PHOTOS,
    DELETE_CHARGER_PHOTO,
    SEARCH_LOCATIONS,
    UPDATE_CONNECTOR_AVAILABILITY,
} from './../const/index'
import { Image } from '../../shared/types/common'

export const locationChargersReducer =
    () =>
    (state = { chargers: new Array<any>() }, action: any) => {
        switch (action.type) {
            case `${FETCH_LOCATION_FOR_DETAIL}_SUCCESS`: {
                return {
                    locationId: action.payload.result.id,
                    chargers: action.payload.result.chargers.map((x: any) => {
                        return {
                            ...x,
                        }
                    }),
                }
            }
            case `${ADD_CHARGER}_SUCCESS`: {
                return {
                    ...state,
                    chargers: [...state.chargers, action.payload.result],
                }
            }
            case `${DELETE_CHARGER}_SUCCESS`: {
                return {
                    ...state,
                    chargers: state.chargers.filter((x) => x.id !== action.payload.chargerId),
                }
            }

            case `${EDIT_CHARGER}_SUCCESS`: {
                return {
                    ...state,
                    chargers: state.chargers.map((x) =>
                        x.id === action.payload.result.id ? action.payload.result : x
                    ),
                }
            }
            case `${UPLOAD_CHARGER_PHOTOS}_SUCCESS`: {
                return {
                    ...state,
                    chargers: state.chargers.map((x) => {
                        if (x.id === action.payload.chargerId) {
                            x.images = x.images.concat(action.payload.result)
                        }
                        return x
                    }),
                }
            }
            case `${DELETE_CHARGER_PHOTO}_SUCCESS`: {
                return {
                    ...state,
                    chargers: state.chargers.map((x) => {
                        if (x.id === action.payload.chargerId) {
                            x.images = x.images.filter((image: Image) => image.id !== action.payload.photoId)
                        }
                        return x
                    }),
                }
            }
            default: {
                return state
            }
        }
    }

export const locationsListUpdateReducer = (state: any, action: any) => {
    switch (action.type) {
        case `${FETCH_LOCATION_FOR_DETAIL}_SUCCESS`:
        case `${EDIT_LOCATION}_SUCCESS`: {
            return {
                ...state,
                data: state.data.map((x: LocationListItem) =>
                    x.id === action.payload.result.id ? { ...action.payload.result } : x
                ),
            }
        }
        case `${DELETE_LOCATION}_SUCCESS`: {
            return {
                ...state,
                data: state.data.filter((x: LocationListItem) => x.id !== action.payload.locationId),
            }
        }
        case `${UPLOAD_LOCATION_PHOTOS}_SUCCESS`: {
            return {
                ...state,
                data: state.data.map((x: LocationListItem) =>
                    x.id === action.payload.locationId ? { ...x, images: x.images.concat(action.payload.result) } : x
                ),
            }
        }
        case `${DELETE_LOCATION_PHOTO}_SUCCESS`: {
            return {
                ...state,
                data: state.data.map((x: LocationListItem) =>
                    x.id === action.payload.locationId
                        ? { ...x, images: x.images.filter((y) => y.id !== action.payload.photoId) }
                        : x
                ),
            }
        }
        default:
            return state
    }
}

const updateMapLocation = (location: LocationInfo): LocationInfo => ({
    id: location.id,
    latitude: location.latitude,
    longitude: location.longitude,
    chargerCount: location.chargerCount,
    chargersInUseCount: location.chargersInUseCount,
    availableConnectors: location.availableConnectors,
    workingConnectors: location.workingConnectors,
    connectedChargers: location.connectedChargers,
    connectorCount: location.connectorCount,
})

export const locationsMapUpdateReducer = (state: any, action: any) => {
    switch (action.type) {
        case `${ADD_LOCATION}_SUCCESS`: {
            return {
                ...state,
                value: state.value.concat([updateMapLocation(action.payload.result)]),
            }
        }
        case `${FETCH_LOCATION_FOR_DETAIL}_SUCCESS`:
        case `${EDIT_LOCATION}_SUCCESS`: {
            const updatedLocation = action.payload.result
            let currentLocation = state.value.filter((location: LocationInfo) => location.id === updatedLocation.id)[0]
            if (
                currentLocation.latitude !== updatedLocation.latitude ||
                currentLocation.longitude !== updatedLocation.longitude ||
                currentLocation.chargerCount !== updatedLocation.chargerCount ||
                currentLocation.chargersInUseCount !== updatedLocation.chargersInUseCount ||
                currentLocation.availableConnectors !== updatedLocation.availableConnectors ||
                currentLocation.workingConnectors !== updatedLocation.workingConnectors ||
                currentLocation.connectedChargers !== updatedLocation.connectedChargers ||
                currentLocation.connectorCount !== updatedLocation.connectorCount
            ) {
                return {
                    ...state,
                    value: state.value.map((x: LocationInfo) =>
                        x.id === updatedLocation.id ? updateMapLocation(action.payload.result) : x
                    ),
                }
            }
            return state
        }
        case `${DELETE_LOCATION}_SUCCESS`: {
            return {
                ...state,
                value: state.value.filter((x: LocationListItem) => x.id !== action.payload.locationId),
            }
        }
        default:
            return state
    }
}

export const locationDetailUpdateReducer = (state: any, action: any) => {
    switch (action.type) {
        case `${UPLOAD_LOCATION_PHOTOS}_SUCCESS`: {
            return {
                ...state,
                value:
                    state.value.id === action.payload.locationId
                        ? { ...state.value, images: state.value.images.concat(action.payload.result) }
                        : state.value,
            }
        }
        case `${DELETE_LOCATION_PHOTO}_SUCCESS`: {
            return {
                ...state,
                value:
                    state.value.id === action.payload.locationId
                        ? {
                              ...state.value,
                              images: state.value.images.filter((y: any) => y.id !== action.payload.photoId),
                          }
                        : state.value,
            }
        }
        case `${UPDATE_CONNECTOR_AVAILABILITY}`: {
            return {
                ...state,
                value: {
                    ...state.value,
                    chargers: state.value.chargers.map((charger: any) => {
                        if (charger.id === action.payload.value.chargerId) {
                            return {
                                ...charger,
                                connectors: charger.connectors.map((connector: any) => {
                                    if (connector.id === action.payload.value.connectorId) {
                                        return {
                                            ...connector,
                                            availability: action.payload.value.availType,
                                        }
                                    }
                                    return connector
                                }),
                            }
                        }
                        return charger
                    }),
                },
            }
        }
        default:
            return state
    }
}

export const locationSelectedIdUpdateReducer = (state: any, action: any) => {
    switch (action.type) {
        case SEARCH_LOCATIONS: {
            return {
                ...state,
                value: null,
            }
        }
        case `${DELETE_LOCATION}_SUCCESS`: {
            if (state.value === action.payload.locationId) {
                return {
                    ...state,
                    value: null,
                }
            } else {
                return state
            }
        }
        default:
            return state
    }
}
