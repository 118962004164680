import { ADD_TARIFF, EDIT_TARIFF, TOGGLE_ACTIVE_TARIFF } from './../const/index'

export const tariffReducer = () => (state: any, action: any) => {
    switch (action.type) {
        case `${ADD_TARIFF}_SUCCESS`: {
            return {
                ...state,
                value: [...state.value, action.payload.result],
            }
        }

        case `${EDIT_TARIFF}_SUCCESS`: {
            return {
                ...state,
                value: state.value.map((x: any) => (x.id === action.payload.result.id ? action.payload.result : x)),
            }
        }

        case `${TOGGLE_ACTIVE_TARIFF}_SUCCESS`: {
            return {
                ...state,
                value: state.value.map((x: any) =>
                    x.id === action.payload.tariffId ? { ...x, active: !x.active } : x
                ),
            }
        }

        default: {
            return state
        }
    }
}
