import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Map from './Map'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import LocationAddEditDialog from './locationAddEditDialog/LocationAddEditDialog'
import LocationList from './locationList/LocationList'
import LocationDetail from './locationDetail/LocationDetail'
import { LocationInfo, LocationListItem, Location, LocationModel } from '../../shared/types/location'
import { useCalcHeightToBottom } from '../../utils/ui'
import { useTranslation } from 'react-i18next'
import { SplitButton } from '../../shared/components/splitButton'
import { Coordinates } from '../../shared/types/common'
import { setSelectedLocationId } from '../../redux/actions/locations'

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        padding: theme.spacing(1),
    },
    actionButtonWrapper: {
        padding: theme.spacing(1, 0),
        textAlign: 'right',
    },
    listWrapper: {
        paddingTop: theme.spacing(2),
    },
    mapWrapper: {
        paddingTop: theme.spacing(2),
        width: '100%',
    },
}))

export default function Locations() {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const selectedLocationId = useSelector((state: any) => state.locations.selectedId.value)

    const [mapCenter, setMapCenter] = useState<Coordinates>()

    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)
    const [addDialogInitialModel, setAddDialogInitialModel] = useState<Location>()
    const [mapAdding, setMapAdding] = useState<boolean>(false)

    const mapHeightFill = useCalcHeightToBottom('locationsMapWrapper')

    const onLocationDetailClose = () => {
        dispatch(setSelectedLocationId(undefined))
    }

    const openAddDialog = (location: LocationModel) => {
        setAddDialogInitialModel({ id: undefined, address: "defaultAddress",...location })
        setAddDialogOpen(true)
    }

    const addButtonOptions = {
        primary: {
            label: t('locations.newLocation'),
            function: openAddDialog,
        },
        options: [
            {
                label: t('locations.newByManualEntry'),
                function: openAddDialog,
            },
            {
                label: t('locations.newByMap'),
                function: () => setMapAdding(true),
            },
        ],
    }

    const onAddDialogClose = (location: Location) => {
        setAddDialogOpen(false)
        setAddDialogInitialModel(undefined)
    }

    const onMapAddEnd = (location: LocationModel) => {
        setMapAdding(false)
        if (location) {
            setAddDialogInitialModel({ id: undefined, ...location })
            setAddDialogOpen(true)
        }
    }

    const onLocationSelectedFromMap = (location: LocationInfo) => {
        dispatch(setSelectedLocationId(location.id))
    }

    const onLocationSelectedFromList = (location: LocationListItem) => {
        dispatch(setSelectedLocationId(location.id))
        setMapCenter({ lat: location.latitude, lng: location.longitude })
    }

    return (
        <>
            <Grid container spacing={0}>
                <Grid item sm={2} className={classes.headerWrapper}>
                    <Typography align="left" variant="h3">
                        {t('locations.title')}
                    </Typography>
                </Grid>
                <Grid item sm={10} className={classes.actionButtonWrapper}>
                    <SplitButton {...addButtonOptions} />
                    <LocationAddEditDialog
                        open={addDialogOpen}
                        isAdd={true}
                        onClose={onAddDialogClose}
                        initialModel={addDialogInitialModel}
                    />
                </Grid>
                <Grid item sm={4} className={classes.listWrapper}>
                    <LocationList
                        selectedLocationId={selectedLocationId}
                        onLocationSelected={onLocationSelectedFromList}
                    />
                </Grid>
                <Grid item className={classes.mapWrapper} sm={8}>
                    <div id="locationsMapWrapper" ref={mapHeightFill}>
                        <Map
                            center={mapCenter}
                            onCenterChanged={setMapCenter}
                            onLocationSelected={onLocationSelectedFromMap}
                            adding={mapAdding}
                            onAddEnd={onMapAddEnd}
                        />
                    </div>
                </Grid>
            </Grid>
            <LocationDetail onClose={onLocationDetailClose} locationId={selectedLocationId} />
        </>
    )
}
