import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ListItemIcon, ListItemText, ListItem, List } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import MapIcon from '@material-ui/icons/Map'
import SpeedIcon from '@material-ui/icons/Speed'
import HistoryIcon from '@material-ui/icons/History'
import EventNote from '@material-ui/icons/EventNote'
import Commute from '@material-ui/icons/Commute'

import ReportIcon from '@material-ui/icons/Report'
import AdminPanelSettingsIcon from '@material-ui/icons/SupervisorAccount'
import { useHistory, useLocation, RouteProps, matchPath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getMainTenantId } from '../../config'

const useStyles = makeStyles((theme) => ({
    list: {
        padding: 0,
    },
    menuItem: {
        borderRadius: '6px',
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.light,
            '& .MuiListItemIcon-root': {
                color: theme.palette.primary.main,
            },
            '& $focusRectangle': { bottom: '0' },
        },
        position: 'relative',
        padding: theme.spacing(1, 3, 1, 0),
        '& .MuiListItemIcon-root': {
            minWidth: theme.spacing(5),
        },
        '& .MuiListItemText-primary': {
            fontSize: '15px',
            fontWeight: 600,
            color: '#4A4A4A',
        },
    },
    focusRectangle: {
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(1 / 2),
        position: 'absolute',
        top: '0',
    },
    listItemContent: {
        display: 'inline-flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(3),
    },
}))

interface SidebarProps {
    routes: Array<RouteProps>
}
export default function Sidebar({ routes }: SidebarProps) {
    const classes = useStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    
    type MenuItemDescriptor = {
        icon: ReactElement
        text: string
        url: string
    }
    
    const menuItems: Array<MenuItemDescriptor> = [
        { icon: <MapIcon />, text: t('locations.title'), url: routes[0].path as string },
        { icon: <PersonOutlineIcon />, text: t('users.title'), url: routes[1].path as string },
        { icon: <LocalOfferIcon />, text: t('tags.title'), url: routes[2].path as string },
        { icon: <AttachMoneyIcon />, text: t('pricingConditions.title'), url: routes[3].path as string },
        { icon: <SpeedIcon />, text: t('tariffs.title'), url: routes[4].path as string },
        { icon: <HistoryIcon />, text: t('history.title'), url: routes[5].path as string },
        { icon: <EventNote />, text: t('reports.title'), url: routes[6].path as string },
        { icon: <Commute />, text: t('hubject.title'), url: routes[7].path as string },
        { icon: <AdminPanelSettingsIcon />, text: t('adminSettings.title'), url: routes[8].path as string },
    ]

    // show logs only for superadmin
    const tenantId = useSelector((state: any) => state.login.value.user.id)
    if (tenantId && tenantId === getMainTenantId()) {
        menuItems.splice(9, 0, { icon: <ReportIcon />, text: t('logs.title'), url: routes[9].path as string });
    }

    return (
        <List component="nav" className={classes.list}>
            {menuItems.map((item, index) => (
                <ListItem
                    key={index}
                    button
                    selected={Boolean(matchPath(location.pathname, routes[index]))}
                    className={classes.menuItem}
                    onClick={() => {
                        history.push(item.url)
                    }}
                    disableRipple
                >
                    <div className={classes.focusRectangle} />
                    <div className={classes.listItemContent}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </div>
                </ListItem>
            ))}
        </List>
    )
}
