import { sendJson } from './../../shared/utils/api'
import { asyncActionCreator, valueHolderActionCreator } from './../../shared/redux/actions/common'
import { ADD_PRICING_CONDITIONS, EDIT_PRICING_CONDITIONS, DELETE_PRICING_CONDITIONS, FETCH_PRICING_CONDITIONS, SEARCH_PRICING_CONDITIONS } from '../const'
import { getJson } from '../../shared/utils/api'
import { PricingConditionModel } from '../../shared/types/pricingCondition'

export const addPricingCondition = (pricingCondition: PricingConditionModel) => {
    return asyncActionCreator(ADD_PRICING_CONDITIONS, () => sendJson('pricingCondition', 'POST', pricingCondition))
}

export const editPricingCondition = (pricingCondition: PricingConditionModel) => {
    return asyncActionCreator(EDIT_PRICING_CONDITIONS, () => sendJson(`pricingCondition/${pricingCondition.id}`, 'PATCH', pricingCondition))
}

export const deletePricingCondition = (pricingCondition: PricingConditionModel) => {
    return asyncActionCreator(DELETE_PRICING_CONDITIONS, () => sendJson(`pricingCondition/${pricingCondition.id}`, 'DELETE'))
}

export const fetchPricingConditions = () => {
    return asyncActionCreator(FETCH_PRICING_CONDITIONS, () => getJson('pricingCondition'))
}

export const searchPricingConditions = (search: string) => {
    return valueHolderActionCreator(SEARCH_PRICING_CONDITIONS, search)
}
