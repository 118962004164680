import React from 'react'
import { Typography } from '@material-ui/core'

type TabPanelProps = {
    children?: React.ReactNode
    index: any
    value: any
    padding?: number
    className?: string
}
export function TabPanel({ children, index, value, padding, className }: TabPanelProps) {
    return (
        <Typography component="div" className={className} role="tabpanel" hidden={value !== index}>
            {value === index && children}
        </Typography>
    )
}
