import { sendJson } from './../../shared/utils/api'
import { UserModel } from './../../shared/types/user'
import {
    asyncActionCreator,
    asyncPagedActionCreator,
    valueHolderActionCreator,
} from './../../shared/redux/actions/common'
import { FETCH_USERS, SEARCH_USERS, ADD_USER, EDIT_USER, DELETE_USER, FETCH_SESSIONS } from '../const'
import { getJson, patchProperty } from '../../shared/utils/api'

const changeUserErrorCodeMapping = [['2', 'users.emailAlreadyUsed'],['3', 'users.userAddedToTenant']]

export const addUser = (user: UserModel) => {
    return asyncActionCreator(ADD_USER, () => sendJson('users', 'POST', user), {}, changeUserErrorCodeMapping)
}

export const editUser = (user: UserModel) => {
    return asyncActionCreator(EDIT_USER, () => {
        const patchOperations = [
                patchProperty('firstName', user.firstName),
                patchProperty('lastName', user.lastName),
                patchProperty('email', user.email),
                patchProperty('carLicensePlate', user.carLicensePlate),
                patchProperty('phone', user.phone),
                patchProperty('street', user.street),
                patchProperty('zip', user.zip),
                patchProperty('city', user.city),
                patchProperty('country', user.country),
                patchProperty('companyId', user.companyId),
                patchProperty('taxId', user.taxId),
                patchProperty('vatId', user.vatId),
                patchProperty('rfid', user.rfid),
                patchProperty('showHiddenLocation', user.showHiddenLocation),
                patchProperty('tenants', user.tenants),
        ]
        const payload = {
            operations: patchOperations.filter((op) => op.value !== undefined),
            tags: user.tags,
        }

        return sendJson(`users/${user.id}`, 'PATCH', payload)
    })
}

export const deleteUser = (user: UserModel) => {
    return asyncActionCreator(DELETE_USER, () => sendJson(`users/${user.id}`, 'DELETE'))
}

export const fetchUsers = (search: string = '', tenantId: string) => {
    let url = `users?tenantId=${tenantId}`;
    if (search) {
        url += `&searchQuery=${search}`
    }
    return asyncActionCreator(FETCH_USERS, () => getJson(url))
}

export const searchUsers = (search: string) => {
    return valueHolderActionCreator(SEARCH_USERS, search)
}

export const fetchSessionsForUser = (user: UserModel, state: any) => {
    return asyncPagedActionCreator(
        FETCH_SESSIONS,
        (pageIndex, pageSize) =>
            getJson(`chargingSession?userId=${user.id}&page=${pageIndex}&pageSize=${pageSize}&sort=startedAt desc`),
        user.id,
        state
    )
}
