import React, { SFC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TagModel, getTagNameByType } from '../../../shared/types/tag'
import { Grid, Paper, IconButton, Tabs, Typography, Tab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { TabPanel } from '../../../shared/components/tabPanel'
import { getMainTenantId } from '../../../config'

const mainTenantId = getMainTenantId()

const useStyles = makeStyles((theme) => ({
    cardWrapper: {
        height: '100%',
        width: `calc(100% - ${theme.spacing(1)})`,
        paddingLeft: theme.spacing(1),
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    cardWrapperInner: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    tabContentWrapper: {
        flexGrow: 1,
        overflowY: 'auto',
        height: '100%',
        padding: theme.spacing(3),
    },
    tabContent: {
        height: '100%',
    },
    card: {
        height: '100%',
    },
    titleWrapper: {
        alignItems: 'center',
        padding: theme.spacing(2, 3),
        flex: '0',
    },
    titleText: {
        textAlign: 'left',
    },
    titleButtons: {
        textAlign: 'right',
    },
    profileItem: {
        textAlign: 'left',
    },
    profileValue: {
        paddingLeft: theme.spacing(1),
        textAlign: 'left',
        position: 'relative',
        width: '80%',
        '& span': {
            position: 'absolute',
            top: theme.spacing(0.3),
        },
    },
    groupDivider: {
        height: theme.spacing(1.5),
    },
    sessionDayTitle: {
        marginLeft: theme.spacing(1),
    },
    sessionDayTitleDivider: {
        marginTop: theme.spacing(3),
    },
    sessionListItem: {
        margin: theme.spacing(1, 0, 1, 0),
        padding: theme.spacing(2),
        display: 'flex',
        width: '50%',
    },
    sessionListContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    sessionListContent: {
        flex: '1 0 auto',
        textAlign: 'left',
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
    },
    sessionImageWrapper: {
        flex: '1 0 auto',
        textAlign: 'right',
        marginLeft: theme.spacing(1),
    },
    sessionImage: {
        width: '80px',
        height: '80px',
    },
    sessionLoadMore: {
        marginBottom: theme.spacing(3),
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
    fullHeight: {
        height: '20%',
    },
}))

interface TagCardProps {
    tag: TagModel
    onDelete: Function
    onEdit: Function
}
const TagCard: SFC<TagCardProps> = ({ tag, onDelete, onEdit }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const tenantId = useSelector((state: any) => state.login.value.user.id)

    const tenants = useSelector((state: any) => state.tenants.value)
    const tenant = tenants?.find((tenant: any) => tenant.id === tag.tenantId);

    const [tabIndex, setTabIndex] = useState<number>(0)
    const tagValues = [
        // ['id', tag.id],
        [t('tags.name'), tag.name],
        [t('tags.type'), getTagNameByType(tag.type)],
        [t('tags.description'), tag.description],
        tenantId && tenantId === mainTenantId ? [t('common.tenant'), tenant?.login ? tenant.login : tag.tenantId] : [],
    ]

    return (
        <div className={classes.cardWrapper}>
            <Paper elevation={1} className={classes.card}>
                <div className={classes.cardWrapperInner}>
                    <Grid item xs={12} container className={classes.titleWrapper}>
                        <Grid item xs={6} className={classes.titleText}>
                            <Typography variant="h2" style={{ padding: 0 }}>
                                {tag.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.titleButtons}>
                            <IconButton onClick={() => onDelete()}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton onClick={() => onEdit()}>
                                <EditIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <div>
                        <Tabs value={tabIndex} onChange={(event: any, newValue: number) => setTabIndex(newValue)}>
                            <Tab label={t('tags.profile')} color="primary" />
                            <Tab label={t('tags.usage')} />
                        </Tabs>
                    </div>
                    <div className={classes.tabContentWrapper}>
                        <TabPanel value={tabIndex} index={0} className={classes.tabContent}>
                            <Grid container className={classes.fullHeight}>
                                {tagValues.map((x: any, index) =>
                                    x.length === 2 ? (
                                        <React.Fragment key={index}>
                                            <Grid item sm={2} className={classes.profileItem}>
                                                <Typography variant="subtitle2">{x[0]}</Typography>
                                            </Grid>
                                            <Grid item sm={10} className={classes.profileValue}>
                                                <Typography variant="caption" component="div">
                                                    {x[1] && x[1] !== '' ? x[1] : '\u00A0'}
                                                </Typography>
                                            </Grid>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment key={index}>
                                            <Grid item sm={12} className={classes.groupDivider} />
                                            {x.length === 1 && (
                                                <Grid item sm={12} className={classes.profileItem}>
                                                    <Typography variant="h6">{x[0]}</Typography>
                                                </Grid>
                                            )}
                                        </React.Fragment>
                                    )
                                )}
                            </Grid>
                        </TabPanel>

                        <TabPanel value={tabIndex} index={1} className={classes.tabContent}>
                            TODO: where is this tag used
                        </TabPanel>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default TagCard
