import { PersistenceState, Image } from './../../shared/types/common'
import { Charger, ChargerModel, ChargerConnector } from './../../shared/types/charger'
import { sendJson, sendData } from './../../shared/utils/api'
import { LocationModel, Location, LocationListItem } from './../../shared/types/location'
import {
    asyncActionCreator,
    asyncPagedActionCreator,
    valueHolderActionCreator,
} from './../../shared/redux/actions/common'
import {
    FETCH_LIST_LOCATIONS,
    FETCH_MAP_LOCATIONS,
    FETCH_LOCATION_FOR_DETAIL,
    SELECTED_LOCATION_ID,
    SEARCH_LOCATIONS,
    ADD_LOCATION,
    EDIT_LOCATION,
    DELETE_LOCATION,
    UPLOAD_LOCATION_PHOTOS,
    DELETE_LOCATION_PHOTO,
    ADD_CHARGER,
    EDIT_CHARGER,
    DELETE_CHARGER,
    RESET_CHARGER,
    UPDATE_CONNECTORS,
    UNLOCK_CONNECTOR,
    UPLOAD_CHARGER_PHOTOS,
    DELETE_CHARGER_PHOTO,
    CHANGE_CONNECTOR_AVAILABILITY,
    UPDATE_CONNECTOR_AVAILABILITY,
    UPLOAD_CHARGER_CSV,
    MAP_TOKEN,
} from '../const'
import { getJson, patchProperty } from '../../shared/utils/api'

export const addLocation = (location: LocationModel) => {
    return asyncActionCreator(ADD_LOCATION, () => sendJson('locations', 'POST', location))
}

export const editLocation = (location: Location) => {
    return asyncActionCreator(EDIT_LOCATION, () => {
        const patchOperations = [
            patchProperty('address', 'defaultAddress'),
            patchProperty('name', location.name),
            patchProperty('description', location.description),
            patchProperty('latitude', location.latitude),
            patchProperty('longitude', location.longitude),
            patchProperty('city', location.city),
            patchProperty('street', location.street),
            patchProperty('postalCode', location.postalCode),
            patchProperty('houseNum', location.houseNum),
            patchProperty('phoneNumber', location.phoneNumber),
            patchProperty('hidden', location.hidden),
            patchProperty('includeInHubject', location.includeInHubject),
            patchProperty('allowAnonymous', location.allowAnonymous),
            patchProperty('tenantId', location.tenantId),
        ]

        const payload = {
            operations: patchOperations.filter((op) => op.value !== undefined),
            tags: location.tags,
        }

        return sendJson(`locations/${location.id}`, 'PATCH', payload)
    })
}

export const deleteLocation = (location: LocationListItem) => {
    return asyncActionCreator(DELETE_LOCATION, () => sendJson(`locations/${location.id}`, 'DELETE'), {
        locationId: location.id,
    })
}

export const fetchListLocations = (key: any, search: string, state: any, tenantId: string) => {
    let url = `locations?tenantId=${tenantId}&showHidden=true`
    if (search) {
        url += `&searchQuery=${search}&`
    }

    return asyncPagedActionCreator(
        FETCH_LIST_LOCATIONS,
        (pageIndex, pageSize) => getJson(url + `&page=${pageIndex}&pageSize=${pageSize}&sort=name asc`),
        key,
        state
    )
}

export const fetchMapLocations = (search: string, tenantId: string) => {
    let url = `locations/all?showHidden=true&tenantId=${tenantId}`
    if (search) {
        url += `&searchQuery=${search}&`
    }
    return asyncActionCreator(FETCH_MAP_LOCATIONS, () => getJson(url))
}

export const fetchLocationForDetail = (locationId: string) => {
    return asyncActionCreator(FETCH_LOCATION_FOR_DETAIL, () => getJson(`locations/${locationId}`))
}

export const setSelectedLocationId = (id: string | undefined) => {
    return valueHolderActionCreator(SELECTED_LOCATION_ID, id)
}

export const searchLocations = (search: string) => {
    return valueHolderActionCreator(SEARCH_LOCATIONS, search)
}

export const uploadPhotosForLocation = (location: LocationListItem, photos: File[]) => {
    const uploadFunction = () =>
        Promise.all(
            photos.map((photo) => {
                return sendData(`locations/image/${location.id}`, 'POST', photo)
            })
        )
    return asyncActionCreator(UPLOAD_LOCATION_PHOTOS, uploadFunction, {
        locationId: location.id,
    })
}

export const deleteLocationPhoto = (location: LocationListItem, photo: Image) => {
    return asyncActionCreator(DELETE_LOCATION_PHOTO, () => sendJson(`image/${photo.id}`, 'DELETE'), {
        locationId: location.id,
        photoId: photo.id,
    })
}

export const addCharger = (charger: ChargerModel) => {
    return asyncActionCreator(ADD_CHARGER, () => sendJson('charger', 'POST', charger), {}, [
        ['0', 'locations.chargerAlreadyExists'],
    ])
}

export const editCharger = (charger: Charger) => {
    return asyncActionCreator(EDIT_CHARGER, () => {
        const patchOperations = [
            patchProperty('locationId', charger.locationId),
            patchProperty('name', charger.name),
            patchProperty('manufacturer', charger.manufacturer),
            patchProperty('brand', charger.brand),
            patchProperty('textInstructionForNavigation', charger.textInstructionForNavigation),
            patchProperty('textInstructionHowToStartCharging', charger.textInstructionHowToStartCharging),
            patchProperty('linkToForum', charger.linkToForum),
            patchProperty('serviceNote', charger.serviceNote),
            patchProperty('externalId', charger.externalId),
            patchProperty('integrations', charger.integrations),
        ]
        const payload = {
            operations: patchOperations.filter((op) => op.value !== undefined),
            tags: charger.tags,
        }

        return sendJson(`charger/${charger.id}`, 'PATCH', payload)
    })
}

export const deleteCharger = (charger: Charger) => {
    return asyncActionCreator(DELETE_CHARGER, () => sendJson(`charger/${charger.id}`, 'DELETE'), {
        chargerId: charger.id,
    })
}

export const updateConnectorsForCharger = (charger: Charger, connectors: ChargerConnector[]) => {
    const adjustBody = {
        connectorsToDelete: new Array<string>(),
        connectorsToUpdate: new Array<any>(),
        connectorsToAdd: new Array<any>(),
    }

    connectors.forEach((x) => {
        if (x.persistenceState === PersistenceState.Added) {
            adjustBody.connectorsToAdd.push(x)
        } else if (x.persistenceState === PersistenceState.Deleted) {
            adjustBody.connectorsToDelete.push(x.id)
        } else if (x.persistenceState === PersistenceState.Updated) {
            adjustBody.connectorsToUpdate.push({
                key: x.id,
                value: [
                    patchProperty('type', x.type),
                    patchProperty('socketType', x.socketType),
                    patchProperty('externalId', x.externalId),
                ],
            })
        }
    })

    return asyncActionCreator(
        UPDATE_CONNECTORS,
        () => sendJson('chargingConnector/adjust', 'POST', adjustBody),
        {
            chargerId: charger.id,
            deletedIds: adjustBody.connectorsToDelete,
        },
        [
            ['2', 'locations.connectorExternalIdsMustBeUnique'],
            ['3', 'locations.externalIdsAreMandatory'],
        ]
    )
}

export const uploadPhotosForCharger = (charger: Charger, photos: File[]) => {
    const uploadFunction = () =>
        Promise.all(
            photos.map((photo) => {
                return sendData(`charger/image/${charger.id}`, 'POST', photo)
            })
        )
    return asyncActionCreator(UPLOAD_CHARGER_PHOTOS, uploadFunction, {
        chargerId: charger.id,
    })
}

export const deleteChargerPhoto = (charger: Charger, photo: Image) => {
    return asyncActionCreator(DELETE_CHARGER_PHOTO, () => sendJson(`image/${photo.id}`, 'DELETE'), {
        chargerId: charger.id,
        photoId: photo.id,
    })
}

export const resetCharger = (charger: Charger, resetType: number) => {
    return asyncActionCreator(RESET_CHARGER, () => sendJson(`Charger/reset/${charger.id}/${resetType}`, 'POST'))
}

export const changeConnectorAvailability = (connectorId: string, availType: number) => {
    return asyncActionCreator(CHANGE_CONNECTOR_AVAILABILITY, () =>
        sendJson(`ChargingConnector/changeAvailability/${connectorId}/${availType}`, 'POST')
    )
}

export const updateConnectorAvailability = (chargerId: string, connectorId: string, availType: number) => {
    return valueHolderActionCreator(UPDATE_CONNECTOR_AVAILABILITY, { chargerId, connectorId, availType })
}

export const unlockConnector = (connectorId: Charger) => {
    return asyncActionCreator(UNLOCK_CONNECTOR, () => sendJson(`ChargingConnector/unlock/${connectorId}`, 'POST'))
}
export const uploadChargerCsv = (chargerId: string, uploadType: number, fileToUpload: File) => {
    const uploadFunction = () => 
        sendData(`charger/sendLocalListCsv/${chargerId}/${uploadType}`, 'POST', fileToUpload); // No need for Promise.all

    return asyncActionCreator(UPLOAD_CHARGER_CSV, uploadFunction, {
        chargerId: chargerId,
    });
};


export const setMapToken = (token: string) => {
    return valueHolderActionCreator(MAP_TOKEN, token)
}
