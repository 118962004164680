import { asyncActionCreator } from '../../shared/redux/actions/common'
import { ADMIN_PROFILE_LOGO_DELETE, FETCH_TENANTS } from '../const'
import { sendJson, patchProperty, sendData, getJson } from '../../shared/utils/api'

export type AdminEditModel = {
    id: string
    aboutPage: string
    login: string
    logo: File
    password: string
    texts: string
    contact: string
}

interface PasswordInfo {
    currentPassword: string;
    newPassword: string;
}

export const updateAdmin = (id: string, admin: AdminEditModel) => {
    return sendJson(`tenant/${id}`, 'PATCH', [
        patchProperty('aboutPage', admin.aboutPage),
        patchProperty('login', admin.login),
        patchProperty('texts', admin.texts),
        patchProperty('contact', admin.contact),
    ])
}

export const updateLogo = (logo: File) => {
    return sendData(`tenant/logo`, 'POST', logo)
}

// need to be id of image
export const deleteLogo = (id: string) => {
    return asyncActionCreator(ADMIN_PROFILE_LOGO_DELETE, () => sendJson(`image/${id}`, 'DELETE'))
}

export const updatePassword = (passwordInfo: PasswordInfo) => {
    return sendJson(`tenant/password`, 'POST', passwordInfo)
}

export const fetchTenants = () => {
    return asyncActionCreator(FETCH_TENANTS, () => getJson('tenant/all'))
}