import React, { useState, useEffect } from 'react'
import { Paper, TableContainer, Table, TableBody, TableRow, TableCell, TablePagination } from '@material-ui/core'
import { tableStyles } from './TableStyles'
import moment from 'moment'
import EnhancedTableHead from './EnhancedTableHead'
import { useSelector, useDispatch } from 'react-redux'
import { fetchLogs } from '../../../redux/actions/logs'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

export default function HistoryTable() {
    const classes = tableStyles()
    const dispatch = useDispatch()

    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(30)

    const logsState = useSelector((state: any) => state.logs.items)
    const logsFilter = useSelector((state: any) => state.logs.filter)

    const rowTypeStyle = (type: string) => {
        switch (type) {
            case 'C_to_B_Request':
                return classes.C_to_B_Request
            case 'B_to_C_Request':
                return classes.B_to_C_Request
            case 'C_to_B_Response':
                return classes.C_to_B_Response
            case 'B_to_C_Response':
                return classes.B_to_C_Response
            default:
                return undefined
        }
    }

    //load logs
    useEffect(() => {
        dispatch(fetchLogs(page + 1, rowsPerPage, logsFilter))
    }, [dispatch, logsFilter, logsFilter.type, rowsPerPage, page])

    //show first page if filter is changed
    useEffect(() => {
        setPage(0)
    }, [logsFilter])

    return (
        <Paper className={classes.paper}>
            <TableContainer style={{ height: '100%', overflowY: 'scroll' }}>
                <Table stickyHeader className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
                    <EnhancedTableHead />
                    <TableBody style={{ width: '100%' }}>
                        {logsState.value?.diagnosticLogs.map((row: any) => {
                            const customKey = `${row.chargerId}${row.type}${row.created}${row.payload}`
                            return (
                                <TableRow key={customKey} className={rowTypeStyle(row.type)}>
                                    <TableCell>{row.chargerId}</TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell>{moment(row.created).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                    <TableCell style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                                        {row.payload}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 30, 50, 100]}
                component={Paper}
                count={logsState.value ? logsState.value.count : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
                    setPage(page)
                }}
                onChangeRowsPerPage={(e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    setRowsPerPage((e.target as any).value)
                }}
                className={classes.pagination}
                ActionsComponent={TablePaginationActions}
            />
        </Paper>
    )
}

interface TablePaginationActionsProps {
    count: number
    page: number
    rowsPerPage: number
    onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const { count, page, rowsPerPage, onChangePage } = props

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, 0)
    }

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1)
    }

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1)
    }

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
        <div
            style={{
                flexShrink: 0,
                marginLeft: '2em',
            }}
        >
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                <FirstPageIcon />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                <LastPageIcon />
            </IconButton>
        </div>
    )
}
