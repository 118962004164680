export type TagModel = {
    id: string;
    description: string;
    type: TagType;
    name: string;
    tenantId: string;
};

export type TagCharger = {
    id?: string;
    tagId: string;
    chargerId?: string;
}

export type TagLocation = {
    id?: string;
    tagId: string;
    locationId?: string;
}

export type TagUser = {
    id?: string;
    tagId: string;
    userId?: string;
}

export enum TagType {
    User=1,
    Charger=2,
    Location=3,
    ChargingProfile=4,
}

export const TagTypes = [
    { code: 1, label: "User" },
    { code: 2, label: "Charger" },
    { code: 3, label: "Location" },
    { code: 4, label: "ChargingProfile" },
];

export const getTagNameByType = function (type: number) {
    return TagTypes.find((tag) => tag.code === type)?.label || "";
};
