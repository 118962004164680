import React, { SFC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Dialog,
    DialogTitle,
    makeStyles,
    DialogContent,
    DialogActions,
    Grid,
    MenuItem,
    Checkbox,
    Select,
    Typography,
    FormControl,
    InputLabel,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import { Location } from '../../../shared/types/location'
import { ValidationErrors } from 'final-form'
import { addLocation, editLocation } from '../../../redux/actions/locations'
import { setRequiredErrors } from '../../../utils/ui'
import { DialogProgress } from '../../../shared/components/dialogProgress'
import { DialogConfirmButton } from '../../../shared/components/dialogConfirmButton'
import { RichEditor } from '../../../core/richEditor/RichEditor'
import { TagLocation, TagModel } from '../../../shared/types/tag'
import { Autocomplete } from '@material-ui/lab'
import { fetchAllLocationTags } from '../../../redux/actions/tags'
import { AdminEditModel, fetchTenants } from '../../../redux/actions/tenants'
import { getMainTenantId } from '../../../config'

const mainTenantId = getMainTenantId()

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiFilledInput-root': {
            borderRadius: theme.spacing(0.5),
        },
        '& .MuiFilledInput-root::before': {
            border: 0,
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& .DraftEditor-editorContainer': {
            maxHeight: 'calc(100vh - 272px)',
        },
    },
    form: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        flex: 1,
    },
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
    },
    fullWidth: {
        width: '100%',
    },
}))

interface LocationAddEditDialogProps {
    open: boolean
    isAdd: boolean
    onClose(location?: Location): void
    initialModel?: Location
}

const LocationAddEditDialog: SFC<LocationAddEditDialogProps> = ({ open, isAdd, onClose, initialModel }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const stateSelector = isAdd ? (state: any) => state.locations.add : (state: any) => state.locations.edit

    const tenantsState = useSelector((state: any) => state.tenants)
    const tenantId = useSelector((state: any) => state.login.value.user.id)
    const [hidden, setHidden] = useState<boolean>(initialModel?.hidden || false)
    const [includeInHubject, setIncludeInHubject] = useState<boolean>(initialModel?.includeInHubject || false)
    const [allowAnonymous, setAllowAnonymous] = useState<boolean>(initialModel?.allowAnonymous || true)
    const [richEditor, setRichEditor] = useState({ description: initialModel?.description })
    const [beforeSubmit, setBeforeSubmit] = useState<boolean>(true)
    const [tenant, setTenant] = useState<string>('')

    const allTags = useSelector((state: any) => state.tags.items.location.value)
    const [filteredTags, setFilteredTags] = useState([])
    const [locationTags, setLocationTags] = useState<TagLocation[]>(initialModel?.tags || [])
    const [locationTagsMapped, setLocationTagsMapped] = useState<TagModel[]>([])

    const onRichEditorChange = (property: string, value: string) => {
        setRichEditor({ ...richEditor, [property]: value })
    }

    const onDialogClose = (location?: Location) => {
        setRichEditor({ description: initialModel?.description })
        onClose(location)
        setBeforeSubmit(true)
    }

    const handleHiddenChange = () => {
        setHidden(!hidden)
        setIncludeInHubject(false);
    }
    const handleHubjectLocationChange = () => {
        setIncludeInHubject(!includeInHubject)
    }
    const handleAllowAnonymous = () => {
        setAllowAnonymous(!allowAnonymous)
    }
    const handleTenantChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTenant(event.target.value as string)
        setLocationTags([])
    }

    const validateDescription = (result: any, description: any) => {
        if (description) {
            try {
                if (JSON.parse(description).blocks[0].text.length === 0) {
                    result.description = t('common.required')
                }
            } catch (e) {
                if (description.length === 0) result.description = t('common.required')
            }
        }
    }

    const validatePhoneNumber = (result: any, phoneNumber: any) => {
        const phoneNumbeRregex = new RegExp('^\\+[0-9]{0,15}$')
        const phoneNumbeRregexSize = new RegExp('^\\+[0-9]{5,15}$')
        const phoneNumbeRregexPlus = new RegExp('^\\+')
        if (!phoneNumbeRregex.test(phoneNumber) && !phoneNumbeRregexSize.test(phoneNumber)) {
            result.phoneNumber = t('common.incorrectTelephoneNumber')
        }
        if (!phoneNumbeRregexSize.test(phoneNumber)) {
            result.phoneNumber = t('common.incorrectTelephoneLength')
        }
        if (!phoneNumbeRregex.test(phoneNumber)) {
            result.phoneNumber = t('common.incorrectTelephoneNumber')
        }
        if (!phoneNumbeRregexPlus.test(phoneNumber)) {
            result.phoneNumber = t('common.plusFirstChar')
        }
    }

    const handleTagChange = (event: any, newValue: TagModel[]) => {
        // Set the new chargerTags state
        setLocationTagsMapped(newValue)

        const newLocationTags: TagLocation[] = newValue.map((tag) => ({
            tagId: tag.id,
            locationId: initialModel?.id,
        }))

        // Set the new chargerTags state
        setLocationTags(newLocationTags)
    }

    // load tariffs on component mount
    useEffect(() => {
        dispatch(fetchAllLocationTags())
        dispatch(fetchTenants())
    }, [dispatch, tenantId])

    useEffect(() => {
        if (initialModel) {
            setTenant(initialModel.tenantId as string)
            setHidden(initialModel.hidden)
            setIncludeInHubject(initialModel.includeInHubject)
            setAllowAnonymous(initialModel.allowAnonymous)
            setLocationTags(initialModel.tags)
            setRichEditor({ description: initialModel?.description })
        }
    }, [open, initialModel])

    useEffect(() => {
        if (locationTags && allTags.length > 0) {
            const filtered = allTags.filter((tag: TagModel) => tag.tenantId === tenant)
            setFilteredTags(filtered)

            // Filter allTags to select only those TagModel objects whose id matches TagCharger.tagId
            const selectedTags = filtered.filter((tag: TagModel) =>
                locationTags.some((tagLocation: TagLocation) => tag.id === tagLocation.tagId)
            )
            setLocationTagsMapped(selectedTags)
        } else {
            setLocationTagsMapped([])
        }
    }, [locationTags, allTags, tenant])

    return (
        <Dialog className={classes.dialog} open={open}>
            <DialogProgress stateSelector={stateSelector} />
            <DialogTitle>{isAdd ? t('locations.newLocation') : t('locations.editLocation')}</DialogTitle>

            <Form
                onSubmit={async (model) => {
                    const newLocationObj = {
                        ...model,
                        description: richEditor.description,
                        hidden: hidden,
                        includeInHubject: includeInHubject,
                        allowAnonymous: allowAnonymous,
                        tags: locationTags,
                        tenantId: tenant,
                        totalPower: '1',
                    }
                    const result: any = await dispatch(
                        isAdd ? addLocation(newLocationObj) : editLocation(newLocationObj)
                    )
                    if (result) {
                        onDialogClose(result)
                    }
                }}
                initialValues={initialModel}
                validate={(model: Location): ValidationErrors => {
                    model = {
                        ...model,
                        description: richEditor.description,
                        hidden: hidden,
                        includeInHubject: includeInHubject,
                        allowAnonymous: allowAnonymous,
                        tags: locationTags,
                        tenantId: tenant,
                    }
                    const result: any = {}
                    validatePhoneNumber(result, model.phoneNumber)
                    validateDescription(result, model.description)
                    setRequiredErrors(
                        result,
                        model,
                        [
                            'phoneNumber',
                            'name',
                            'description',
                            'address',
                            'latitude',
                            'longitude',
                            'city',
                            'street',
                            'postalCode',
                            'houseNum',
                        ],
                        t('common.required')
                    )
                    return result
                }}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                        <DialogContent>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="name"
                                        fullWidth
                                        required
                                        multiline
                                        inputProps={{
                                            maxLength: 256,
                                            rows: 1,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('common.name')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="city"
                                        fullWidth
                                        required
                                        rows={1}
                                        multiline
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('common.city')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="street"
                                        fullWidth
                                        required
                                        rows={1}
                                        multiline
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('common.street')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="houseNum"
                                        fullWidth
                                        required
                                        rows={1}
                                        multiline
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('common.houseNum')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="postalCode"
                                        fullWidth
                                        required
                                        rows={1}
                                        multiline
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('common.postalCode')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="phoneNumber"
                                        fullWidth
                                        required
                                        rows={1}
                                        multiline
                                        inputProps={{
                                            maxLength: 16,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('common.phoneNumber')}
                                    />
                                </Grid>
                                <Grid hidden={!(tenantId && tenantId === mainTenantId)} item xs={12}>
                                    <FormControl required variant="filled" fullWidth>
                                        <InputLabel >{t('common.tenant')}</InputLabel>
                                        <Select value={tenant} onChange={handleTenantChange}>
                                            {tenantsState.value
                                                ?.filter((item: AdminEditModel) => item.id !== mainTenantId)
                                                .map((item: AdminEditModel) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.login}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        options={filteredTags}
                                        getOptionLabel={(tag: TagModel) => tag.name}
                                        value={locationTagsMapped}
                                        onChange={handleTagChange}
                                        renderInput={(params) => (
                                            <TextField name="tags" {...params} variant="filled" label={t('tags.title')} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption" style={{ fontSize: '18px', color: '#000000' }}>
                                        <Checkbox
                                            name="allowAnonymous"
                                            checked={allowAnonymous}
                                            onChange={handleAllowAnonymous}
                                            style={{
                                                color: '#6100ED',
                                                marginBottom: '4px',
                                                paddingLeft: '0px',
                                                paddingBottom: '0px',
                                                paddingTop: '0px',
                                                paddingRight: '8px',
                                            }}
                                        />
                                        {t('locations.allowAnonymous')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="caption" style={{ fontSize: '18px', color: '#000000' }}>
                                        <Checkbox
                                            name="showHiddenLocation"
                                            checked={hidden}
                                            onChange={handleHiddenChange}
                                            style={{
                                                color: '#6100ED',
                                                marginBottom: '4px',
                                                paddingLeft: '0px',
                                                paddingBottom: '0px',
                                                paddingTop: '0px',
                                                paddingRight: '8px',
                                            }}
                                        />
                                        {t('locations.hiddenLocation')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography hidden={hidden} variant="caption" style={{ fontSize: '18px', color: '#000000' }}>
                                        <Checkbox
                                            name="includeInHubject"
                                            checked={includeInHubject}
                                            onChange={handleHubjectLocationChange}
                                            style={{
                                                color: '#6100ED',
                                                marginBottom: '4px',
                                                paddingLeft: '0px',
                                                paddingBottom: '0px',
                                                paddingTop: '0px',
                                                paddingRight: '8px',
                                            }}
                                        />
                                        {t('locations.includeInLocations')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="latitude"
                                        fullWidth
                                        required
                                        inputProps={{
                                            min: -180,
                                            max: 180,
                                            step: 0.000001,
                                        }}
                                        type="number"
                                        variant="filled"
                                        label={t('common.latitude')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="longitude"
                                        fullWidth
                                        required
                                        inputProps={{
                                            min: -180,
                                            max: 180,
                                            step: 0.000001,
                                        }}
                                        type="number"
                                        variant="filled"
                                        label={t('common.longitude')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <RichEditor
                                        required
                                        label={t('common.description')}
                                        property="description"
                                        initialValue={richEditor.description}
                                        onChange={onRichEditorChange}
                                        beforeSubmit={beforeSubmit}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={() => {
                                    onDialogClose()
                                }}
                                color="primary"
                            >
                                {t('common.cancel')}
                            </Button>
                            <DialogConfirmButton
                                label={isAdd ? t('common.create') : t('common.change')}
                                stateSelector={stateSelector}
                                onClick={() => {
                                    if (beforeSubmit) {
                                        setBeforeSubmit(false)
                                    }
                                }}
                            />
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    )
}

export default LocationAddEditDialog
