import React, { SFC, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles((theme) => ({
    search: {
        display: 'flex',
        border: '1px solid #EBEBEB',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#F5F5F5',
        alignItems: 'flex-end',
        color: '#BCBCCB',
        '& ::placeholder': {
            color: '#A7A7A7',
        },
        textAlign: 'center',
        width: '100%'
    },
    searchIcon: {
        width: theme.spacing(5),
        pointerEvents: 'none',
    },
    inputRoot: {
        flex: '1 0 auto',
    },
    inputInput: {
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}))

const debouncedAction = _.debounce((action: any) => {
    action()
}, 250)

interface SearchBarProps {
    placeholder: string
    onChange: Function
    value?: string
}
const SearchBar: SFC<SearchBarProps> = ({ placeholder, onChange, value }) => {
    const classes = useStyles()
    const [currentValue, setCurrentValue] = useState(value ?? '')

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                value={currentValue}
                onChange={(x) => {
                    const newValue = x.target.value
                    setCurrentValue(newValue)
                    debouncedAction(() => onChange(newValue))
                }}
                placeholder={placeholder}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
            />
        </div>
    )
}

export default SearchBar
