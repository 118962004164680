import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { makeStyles, Grid, TextField, Snackbar, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { AsyncButton } from '../../shared/components/asyncButton'
import { sendJson } from '../../shared/utils/api'
import { setToken } from '../../config'
import { login } from '../../shared/redux/actions/common'
import AuthorizationError from '../../shared/utils/authorizationError'
import { usePromise } from '../../shared/utils/usePromise'
import { AppVersion } from './../../shared/components/AppVersion'

const useStyles = makeStyles((theme) => ({
    wrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    wrapperInner: {
        width: '400px',
        padding: theme.spacing(4),
    },
    logo: {
        paddingBottom: theme.spacing(3),
        textAlign: 'center',
    },
    title: {
        paddingBottom: theme.spacing(3),
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    login: {
        paddingBottom: theme.spacing(4),
    },
    password: {
        paddingBottom: theme.spacing(6),
    },
    input: {
        paddingLeft: theme.spacing(1),
    },
}))

const Login = () => {
    const { t } = useTranslation()
    const classes = useStyles()

    const dispatch = useDispatch()

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>()

    const [loginStart, inProgress, result, error] = usePromise(() =>
        sendJson('authorization/admin', 'POST', null, {
            Authorization: 'Basic ' + btoa(unescape(encodeURIComponent(`${username}:${password}`))),
        })
    )

    useEffect(() => {
        if (error instanceof AuthorizationError) {
            setErrorMessage(t('login.invalidCredentials'))
        } else if (error) {
            setErrorMessage(t('login.error', { error }))
        }
    }, [t, error])

    useEffect(() => {
        if (result) {
            setToken(result.token)
            dispatch(login())
        }
    }, [result, dispatch, username])

    return (
        <div className={classes.wrapper}>
            <form noValidate>
                <Grid container alignItems="flex-start" className={classes.wrapperInner} spacing={0}>
                    <Grid item xs={12} className={classes.logo}>
                        <img src={require('../../shared/static/loginLogo.png')} alt="" />
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h3">{t('login.title')}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.login}>
                        <TextField
                            value={username}
                            onChange={(event: any) => setUsername(event.target.value)}
                            fullWidth
                            required
                            inputProps={{
                                maxLength: 256,
                                className: classes.input,
                            }}
                            type="text"
                            label={t('login.username')}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.password}>
                        <TextField
                            value={password}
                            onChange={(event: any) => setPassword(event.target.value)}
                            fullWidth
                            required
                            inputProps={{
                                maxLength: 256,
                                className: classes.input,
                            }}
                            type="password"
                            label={t('login.password')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AsyncButton
                            buttonProps={{
                                fullWidth: true,
                                variant: 'contained',
                                color: 'primary',
                                type: 'submit',
                                onClick: (event: any) => {
                                    event.preventDefault()
                                    if (username && password) {
                                        loginStart()
                                    } else {
                                        setErrorMessage(t('login.missingCredentials'))
                                    }
                                },
                            }}
                            inProgress={inProgress}
                        >
                            {t('login.signIn')}
                        </AsyncButton>
                    </Grid>
                </Grid>
            </form>
            {errorMessage && (
                <Snackbar
                    open={Boolean(errorMessage)}
                    autoHideDuration={5000}
                    onClose={() => setErrorMessage(undefined)}
                >
                    <Alert onClose={() => setErrorMessage(undefined)} severity="error">
                        {errorMessage}
                    </Alert>
                </Snackbar>
            )}

            <AppVersion />
        </div>
    )
}

export default Login
