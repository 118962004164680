import Cookies from 'js-cookie'
import packageJson from '../package.json'

const localhost = false
const version = '2.7.2'
const proxy = null
const clientUrl = 'https://charge.sk'
const apiUrl = 'https://api.charge.sk/api/'

export const config =
    localhost    
        ? {
            api: '/api/',
            client: 'localhost:3000',
            version: 'dev',
            proxy: packageJson.proxy,
        }
        : {
            api: apiUrl,
            version: version,
            client: clientUrl,
            proxy: proxy
        }

    /*process.env.NODE_ENV === 'development'
        ? {
            api: '/api/',
            client: 'localhost:3000',
            version: 'dev',
            proxy: packageJson.proxy,
        }
        : {
            api: (window as any)._env_.API_URL,
            client: (window as any)._env_.CLIENT_URL,
            version: (window as any)._env_.VERSION_NUMBER,
            proxy: null
        }*/

        

export const getToken = () => {
    return Cookies.get('adminToken')
}

export const setToken = (token: string) => {
    Cookies.set('adminToken', token)
}

export const removeToken = () => {
    Cookies.remove('adminToken')
}
export const getChargeTenantId = () => {
    return '9b671b08-c0a7-4ac9-82db-7275e7d6893c'
}
export const getMainTenantId = () => {
    // TenantLogin.Admin
    // DEV
    // return '811eb710-9278-411d-bc7f-6af4babdbd1a'
    // PROD
    return 'c7636d00-c0f9-4908-9699-ee67c28733c6'
    // APP
    // return '811eb710-9278-411d-bc7f-6af4babdbd1a'
}