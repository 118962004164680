import React, { SFC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Card, CardContent, CardActions, IconButton, Typography, Link, Menu, MenuItem, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import EditIcon from '@material-ui/icons/Edit'
// import DeleteIcon from '@material-ui/icons/Delete'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { useHistory } from "react-router-dom";
import {
    Charger,
    ChargerConnector,
    getLabelForChargerConnectorType,
    getLabelForChargerConnectorSocketType,
} from '../../../shared/types/charger'
import ConnectorsManagementDialog from '../connectorsManagementDialog/ConnectorsManagementDialog'
import { useTranslation } from 'react-i18next'
import ImagesManager from '../../../shared/components/imagesManager'
import { uploadPhotosForCharger, deleteChargerPhoto } from '../../../redux/actions/locations'
import { promiseError } from '../../../shared/redux/actions/common'
import { ReadOnlyEditor } from '../../../shared/components/ReadOnlyEditor'
import { filterByChargerId } from '../../../redux/actions/logs'

const useStyles = makeStyles((theme) => ({
    listItem: {
        marginBottom: theme.spacing(1),
    },
    cardContent: {
        textAlign: 'left',
        '&:last-child': {
            paddingBottom: theme.spacing(2),
        },
    },
    cardTitleWrapper: {
        display: 'flex',
    },
    cardTitleInfo: {
        flex: '1 0 auto',
        maxWidth: '87%',
    },
    cardActions: {
        padding: '0px',
    },
    connectorsLink: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: 'left',
    },
    chargerImage: {
        width: '100%',
        height: theme.spacing(10),
    },
    chargerField: {
        textAlign: 'left',
        marginTop: theme.spacing(2),
    },
    detailLabel: {
        textAlign: 'left',
        display: 'flex',
    },
    expandButtonWrapper: {
        width: '100%',
        textAlign: 'center',
    },
    expansionPanel: {
        boxShadow: 'none',
        width: '100%',
    },
    expansionPanelSummary: {
        padding: 0,
        minHeight: 0,
        '& .MuiExpansionPanelSummary-content': {
            margin: 0,
        },
        '& .MuiExpansionPanelSummary-expandIcon': {
            padding: 0,
        },
    },
    expansionPanelDetails: {
        padding: 0,
    },
}))

interface ChargerListProps {
    chargers: Charger[]
    onDelete(charger: Charger): void
    onEdit(charger: Charger): void
    onSettings(charger: Charger): void
    onQRCode(charger: Charger): void
}
const ChargerList: SFC<ChargerListProps> = ({ chargers, onDelete, onEdit, onSettings, onQRCode }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const history = useHistory();

    const connectorToString = (connector: ChargerConnector) => {
        const type = getLabelForChargerConnectorType(connector.type, t)
        const socketType = getLabelForChargerConnectorSocketType(connector.socketType, t)

        return `${socketType} ${type}`
    }

    const [chargerToManageConnectorsFor, setChargerToManageConnectorsFor] = useState<Charger>()
    const [expandedChargerId, setExpandedChargerId] = useState<string>()

    const onConnectorsManagementDialogClose = (connectors?: ChargerConnector[]) => {
        setChargerToManageConnectorsFor(undefined)
    }

    const getChargerAdditionalFields = (charger: Charger) => {
        return [
            [t('locations.navigationInstructions'), charger.textInstructionForNavigation],
            [t('locations.chargingInstructions'), charger.textInstructionHowToStartCharging],
            [t('locations.serviceNote'), charger.serviceNote],
        ]
    }

    const handleSettingsOnClick = (charger: Charger) => {
        onSettings(charger)
    }

    const handleEditOnClick = (charger: Charger) => {
        onEdit(charger)
    }

    const handleDeleteOnClick = (charger: Charger) => {
        onDelete(charger)
    }

    const handleQRCodeOnClick = (charger: Charger) => {
        onQRCode(charger)
    }

    const redirectToLogs = (chargerName: any) => {
        dispatch(filterByChargerId(chargerName));
        history.push('/logs');
    }

    return (
        <>
            {chargers
                .sort((x, y) => x.name.localeCompare(y.name))
                .map((charger) => (
                    <Card key={charger.id} className={classes.listItem} variant="outlined">
                        <CardContent className={classes.cardContent}>
                            <Grid container>
                                <Grid item xs={12} className={classes.cardTitleWrapper}>
                                    <div className={classes.cardTitleInfo}>
                                        <Typography variant="h4">{charger.name}</Typography>
                                        <Typography variant="caption" component="div">
                                            {charger.brand
                                                ? `${charger.manufacturer} (${charger.brand})`
                                                : charger.manufacturer}
                                        </Typography>
                                    </div>
                                    <CardActions className={classes.cardActions}>
                                        <RenderChargerMenu
                                            charger={charger}
                                            onSettingsClick={handleSettingsOnClick}
                                            onEditClick={handleEditOnClick}
                                            onDeleteClick={handleDeleteOnClick}
                                            onQRCodeClick={handleQRCodeOnClick}
                                        />
                                    </CardActions>
                                </Grid>
                            </Grid>
                            <Link
                                component="button"
                                className={classes.connectorsLink}
                                variant="caption"
                                onClick={() => setChargerToManageConnectorsFor(charger)}
                            >
                                {!Boolean(charger.connectors) || charger.connectors.length === 0
                                    ? t('locations.noConnectors')
                                    : charger.connectors
                                        .sort((x, y) => x.externalId - y.externalId)
                                        .map(connectorToString)
                                        .join(', ')}
                            </Link>
                            
                            <ImagesManager
                                images={charger.images}
                                imageClassName={classes.chargerImage}
                                imageCols={4}
                                uploadAction={async (files) => {
                                    await dispatch(uploadPhotosForCharger(charger, files))
                                }}
                                uploadError={() => dispatch(promiseError(t('common.uploadError')))}
                                uploadProgressStateSelector={(state: any) => state.locations.chargers.uploadPhotos}
                                deleteAction={async (image) => await dispatch(deleteChargerPhoto(charger, image))}
                                deleteProgressStateSelector={(state: any) => state.locations.chargers.deletePhoto}
                            />
                            {/* <Link
                                component="button"
                                className={classes.connectorsLink}
                                variant="caption"
                                onClick={() => redirectToLogs(charger.externalId)}
                            >{t('common.showLogs')}
                            </Link> */}
                            {expandedChargerId === charger.id ? (
                                <>
                                    {getChargerAdditionalFields(charger).map(
                                        (field, index) =>
                                            field[1] && (
                                                <Grid
                                                    key={index}
                                                    item
                                                    container
                                                    xs={12}
                                                    className={classes.chargerField}
                                                >
                                                    <ExpansionPanel className={classes.expansionPanel}>
                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelSummary}>
                                                            <Grid item xs={12} className={classes.detailLabel}>
                                                                <Typography variant="subtitle2">{field[0]}</Typography>
                                                            </Grid>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                                                            <Typography align='justify' variant='caption' noWrap={false}>
                                                                <ReadOnlyEditor value={field[1]} />
                                                            </Typography>
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </Grid>
                                            )
                                    )}
                                    <div className={classes.expandButtonWrapper}>
                                        <IconButton onClick={() => setExpandedChargerId(undefined)}>
                                            <ExpandLessIcon />
                                        </IconButton>
                                    </div>
                                </>
                            ) : (
                                    (charger.textInstructionForNavigation ||
                                        charger.textInstructionHowToStartCharging ||
                                        charger.serviceNote) && (
                                        <div className={classes.expandButtonWrapper}>
                                            <IconButton onClick={() => setExpandedChargerId(charger.id)}>
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </div>
                                    )
                                )}
                        </CardContent>
                    </Card>
                ))}
            {chargerToManageConnectorsFor && (
                <ConnectorsManagementDialog
                    open={true}
                    charger={chargerToManageConnectorsFor}
                    onClose={onConnectorsManagementDialogClose}
                />
            )}
        </>
    )
}

export default ChargerList

const RenderChargerMenu = (props: any) => {
    const { charger, onSettingsClick, onEditClick, onDeleteClick, onQRCodeClick } = props
    const [anchorEl, setAnchorEl] = useState(null)
    const { t } = useTranslation()

    const handleOpenChargerMenuClick = (ev: any) => {
        setAnchorEl(ev.currentTarget)
    }
    const handleCloseChargerMenuClick = () => {
        setAnchorEl(null)
    }

    const handleSettingsOnClick = () => {
        onSettingsClick(charger)
        handleCloseChargerMenuClick()
    }

    const handleEditOnClick = () => {
        onEditClick(charger)
        handleCloseChargerMenuClick()
    }

    const handleDeleteOnClick = () => {
        onDeleteClick(charger)
        handleCloseChargerMenuClick()
    }

    const handleQRCodeOnClick = () => {
        onQRCodeClick(charger)
        handleCloseChargerMenuClick()
    }

    return (
        <>
            <IconButton
                aria-label="more"
                aria-haspopup="true"
                key={`"chargerOptionsButton-${charger.id}`}
                id={`"chargerOptionsButton-${charger.id}`}
                onClick={handleOpenChargerMenuClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id={`"chargerOptionsMenu-${charger.id}`}
                key={`"chargerOptionsMenu-${charger.id}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseChargerMenuClick}
            >
                <MenuItem onClick={handleSettingsOnClick}>{t('common.settings')}</MenuItem>
                <MenuItem onClick={handleEditOnClick}>{t('common.edit')}</MenuItem>
                <MenuItem onClick={handleQRCodeOnClick}>{t('locations.qrcode')}</MenuItem>
                <MenuItem onClick={handleDeleteOnClick}>{t('common.delete')}</MenuItem>
            </Menu>
        </>
    )
}
