// eslint-disable-file react-hooks/exhaustive-deps //
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Dialog,
    DialogTitle,
    Divider,
    makeStyles,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import {
    Charger,
    ChargerConnector,
    getLabelForChargerConnectorSocketType,
    getLabelForChargerConnectorType,
} from '../../../shared/types/charger'
import QRCode from 'qrcode.react'
import { config } from '../../../config'

const useStyles = makeStyles((theme) => ({
    dialog: {

        '& .MuiGrid-container': {
            padding: theme.spacing(2, 1),
        },
        '& .MuiGrid-item': {
            display: 'flex',
            justifyContent: 'center'
        },
        '& .MuiDialog-paper': {
            maxWidth: '720px !important',
            minWidth: '500px',
        },
        '& .MuiButton-containedSecondary:hover': {
            color: '#ffffff',
        },
    },
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
    },
    dividerMarginBottom: {
        marginBottom: theme.spacing(2),
    },
    connectorButton: {
        padding: theme.spacing(0.5)
    },
    dialogContent: {
        height: '350px',
        width: '350px'
    },
    canvas: {
        transform: 'scale(0.25)',
        position: 'absolute',
        left: '-100%',
        top: '-100%'

    }
}))

interface ChargerQRCodeDialogProps {
    open: boolean
    onClose(): void
    initialModel: Charger
}

interface QRCodeDialogProps {
    open: boolean
    onClose(): void
    url: string
}

const QRCodeDialog: FC<QRCodeDialogProps> = ({ open, onClose, url }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <Dialog open={open}>
            {url !== null && (
                <>
                    <DialogContent className={classes.dialogContent}>
                        <QRCode className={classes.canvas} id='canvas' value={url} size={1200} />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => onClose()}
                            color="primary"
                        >
                            {t('common.close')}
                        </Button>
                    </DialogActions>
                </>
            )
            }
        </Dialog>
    )
}


const ChargerQRCodeDialog: FC<ChargerQRCodeDialogProps> = ({ open, onClose, initialModel }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const chargerConnectors = initialModel.connectors

    const [QRCodeOpen, setQRCodeOpen] = useState<any>({ open: false, url: null })

    const connectorToString = (connector: ChargerConnector) => {
        const type = getLabelForChargerConnectorType(connector.type, t)
        const socketType = getLabelForChargerConnectorSocketType(connector.socketType, t)
        return `${socketType} ${type}`
    }

    const generateQRCode = (connectorExternalId?: any) => {
        const url = connectorExternalId
            ? `${config.client}/#!/charging/${initialModel.locationId}?charger=${initialModel.externalId}&connector=${connectorExternalId}`
            : `${config.client}/#!/charging/${initialModel.locationId}?charger=${initialModel.externalId}`
        setQRCodeOpen({ open: true, url: url })
    }

    const onQRCodeDialogClose = () => {
        setQRCodeOpen({ open: false, url: null })
    }

    return (
        <>
            <Dialog className={classes.dialog} open={open}>
                <DialogTitle>{t('locations.qrcode')}</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">{t('locations.qrcodeCharger')}</Typography>
                    <Divider className={classes.dividerMarginBottom} />
                    <Grid container>
                        <Grid item xs={12}>
                            <Button
                                color="secondary"
                                size="small"
                                variant="contained"
                                onClick={() => generateQRCode()}
                            >
                                {t('common.generate')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle1">{t('locations.qrcodeConnectors')}</Typography>
                    <Divider className={classes.dividerMarginBottom} />
                    <Grid container>
                        <Grid item xs={12}>
                            <Table size="small">
                                <TableBody>
                                    {chargerConnectors && chargerConnectors.length ? (
                                        chargerConnectors.map((connector: ChargerConnector) => (
                                            <TableRow key={connector.id}>
                                                <TableCell>
                                                    <span>ID: {connector.externalId}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{connectorToString(connector)}</span>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div className={classes.connectorButton}>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            color="secondary"
                                                            onClick={() => generateQRCode(connector.externalId)}
                                                            key={connector.externalId}
                                                        >
                                                            {t('common.generate')}
                                                        </Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                            <TableRow>
                                                <TableCell align="center">
                                                    <Typography>{t('locations.noConnectorsToList')}</Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        variant="contained"
                        onClick={() => onClose()}
                        color="primary"
                    >
                        {t('common.close')}
                    </Button>
                </DialogActions>
            </Dialog>
            <QRCodeDialog open={QRCodeOpen.open} onClose={() => { onQRCodeDialogClose() }} url={QRCodeOpen.url} />
        </>
    )
}

export default ChargerQRCodeDialog
