import React, { FC, useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    IconButton,
} from '@material-ui/core'
import { Select as MuiSelect, TextField } from 'mui-rff'
import { useDispatch, useSelector } from 'react-redux'
import { DialogProgress } from '../../../shared/components/dialogProgress'
import { Form } from 'react-final-form'
import { ValidationErrors } from 'final-form'
import { setRequiredErrors } from '../../../utils/ui'
import { TariffAddEditDialogProps } from '../TariffsTypes'
import { tariffDialogStyles } from '../TariffsStyles'
import { useTranslation } from 'react-i18next'
import { DialogConfirmButton } from '../../../shared/components/dialogConfirmButton'
import { addTariff, editTariff } from '../../../redux/actions/tariffs'
import { Day, TariffModel, TariffRowModel } from '../../../shared/types/tariff'
import DeleteIcon from '@material-ui/icons/Delete'
import { TimePicker } from '@material-ui/pickers'
import moment from 'moment'
import { AdminEditModel, fetchTenants } from '../../../redux/actions/tenants'
import { getMainTenantId } from '../../../config'

const defaultTariffRow: TariffRowModel = {
    dayFrom: Day.Monday,
    from: moment('00:00', moment.HTML5_FMT.TIME),
    dayTo: Day.Sunday,
    to: moment('23:59', moment.HTML5_FMT.TIME),
}

const mainTenantId = getMainTenantId()

const TariffAddEditDialog: FC<TariffAddEditDialogProps> = ({ isAdd, initialDialogModel, onClose }) => {
    const { t } = useTranslation()
    const classes = tariffDialogStyles()
    const dispatch = useDispatch()
    const stateSelector = isAdd ? (state: any) => state.tariffs.add : (state: any) => state.tariffs.edit
    const tenantId = useSelector((state: any) => state.login.value.user.id)
    const tenantsState = useSelector((state: any) => state.tenants)

    const [tariffRows, setTariffRows] = useState<any>(
        isAdd
            ? [defaultTariffRow]
            : initialDialogModel.rows[0] === null
            ? []
            : initialDialogModel.rows.map((row: TariffRowModel) => ({
                  ...row,
                  from: moment(row.from, moment.HTML5_FMT.TIME),
                  to: moment(row.to, moment.HTML5_FMT.TIME),
              }))
    )

    const onTariffAdd = () => {
        setTariffRows([...tariffRows, defaultTariffRow])
    }
    const onRowDelete = (rowIndex: number) => {
        if (tariffRows.length > 1) {
            setTariffRows(tariffRows.filter((row: TariffRowModel, i: number) => i !== rowIndex))
        }
    }
    const onTimeChange = (value: any, timeType: string, rowIndex: number) => {
        setTariffRows(
            tariffRows.map((row: TariffRowModel, i: number) =>
                i === rowIndex ? { ...row, [timeType]: !value ? null : value } : row
            )
        )
    }
    const onDayChange = (value: any, dayType: string, rowIndex: number) => {
        setTariffRows(
            tariffRows.map((row: TariffRowModel, i: number) => (i === rowIndex ? { ...row, [dayType]: value } : row))
        )
    }

    useEffect(() => {
        dispatch(fetchTenants())
    }, [dispatch, tenantId])

    const weekdays = [
        t('common.monday'),
        t('common.tuesday'),
        t('common.wednesday'),
        t('common.thursday'),
        t('common.friday'),
        t('common.saturday'),
        t('common.sunday'),
    ]

    return (
        <>
            <Dialog maxWidth="sm" fullWidth className={classes.dialog} open={true}>
                <DialogProgress stateSelector={stateSelector} />
                <DialogTitle>{isAdd ? t('tariffs.newTariff') : t('tariffs.editTariff')}</DialogTitle>
                <Form
                    onSubmit={async (model) => {
                        model = {
                            ...model,
                            active: true,
                            type: 0,
                            rows: tariffRows.map((row: TariffRowModel) => ({
                                ...row,
                                from: row.from.format('HH:mm'),
                                to: row.to.format('HH:mm'),
                            })),
                        }
                        const result: any = await dispatch(
                            isAdd ? addTariff(model as TariffModel) : editTariff(model as TariffModel)
                        )

                        if (result) {
                            onClose(result)
                        }
                    }}
                    initialValues={initialDialogModel}
                    validate={(model: any): ValidationErrors => {
                        const result: any = {}
                        let requiredFields = tenantId && tenantId === mainTenantId ? ['name', 'tenantId'] : ['name']
                        setRequiredErrors(result, model, requiredFields, t('common.required'))
                        return result
                    }}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item sm={12}>
                                        <TextField
                                            name="name"
                                            fullWidth
                                            required
                                            inputProps={{
                                                maxLength: 250,
                                                autoComplete: 'off',
                                            }}
                                            type="text"
                                            variant="filled"
                                            label={t('common.name')}
                                        />
                                    </Grid>
                                    <Grid hidden={!(tenantId && tenantId === mainTenantId)} item sm={12}>
                                        <MuiSelect
                                            name="tenantId"
                                            variant="filled"
                                            label={t('common.tenant')}
                                            fullWidth
                                            required
                                        >
                                            {tenantsState.value
                                                ?.filter((item: AdminEditModel) => item.id !== mainTenantId)
                                                .map((item: AdminEditModel) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.login}
                                                    </MenuItem>
                                                ))}
                                        </MuiSelect>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <TextField
                                            name="note"
                                            fullWidth
                                            multiline
                                            rows={6}
                                            inputProps={{
                                                maxLength: 1000,
                                            }}
                                            type="text"
                                            variant="filled"
                                            label={t('common.note')}
                                        />
                                    </Grid>
                                </Grid>

                                {tariffRows.map((tariffRow: TariffRowModel, i: any) => (
                                    <Grid container justify="flex-start" alignItems="flex-start" key={i} spacing={2}>
                                        <Grid item sm={3}>
                                            <FormControl variant="standard" className={classes.fullWidth}>
                                                <InputLabel id={`dayFromlabel`}>{t('common.weekday')}</InputLabel>
                                                <Select
                                                    labelId={`dayFromlabel`}
                                                    fullWidth
                                                    value={tariffRow.dayFrom}
                                                    onChange={(ev) => {
                                                        onDayChange(ev.target.value, 'dayFrom', i)
                                                    }}
                                                >
                                                    {weekdays.map((item: string, i) => (
                                                        <MenuItem key={i+1} value={i+1}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <FormControl variant="standard" className={classes.fullWidth}>
                                                <TimePicker
                                                    ampm={false}
                                                    label="From"
                                                    value={tariffRow.from || null}
                                                    onChange={(value) => onTimeChange(value, 'from', i)}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item sm={3}>
                                            <FormControl variant="standard" className={classes.fullWidth}>
                                                <InputLabel id={`dayTolabel`}>{t('common.weekday')}</InputLabel>
                                                <Select
                                                    labelId={`dayTolabel`}
                                                    fullWidth
                                                    value={tariffRow.dayTo}
                                                    onChange={(ev) => {
                                                        onDayChange(ev.target.value, 'dayTo', i)
                                                    }}
                                                >
                                                    {weekdays.map((item, i) => (
                                                        <MenuItem key={i+1} value={i+1}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <TimePicker
                                                ampm={false}
                                                label="To"
                                                value={tariffRow.to || null}
                                                onChange={(value) => onTimeChange(value, 'to', i)}
                                            />
                                        </Grid>
                                        {i !== 0 && (
                                            <Grid
                                                item
                                                container
                                                justify="flex-end"
                                                sm={2}
                                                className={classes.deleteCell}
                                            >
                                                <IconButton onClick={() => onRowDelete(i)}>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                ))}

                                <Grid container justify="center" spacing={0} className={classes.dialogButton}>
                                    <Button variant="contained" color="secondary" onClick={() => onTariffAdd()}>
                                        {t('tariffs.newTimerangeForTariff')}
                                    </Button>
                                </Grid>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={() => {
                                        onClose()
                                    }}
                                    color="primary"
                                >
                                    {t('common.cancel')}
                                </Button>

                                <DialogConfirmButton
                                    label={isAdd ? t('common.create') : t('common.change')}
                                    stateSelector={stateSelector}
                                />
                            </DialogActions>
                        </form>
                    )}
                />
            </Dialog>
        </>
    )
}
export default React.memo(TariffAddEditDialog)
