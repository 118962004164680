import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Button, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { hubjectStyles } from './HubjectStyles'
import { ConfirmationDialog } from '../../shared/components/confirmationDialog'
import { config, getMainTenantId, getToken } from '../../config'
import ReactJson from 'react-json-view'
import { useSelector } from 'react-redux'

export default function Hubject() {
    const classes = hubjectStyles()
    const { t } = useTranslation()
    
    const tenantId = useSelector((state: any) => state.login.value.user.id)

    const [isPerformFullloadConfirmationOpened, setIsPerformFullloadConfirmationOpened] = useState(false)
    const [isPerformDeleteConfirmationOpened, setIsPerformDeleteConfirmationOpened] = useState(false)

    const [isPerformFullloadInProgress, setIsPerformFullloadInProgress] = useState(false)
    const [isPerformDeleteInProgress, setIsPerformDeleteInProgress] = useState(false)

    const [fullloadData, setFullloadData] = useState<any>({ result: 'Fullload not executed' })
    const [deleteData, setDeleteData] = useState<any>({ result: 'Delete not executed' })

    const performFullloadController = new AbortController()
    const performDeleteController = new AbortController()

    const handlePerformFullload = () => {
        if (isPerformFullloadInProgress) {
            return
        }
        setIsPerformFullloadInProgress(true)

        fetch(`${config.api}_OICP/eRoamingPushEvseDataFullload`, {
            signal: performFullloadController.signal,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw res.statusText
                }
            })
            .then((data: any) => {
                setFullloadData(data)
            })
            .catch((error) => {
                setFullloadData({ error })
            })
            .finally(() => {
                setIsPerformFullloadInProgress(false)
                setIsPerformFullloadConfirmationOpened(false)
            })
    }

    const handlePerformDelete = () => {
        if (isPerformDeleteInProgress) {
            return
        }
        setIsPerformDeleteInProgress(true)

        fetch(`${config.api}_OICP/eRoamingPushEvseDataDeleteAll`, {
            signal: performFullloadController.signal,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw res.statusText
                }
            })
            .then((data: any) => {
                setDeleteData(data)
            })
            .catch((error) => {
                setDeleteData({ error })
            })
            .finally(() => {
                setIsPerformDeleteInProgress(false)
                setIsPerformDeleteConfirmationOpened(false)
            })
    }

    return (
        <>
            <ConfirmationDialog
                open={isPerformFullloadConfirmationOpened}
                onConfirm={handlePerformFullload}
                onCancel={() => {
                    if (isPerformFullloadInProgress) {
                        performFullloadController.abort()
                        setIsPerformFullloadInProgress(false)
                    }
                    setIsPerformFullloadConfirmationOpened(false)
                }}
                title={t('hubject.areyousure')}
                confirmLabel={t('hubject.yes')}
                inProgress={isPerformFullloadInProgress}
                content={t('hubject.confirmPerformFullload')}
                cancelLabel={t('hubject.no')}
            />
            <ConfirmationDialog
                open={isPerformDeleteConfirmationOpened}
                onConfirm={handlePerformDelete}
                onCancel={() => {
                    if (isPerformDeleteInProgress) {
                        performDeleteController.abort()
                        setIsPerformDeleteInProgress(false)
                    }
                    setIsPerformDeleteConfirmationOpened(false)
                }}
                title={t('hubject.areyousure')}
                confirmLabel={t('hubject.yes')}
                inProgress={isPerformDeleteInProgress}
                content="Are you sure you want to Delete all Hubject EVSE records?"
                cancelLabel={t('hubject.no')}
            />
            <Grid spacing={2} direction="column" justify="flex-start" className={classes.content}>
                <Grid item xs={12} className={classes.headerWrapper}>
                    <Typography align="left" variant="h3">
                        {t('hubject.title')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.fullloadButton}
                        onClick={() => {
                            setIsPerformFullloadConfirmationOpened(true)
                        }}
                    >
                        {t('hubject.performFullload')}
                    </Button>
                    {tenantId && tenantId == getMainTenantId() && (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.fullloadButton}
                            onClick={() => {
                                setIsPerformDeleteConfirmationOpened(true)
                            }}
                        >
                            {t('hubject.deleteAllRecords')}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {fullloadData && (
                        <ReactJson
                            src={fullloadData}
                            name={false}
                            iconStyle="square"
                            enableClipboard={false}
                            displayObjectSize={true}
                            displayDataTypes={false}
                        />
                    )}
                     {deleteData && tenantId && tenantId == getMainTenantId() && (
                        <ReactJson
                            src={deleteData}
                            name={false}
                            iconStyle="square"
                            enableClipboard={false}
                            displayObjectSize={true}
                            displayDataTypes={false}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}
