import React, { useState } from 'react'
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    makeStyles,
    Theme,
    TypographyProps,
} from '@material-ui/core'

const useStyles = makeStyles<Theme, TypographyProps>((theme: Theme) => ({
    root: {
        padding: 0,
        minHeight: 'auto !important',
    },
    content: {
        overflow: 'hidden',
        margin: '0 !important',
    },
    typographyWrapper: (props) => ({
        textAlign: props.align ?? 'inherit',
    }),
    notExpanded: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hideBorder: {
        '&.MuiExpansionPanel-root:before': {
            display: 'none',
        },
    },
}))

interface ExpandableTextProps {
    typographyProps?: Partial<TypographyProps>
}
export const ExpandableText: React.FC<ExpandableTextProps> = ({ typographyProps, children }) => {
    const classes = useStyles(typographyProps ?? {})
    const [expanded, setExpanded] = useState(false)

    return (
        <ExpansionPanel
            className={classes.hideBorder}
            onChange={(event: any, expanded: boolean) => setExpanded(expanded)}
            elevation={0}
        >
            <ExpansionPanelSummary classes={{ content: classes.content, root: classes.root }}>
                <div className={`${classes.typographyWrapper} ${expanded === false ? classes.notExpanded : ''}`}>
                    <Typography {...typographyProps} noWrap={false}>
                        {children}
                    </Typography>
                </div>
            </ExpansionPanelSummary>
        </ExpansionPanel>
    )
}
