import React from 'react'
import { AsyncActionConfirmationDialog } from './asyncActionConfirmationDialog'

type ConfirmationDialogProps = {
    open: boolean
    onConfirm: Function
    onCancel: Function
    title: string
    content: string
    confirmLabel: string
    cancelLabel: string
    inProgress: boolean
}

export function ConfirmationDialog(props: ConfirmationDialogProps) {
    return <AsyncActionConfirmationDialog {...props} />
}
