import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import {
    Grid,
    Paper,
    TableContainer,
    Table,
    CircularProgress,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { fetchReports } from '../../redux/actions/reports'
import { useSelector, useDispatch } from 'react-redux'

const reportStyles = makeStyles((theme: any) => ({
    headerWrapper: {
        padding: theme.spacing(1),
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    table: {
        overflowY: 'hidden',
        overflowX: 'hidden',
    },
    row: {
        padding: 0,
    },
    circularProgressWrapper: {
        padding: theme.spacing(6),
    },
}))

export default function Reports() {
    const classes = reportStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const reportsState = useSelector((state: any) => state.reports)
    const tenantId = useSelector((state: any) => state.login.value.user.id)

    useEffect(() => {
        dispatch(fetchReports(tenantId))
    }, [dispatch, tenantId])

    return (
        <>
            <Grid container>
                <Grid item xs={12} className={classes.headerWrapper}>
                    <Typography align="left" variant="h3">
                        {t('reports.title')}
                    </Typography>
                </Grid>
                <Grid item sm={12}>
                    <Paper className={classes.paper}>
                        <TableContainer>
                            <Table
                                stickyHeader
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'small'}
                                aria-label="enhanced table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('reports.name')}</TableCell>
                                        <TableCell>{t('reports.url')}</TableCell>
                                        <TableCell>{t('reports.description')}</TableCell>
                                    </TableRow>
                                </TableHead>

                                {!reportsState.value ? (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                colSpan={7}
                                                align="center"
                                                className={classes.circularProgressWrapper}
                                            >
                                                <CircularProgress size={40} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {reportsState.value.map((report: any, index: number) => {
                                            let color = 'white'
                                            return (
                                                <TableRow key={index} style={{ backgroundColor: color }}>
                                                    <TableCell align="left">
                                                        {report.name ?? t('common.none')}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {report.url ? (
                                                            <a
                                                                href={report.url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {report.url}
                                                            </a>
                                                        ) : (
                                                            t('common.none')
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {report.description ?? t('common.none')}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
