import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HashRouter as Router, Switch, Route, Redirect, RouteProps } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from './core/appBar/AppBar'
import Sidebar from './core/sidebar/Sidebar'
import Locations from './pages/locations/Locations'
import Users from './pages/users/Users'
import Tags from './pages/tags/Tags'
import Tariffs from './pages/tariffs/Tariffs'
import History from './pages/history/History'
import Reports from './pages/reports/Reports'
import Admin from './pages/adminSettings/AdminSettings'
import { Grid } from '@material-ui/core'
import { getJson } from './shared/utils/api'
import { getToken } from './config'
import { setUser, setPaymentSettings } from './shared/redux/actions/common'
import LoginPage from './core/login/login'
import usePromiseResult from './shared/utils/usePromiseResult'
import { setMapToken } from './redux/actions/locations'
import Logs from './pages/logs/Logs'
import Hubject from './pages/hubject/Hubject'
import PricingConditions from './pages/pricingConditions/PricingConditions'

const useStyles = makeStyles((theme) => ({
    appBarWrapper: {
        paddingBottom: theme.spacing(1),
    },
    sideBarWrapper: {
        paddingTop: theme.spacing(1),
    },
    contentWrapper: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
    },
}))

export default function AppRouter() {
    const classes = useStyles()

    const routes: Array<RouteProps> = [
        { path: '/', component: Locations, exact: true },
        { path: '/users', component: Users, exact: true },
        { path: '/tags', component: Tags, exact: true },
        { path: '/pricingConditions', component: PricingConditions, exact: true },
        { path: '/tariffs', component: Tariffs, exact: true },
        { path: '/history', component: History, exact: true },
        { path: '/reports', component: Reports, exact: true },
        { path: '/hubject', component: Hubject, exact: true },
        { path: '/settings', component: Admin, exact: true },
        { path: '/logs', component: Logs, exact: true },
    ]

    const dispatch = useDispatch()

    const loginState = useSelector((state: any) => state.login.value)
    const isLoggedIn: boolean = usePromiseResult(
        'router',
        (inputState: any) => {
            return new Promise((resolve) => {
                if (getToken()) {
                    if (inputState.isLoggedIn && inputState.user) {
                        resolve(true)
                    } else {
                        Promise.all([
                            getJson('tenant/me'),
                            getJson('settings/mapToken'),
                            getJson('settings/paymentSettings'),
                        ])
                            .then(([user, mapSettings, paymentSettings]) => {
                                dispatch(setPaymentSettings(paymentSettings))
                                dispatch(setUser(user))
                                dispatch(setMapToken(mapSettings.token))
                                resolve(true)
                            })
                            .catch(() => {
                                resolve(false)
                            })
                    }
                } else {
                    resolve(false)
                }
            })
        },
        [loginState]
    )

    return isLoggedIn ? (
        <Router hashType="hashbang">
            <Grid container spacing={0}>
                <Grid item xs={12} className={classes.appBarWrapper}>
                    <AppBar />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={2} className={classes.sideBarWrapper}>
                    <Sidebar routes={routes} />
                </Grid>
                <Grid item xs={8} className={classes.contentWrapper}>
                    <Switch>
                        {routes.map((route, index) => (
                            <Route key={index} {...route} />
                        ))}
                        <Redirect from="*" to="/" />
                    </Switch>
                </Grid>
                <Grid item xs={1} />
            </Grid>
        </Router>
    ) : (
        <LoginPage />
    )
}
