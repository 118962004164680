import React, { SFC, useState, useEffect } from 'react'
import { CircularProgress, TextField as MaterialTextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { Autocomplete } from 'mui-rff'
import { promiseError } from '../redux/actions/common'
import { usePromise } from '../utils/usePromise'
import { FormApi } from 'final-form'

export type Option = {
    id: any
    label: string | undefined
}
interface AsyncAutoCompleteFormItemProps {
    form: FormApi<any>
    label: string
    field: string
    dataFetcher: Function
    optionMapper(input: any): Option
    initialOptions: Option[]
}
const AsyncAutoCompleteFormItem: SFC<AsyncAutoCompleteFormItemProps> = ({
    form,
    label,
    field,
    dataFetcher,
    optionMapper,
    initialOptions,
}) => {
    const dispatch = useDispatch()

    const [open, setOpen] = useState<boolean>(false)
    const [fieldToUse, setFieldToUse] = useState<string>('')
    const [initialOptionsToUse, setInitialOptionsToUse] = useState<Option[]>()

    const [fetchData, inProgress, data, error] = usePromise(dataFetcher)

    useEffect(() => {
        if (open && data === undefined) {
            fetchData()
        }
    }, [open, data, fetchData])

    useEffect(() => {
        if (data === undefined && initialOptions.length > 0) {
            fetchData()
        } else {
            setFieldToUse(field)
        }
    }, [data, initialOptions, fetchData, field])

    useEffect(() => {
        if (error) {
            dispatch(promiseError(error))
        }
    }, [error, dispatch])

    useEffect(() => {
        if (data !== undefined && !initialOptionsToUse) {
            const optionsData = data.map(optionMapper)
            const initialOptionsWithLabels = initialOptions.map((x) => ({
                id: x.id,
                label: optionsData.find((y: Option) => y.id === x.id)?.label,
            }))
            form.change(field, initialOptionsWithLabels)
            setInitialOptionsToUse(initialOptionsWithLabels)
            setFieldToUse(field)
        }
    }, [data, initialOptionsToUse, initialOptions, optionMapper, form, field])

    return (
        <Autocomplete
            name={fieldToUse}
            label=""
            multiple
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.label}
            options={data ? data.map(optionMapper) : []}
            loading={inProgress}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            renderInput={(params) => (
                <MaterialTextField
                    {...params}
                    fullWidth
                    label={label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {inProgress && <CircularProgress color="inherit" size={20} />}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}

export default AsyncAutoCompleteFormItem
