import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardContent, CardActions, Button, Typography } from '@material-ui/core'
import { Marker, OverlayView } from '@react-google-maps/api'
import { Clusterer } from '@react-google-maps/marker-clusterer'
import { LocationModel, LocationInfo } from '../../shared/types/location'
import { DialogConfirmButton } from '../../shared/components/dialogConfirmButton'
const green = require(`../../shared/static/locationGreen.svg`)
const orange = require(`../../shared/static/locationOrange.svg`)
const red = require(`../../shared/static/locationRed.svg`)
const blue = require(`../../shared/static/locationBlue.svg`)

export type AddMarkerProps = {
    lat: number
    lng: number
    onAddEnd(model?: LocationModel): void
}
export const AddMarker = ({ lat, lng, onAddEnd }: AddMarkerProps) => {
    const { t } = useTranslation()
    const stateSelector = (state: any) => state.locations.add

    return (
        <>
            <Marker icon={blue} position={{ lat: lat, lng: lng }} />
            <OverlayView
                position={{ lat: lat, lng: lng }}
                mapPaneName={OverlayView.FLOAT_PANE}
                getPixelPositionOffset={() => ({ x: -100, y: 0 })}
            >
                <Card style={{ zIndex: 100, width: '200px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', paddingBottom: 0 }}>
                        <Typography variant="caption">
                            {t('locations.latitudeValue', { value: lat.toFixed(8) })}
                        </Typography>
                        <Typography variant="caption">
                            {t('locations.longitudeValue', { value: lng.toFixed(8) })}
                        </Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>
                        <Button size="small" onClick={() => onAddEnd()} color="primary">
                            {t('common.cancel')}
                        </Button>
                        <DialogConfirmButton
                            label={t('common.create')}
                            stateSelector={stateSelector}
                            onClick={() =>
                                onAddEnd({
                                    latitude: Number(lat.toFixed(8)),
                                    longitude: Number(lng.toFixed(8)),
                                    name: undefined,
                                    description: undefined,
                                    address: "defaultAddress",
                                    city: undefined,
                                    totalPower: undefined,
                                    street: undefined,
                                    postalCode: undefined,
                                    houseNum: undefined,
                                    phoneNumber: undefined,
                                    hidden: false,
                                    tenantId: undefined,
                                    includeInHubject: false,
                                    allowAnonymous: true,
                                    tags: [],
                                })
                            }
                        />
                    </CardActions>
                </Card>
            </OverlayView>
        </>
    )
}

export type LocationMarkerProps = {
    location: LocationInfo
    onClick: Function
    clusterer: Clusterer
}

export const LocationMarker = ({ location, onClick, clusterer }: LocationMarkerProps) => {
    let icon: any
    if (location.workingConnectors === 0 || location.connectedChargers.length === 0) {
        icon = red
    } else if (location.availableConnectors === 0) {
        icon = orange
    } else if (location.availableConnectors > 0) {
        icon = green
    }

    return (
        <Marker
            icon={icon}
            position={{ lat: location.latitude, lng: location.longitude }}
            onClick={() => onClick()}
            clusterer={clusterer}
        />
    )
}
