import React from 'react'
import { useSelector } from 'react-redux'
import { TableHead, TableSortLabel, TableRow, TableCell } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Data, EnhancedTableProps } from '../HistoryTypes'
import { tableStyles } from './TableStyles'
import { getMainTenantId } from '../../../config'

export default function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props
    const classes = tableStyles()
    const { t } = useTranslation()
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
    }
    const currency = useSelector((state: any) => state.paymentSettings.value.currency)
    const tenantId = useSelector((state: any) => state.login.value.user.id)

    interface HeadCell {
        disablePadding: boolean
        id: keyof Data
        label: string
        numeric: boolean
    }

    const headCells: HeadCell[] = [
        { id: 'location', numeric: false, disablePadding: true, label: t('history.location') },
        { id: 'charger', numeric: false, disablePadding: true, label: t('history.charger') },
        { id: 'connector', numeric: false, disablePadding: true, label: t('history.connector') },
        { id: 'startedAt', numeric: false, disablePadding: true, label: t('history.startDate') },
        { id: 'consumedWh', numeric: true, disablePadding: false, label: t('history.energyCharged') },
        { id: 'length', numeric: true, disablePadding: false, label: t('history.duration') },
        { id: 'price', numeric: true, disablePadding: false, label: t('history.price', { currency: currency }) },
        { id: 'pricingCondition', numeric: false, disablePadding: true, label: t('history.pricingCondition') },
        { id: 'pricelist', numeric: false, disablePadding: true, label: t('history.pricelist') },
        { id: 'user', numeric: false, disablePadding: true, label: t('history.email') },
        { id: 'userType', numeric: false, disablePadding: true, label: t('history.userType') },
        { id: 'idTag', numeric: false, disablePadding: true, label: t('history.authorizationRfid') },
        { id: 'car', numeric: false, disablePadding: true, label: t('history.carLicensePlate') },
        { id: 'initSource', numeric: false, disablePadding: true, label: t('history.initSource') },
        { id: 'export_invoice', numeric: false, disablePadding: true, label: t('history.exportInvoice') },
        ...(tenantId && tenantId === getMainTenantId()
        ? [{ id: 'send_invoice', numeric: false, disablePadding: true, label: 'Send invoice' }] as HeadCell[]
        : []),
    ]

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'export_invoice' === headCell.id ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id && (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            )}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
