import {
    asyncActionCreator,
} from './../../shared/redux/actions/common'
import { FETCH_LOCATION_WHITELIST, UPDATE_LOCATION_WHITELIST } from '../const'
import { getJson, sendJson } from '../../shared/utils/api'
import { } from '../const/index'

export const fetchLocationWhitelist = (locationId: string) => {
    return asyncActionCreator(FETCH_LOCATION_WHITELIST, () => getJson(`locations/whitelist/${locationId}`))
}

export const updateLocationWhitelist = (locationId: string, users: string[]) => {
    return asyncActionCreator(UPDATE_LOCATION_WHITELIST, () =>
        sendJson(`locations/whitelist/${locationId}`, 'POST', users)
    )
}
