import { UPDATE_LOCATION_WHITELIST } from './../const/index'

export const locationWhitelistReducer = () => (state: any, action: any) => {
    switch (action.type) {
        case `${UPDATE_LOCATION_WHITELIST}_SUCCESS`: {
            return {
                ...state,
                value: [...state.value, action.payload.result],
            }
        }
        default: {
            return state
        }
    }
}
