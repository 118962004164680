import { sendJson } from './../../shared/utils/api'
import { TagModel } from './../../shared/types/tag'
import { asyncActionCreator, valueHolderActionCreator } from './../../shared/redux/actions/common'
import { FETCH_TAGS, SEARCH_TAGS, ADD_TAG, EDIT_TAG, DELETE_TAG, FETCH_TAGS_CHARGER, FETCH_TAGS_LOCATION, FETCH_TAGS_USER, FETCH_TAGS_CHARGER_ALL, FETCH_TAGS_LOCATION_ALL, FETCH_TAGS_USER_ALL } from '../const'
import { getJson } from '../../shared/utils/api'

const changeTagErrorCodeMapping = [
    ['2', 'tags.emailAlreadyUsed'],
    ['3', 'tags.tagAddedToTenant'],
]

export const addTag = (tag: TagModel) => {
    return asyncActionCreator(ADD_TAG, () => sendJson('tag', 'POST', tag), {}, changeTagErrorCodeMapping)
}

export const editTag = (tag: TagModel) => {
    return asyncActionCreator(EDIT_TAG, () => sendJson(`tag/${tag.id}`, 'PATCH', tag), {}, changeTagErrorCodeMapping)
}

export const deleteTag = (tag: TagModel) => {
    return asyncActionCreator(DELETE_TAG, () => sendJson(`tag/${tag.id}`, 'DELETE'))
}

export const fetchTags = () => {
    return asyncActionCreator(FETCH_TAGS, () => getJson('tag'))
}

export const fetchLocationTags = (id: string) => {
    return asyncActionCreator(FETCH_TAGS_LOCATION, () => getJson(`tag/location/${id}`))
}
export const fetchUserTags = (id: string) => {
    return asyncActionCreator(FETCH_TAGS_USER, () => getJson(`tag/user/${id}`))
}
export const fetchChargerTags = (id: string) => {
    return asyncActionCreator(FETCH_TAGS_CHARGER, () => getJson(`tag/charger/${id}`))
}

export const fetchAllChargerTags = () => {
    return asyncActionCreator(FETCH_TAGS_CHARGER_ALL, () => getJson(`tag/charger`))
}
export const fetchAllLocationTags = () => {
    return asyncActionCreator(FETCH_TAGS_LOCATION_ALL, () => getJson('tag/location'))
}
export const fetchAllUserTags = () => {
    return asyncActionCreator(FETCH_TAGS_USER_ALL, () => getJson('tag/user'))
}
export const searchTags = (search: string) => {
    return valueHolderActionCreator(SEARCH_TAGS, search)
}
