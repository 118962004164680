import { makeStyles } from '@material-ui/core'

export const RichEditorStyles = makeStyles((theme) => ({
    richEditorRoot: {
        fontSize: 'inherit',
        fontFamily: theme.typography.fontFamily,
        height: '100%',
        color: 'rgba(0, 0, 0, 0.87)',
        '& .RichEditor-controls': {
            marginTop: theme.spacing(0.5),
            userSelect: 'none',
            textAlign: 'center',
        },
        '& .RichEditor-styleButton': {
            color: 'rgba(0, 0, 0, 0.54)',
            cursor: 'pointer',
            padding: theme.spacing(0, 0.5),
            display: 'inline-block',
        },
        '& .MuiSvgIcon-root': {
            '& path': {
                height: '14px'
            },
        },
        '& .RichEditor-textButton': {
            fontFamily: theme.typography.fontFamily,
            fontWeight: '500',
            fontSize: '15px',
        },
        '& .RichEditor-activeButton': {
            color: theme.palette.primary.main
        },
        '& .RichEditor-editor': {
            padding: '27px 12px 10px',
            backgroundColor: theme.palette.secondary.main,
            cursor: 'text',
            minHeight: '100px',
            borderRadius: theme.spacing(0.5),
            alignItems: 'flex-start',
            overflowY: 'auto',
        },
        '& .public-DraftEditorPlaceholder-root': {
            whiteSpace: 'pre-wrap',
            position: 'absolute',
            color: 'rgba(0, 0, 0, 0.54)',
            zIndex: 0,
        },
        '& .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root': {
            display: 'none',
        },
        '& .public-DraftEditorPlaceholder-hasFocus': {
            color: '#bdc1c9',
        },
        '& .RichEditor-editor .RichEditor-blockquote': {
            borderLeft: `5px solid ${theme.palette.primary.contrastText}`,
            fontFamily: '"Hoefler Text", "Georgia", serif',
            fontStyle: 'italic',
            margin: theme.spacing(2, 0),
            padding: theme.spacing(1, 2),
        },
        '& .MuiFormHelperText-root': {
            color: '#f44336',
            margin: 0,
            fontSize: '0.75rem',
            marginTop: '3px',
            textAlign: 'left',
            fontWeight: '400',
            lineHeight: 1.66,
        },
        '& .MuiFormHelperText-contained': {
            marginLeft: '14px',
            marginRight: '14px',
        },
        '& .DraftEditor-root': {
            height: '100%',
        },
        '& .DraftEditor-editorContainer': {
            overflowY: 'auto',
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
            height: '100%',
        },
        '& .DraftEditor-editorContainer::-webkit-scrollbar': {
            display: 'none'
        }
    }
}))