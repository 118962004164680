import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers, deleteUser, editUser } from '../../redux/actions/users'
import { Grid, makeStyles, Typography, Button } from '@material-ui/core'
import { UserModel } from '../../shared/types/user'
import { useTranslation } from 'react-i18next'
import UserList from './userList/UserList'
import UserCard from './userCard/UserCard'
import { useCalcHeightToBottom } from '../../utils/ui'
import UserAddEditDialog from './userAddEditDialog/UserAddEditDialog'
import { DeleteConfirmationDialog } from '../../shared/components/deleteConfirmationDialog'
import { getMainTenantId } from '../../config'

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        padding: theme.spacing(1),
    },
    actionButtonWrapper: {
        padding: theme.spacing(1, 0),
        textAlign: 'right',
    },
    listWrapper: {
        paddingTop: theme.spacing(2),
    },
    cardWrapper: {
        paddingTop: theme.spacing(2),
        width: '100%',
    },
}))

export default function Users() {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [selectedUser, setSelectedUser] = useState<UserModel>()

    const [addEditDialogState, setAddEditDialogState] = useState({
        open: false,
        isAdd: true,
        onClose: (user: UserModel) => {},
        initialModel: undefined as UserModel | undefined,
    })
    const [addedUser, setAddedUser] = useState<UserModel>()
    const [editedUser, setEditedUser] = useState<UserModel>()
    const [userToDelete, setUserToDelete] = useState<UserModel>()

    const users = useSelector((state: any) => state.users.items.value)
    const search = useSelector((state: any) => state.users.search.value)
    const deletedUser = useSelector((state: any) => state.users.delete.value)
    const userDeleteExecuted = useSelector((state: any) => state.users.delete.executed)
    const tenantId = useSelector((state: any) => state.login.value.user.id)
    const mainTenantId = getMainTenantId();

    const userCardHeightFill = useCalcHeightToBottom('userCardWrapper')

    const openAddDialog = () => {
        setAddEditDialogState({
            open: true,
            isAdd: true,
            onClose: onAddDialogClose,
            initialModel: undefined,
        })
    }

    const onAddDialogClose = (user?: UserModel) => {
        setAddEditDialogState({ ...addEditDialogState, open: false })
        if (user) {
            setAddedUser(user)
        }
    }

    const onEditDialogClose = (user?: UserModel) => {
        setAddEditDialogState({ ...addEditDialogState, open: false })
        if (user) {
            setEditedUser(user)
        }
    }

    const onUserSelected = (user: UserModel) => {
        setSelectedUser(user)
    }

    const onUserDelete = (user: UserModel) => {
        setUserToDelete(user)
    }

    const onUserEdit = (user: UserModel) => {
        setAddEditDialogState({
            open: true,
            isAdd: false,
            onClose: onEditDialogClose,
            initialModel: user,
        })
    }
    const handleRemoveUser = async () => {
        const userToDeleteDefined = userToDelete as UserModel
        if (tenantId && tenantId === mainTenantId) {
            await dispatch(deleteUser(userToDeleteDefined))
        } else {
            const userTenantArr = userToDeleteDefined.tenants.split(',');
            let filteredTenants: string[] = [];
            userTenantArr.forEach(element => {
                if (element !== tenantId) {
                    filteredTenants.push(element)
                }
            });
            const newTenants = filteredTenants.join(', ');
            userToDeleteDefined.tenants = newTenants;
            
            await dispatch(editUser(userToDeleteDefined))
            dispatch(fetchUsers(search, tenantId))
        }
        setUserToDelete(undefined)
    }

    useEffect(() => {
        dispatch(fetchUsers(search, tenantId))
    }, [dispatch, search, addedUser, editedUser, deletedUser, tenantId])

    useEffect(() => {
        if (users.length > 0) {
            if (selectedUser != null) {
                const userToSelect = users.find((x: any) => x.id === selectedUser.id)
                if (userToSelect != null) {
                    setSelectedUser(userToSelect)
                } else {
                    setSelectedUser(users[0])
                }
            } else {
                setSelectedUser(users[0])
            }
        } else {
            setSelectedUser(undefined)
        }
    }, [users, selectedUser])

    return (
        <>
            <Grid container spacing={0}>
                <Grid item sm={2} className={classes.headerWrapper}>
                    <Typography align="left" variant="h3">
                        {t('users.title')}
                    </Typography>
                </Grid>
                <Grid item sm={10} className={classes.actionButtonWrapper}>
                    <Button
                        onClick={() => {
                            openAddDialog()
                        }}
                        variant="contained"
                        color="primary"
                    >
                        {t('users.newUser')}
                    </Button>
                    {addEditDialogState.open && <UserAddEditDialog {...addEditDialogState} />}
                </Grid>
                <Grid item sm={4} className={classes.listWrapper}>
                    <UserList selectedUser={selectedUser} users={users} onUserSelected={onUserSelected} />
                </Grid>
                <Grid item sm={8} className={classes.cardWrapper}>
                    <div id="userCardWrapper" ref={userCardHeightFill}>
                        {selectedUser && (
                            <UserCard
                                user={selectedUser}
                                onDelete={() => onUserDelete(selectedUser)}
                                onEdit={() => onUserEdit(selectedUser)}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            <DeleteConfirmationDialog
                open={Boolean(userToDelete)}
                onConfirm={async () => {
                    handleRemoveUser()
                }}
                onCancel={() => setUserToDelete(undefined)}
                inProgress={!userDeleteExecuted}
            />
        </>
    )
}