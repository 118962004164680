export type Coordinates = {
  lat: number;
  lng: number;
  accuracy?: number;
};

export type Image = {
  id: string;
  url: string;
};

export enum PersistenceState {
  Original,
  Updated,
  Added,
  Deleted,
}
