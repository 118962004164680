/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Button,
    IconButton,
    Switch,
    FormControl,
    FormControlLabel,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { tariffsStyles } from './TariffsStyles'
import TariffAddEditDialog from './tariffsAddEditDialog/TariffAddEditDialog'
import { TariffAddEditDialogProps } from './TariffsTypes'
import { TariffModel } from '../../shared/types/tariff'
import { useSelector, useDispatch } from 'react-redux'
import { toggleActiveTariff, fetchTariffs, fetchTariffsPricingConditions } from '../../redux/actions/tariffs'
import { NoData } from '../../shared/components/NoData'
import { ConfirmationDialog } from '../../shared/components/confirmationDialog'
import { useCalcHeightToBottom } from '../../utils/ui'
import { fetchTenants } from '../../redux/actions/tenants'
import { getMainTenantId } from '../../config'

const Tariffs = () => {
    const classes = tariffsStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [tariffsInitialized, setTariffsInitialized] = useState<boolean>(false)
    const tariffs = useSelector((state: any) => state.tariffs.items.value)
    const tariffsState = useSelector((state: any) => state.tariffs.items)
    const tenantId = useSelector((state: any) => state.login.value.user.id)

    const tenants = useSelector((state: any) => state.tenants.value)

    const toggleActiveTariffState = useSelector((state: any) => state.tariffs.toggleActive)
    const [tariffToToggleActive, setTariffToToggleActive] = useState<any>(undefined)
    const [toggleActiveDialogOpen, setToggleActiveDialogOpen] = useState<boolean>(false)

    const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false)
    const assignedLocations = useSelector((state: any) => state.tariffs.assignedLocations.value)

    const [tariffAddEditDialogState, setTariffAddEditDialogState] = useState<TariffAddEditDialogProps>({
        open: false,
        isAdd: true,
        onClose: () => {},
        initialDialogModel: {} as TariffModel,
    })

    const onAddPricelistClick = () => {
        setTariffAddEditDialogState({
            open: true,
            isAdd: true,
            initialDialogModel: undefined,
            onClose: onTariffAddDialogClose,
        })
    }

    const onTariffAddDialogClose = (tariff?: TariffModel) => {
        setTariffAddEditDialogState({ ...tariffAddEditDialogState, open: false })
    }

    const handleDeactivatePricelistClick = (tariff: TariffModel) => {
        setTariffToToggleActive(tariff)
        setToggleActiveDialogOpen(true)
    }

    const onEditPricelistClick = (tariff: TariffModel) => {
        setTariffAddEditDialogState({
            open: true,
            isAdd: false,
            initialDialogModel: tariff,
            onClose: onPricelistEditDialogClose,
        })
    }

    const onPricelistEditDialogClose = (tariff?: TariffModel) => {
        setTariffAddEditDialogState({ ...tariffAddEditDialogState, open: false })
    }

    const setTenant = (tariff: TariffModel) => {
        const tenant = tenants?.find((tenant: any) => tenant.id === tariff.tenantId)
        return tenant?.login ? tenant.login : tariff.tenantId
    }

    const listHeightFill = useCalcHeightToBottom('listWrapper')

    useEffect(() => {
        if (!tariffsInitialized) {
            dispatch(fetchTariffs(tenantId))
        }
    }, [])

    useEffect(() => {
        if (tariffsState.executed && tariffsState.value) {
            setTariffsInitialized(true)
        }
    }, [tariffsState])

    useEffect(() => {
        dispatch(fetchTenants())
    }, [dispatch, tenantId])

    return (
        <>
            <Grid container spacing={0}>
                <Grid item sm={2} className={classes.headerWrapper}>
                    <Typography align="left" variant="h3">
                        {t('tariffs.title')}
                    </Typography>
                </Grid>
                <Grid item sm={10} className={classes.actionButtonWrapper}>
                    <Button
                        onClick={() => {
                            onAddPricelistClick()
                        }}
                        variant="contained"
                        color="primary"
                    >
                        {t('tariffs.newTariff')}
                    </Button>
                    {tariffAddEditDialogState.open && <TariffAddEditDialog {...tariffAddEditDialogState} />}
                </Grid>
            </Grid>

            <Grid container className={classes.list} id="listWrapper" ref={listHeightFill}>
                {!tariffsInitialized ? (
                    <div className={classes.contentsProgress}>
                        <CircularProgress />
                    </div>
                ) : tariffs && tariffs.length ? (
                    tariffs
                        .sort((x: any, y: any) => x.name - y.name)
                        .map((tariff: TariffModel) => (
                            <Card key={tariff.id} className={classes.listItem}>
                                <CardContent>
                                    <Grid container alignItems="flex-start">
                                        <Grid item sm={8}>
                                            <Typography variant="h6">{tariff.name}</Typography>
                                            {tenantId && tenantId && tenantId === getMainTenantId() && (
                                                <Typography variant="body2">{setTenant(tariff)}</Typography>
                                            )}
                                            <Typography variant="body2">{tariff.note}</Typography>
                                        </Grid>
                                        <Grid item container sm={4} justify="flex-end" alignItems="center">
                                            <FormControl className={classes.inputSwitch}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            color="primary"
                                                            key={tariff.id}
                                                            checked={tariff.active}
                                                            onChange={() => handleDeactivatePricelistClick(tariff)}
                                                            name="pricelistActive"
                                                        />
                                                    }
                                                    label="Active"
                                                    labelPlacement="start"
                                                />
                                            </FormControl>
                                            <IconButton onClick={() => onEditPricelistClick(tariff)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))
                ) : (
                    <Grid item container justify="center">
                        <NoData text={t('tariffs.noTariffs')} />
                    </Grid>
                )}
            </Grid>

            <ConfirmationDialog
                open={toggleActiveDialogOpen}
                onConfirm={async () => {
                    const locations = await dispatch(fetchTariffsPricingConditions(tariffToToggleActive.id))
                    if (locations.length === 0) {
                        await dispatch(toggleActiveTariff(tariffToToggleActive.id, tariffToToggleActive.active))
                        setTariffToToggleActive(undefined)
                        setToggleActiveDialogOpen(false)
                    } else {
                        setErrorDialogOpen(true)
                    }
                }}
                onCancel={() => {
                    setTariffToToggleActive(undefined)
                    setToggleActiveDialogOpen(false)
                }}
                content={
                    tariffToToggleActive?.active
                        ? t('tariffs.toggleTariffToInactive')
                        : t('tariffs.toggleTariffToActive')
                }
                confirmLabel={
                    tariffToToggleActive?.active
                        ? t('tariffs.toggleTariffToInactiveBtn')
                        : t('tariffs.toggleTariffToActiveBtn')
                }
                cancelLabel={t('common.cancel')}
                title={t('tariffs.toggleActiveTariffTitle')}
                inProgress={!toggleActiveTariffState.executed}
            />
            <Dialog
                open={errorDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {errorDialogOpen && (
                    <>
                        <DialogTitle id="alert-dialog-title">{t('app.errorTitle')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText className={classes.noMargin}>
                                {t('tariffs.tariffAssignedToLocations')}
                            </DialogContentText>
                            {assignedLocations.map((item: any, index: number) => (
                                <DialogContentText
                                    className={index !== assignedLocations.length - 1 ? classes.noMargin : ''}
                                >
                                    {item.name}
                                </DialogContentText>
                            ))}
                            <DialogContentText>{t('tariffs.toDisableUnassign')}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setErrorDialogOpen(false)
                                }}
                                variant="contained"
                                color="primary"
                            >
                                {t('common.close')}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    )
}

export default Tariffs
