import React, { SFC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PricingConditionModel, getLabelForCountry } from '../../../shared/types/pricingCondition'
import { Grid, Paper, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { TabPanel } from '../../../shared/components/tabPanel'
import { getMainTenantId } from '../../../config'
import { ChargerConnectorType, getLabelForChargerConnectorType } from '../../../shared/types/charger'

const mainTenantId = getMainTenantId()

const useStyles = makeStyles((theme) => ({
    cardWrapper: {
        height: '100%',
        width: `calc(100% - ${theme.spacing(1)})`,
        paddingLeft: theme.spacing(1),
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    cardWrapperInner: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    tabContentWrapper: {
        flexGrow: 1,
        overflowY: 'auto',
        height: '100%',
        padding: theme.spacing(3),
    },
    tabContent: {
        height: '100%',
    },
    card: {
        height: '100%',
    },
    titleWrapper: {
        alignItems: 'center',
        padding: theme.spacing(2, 3),
        flex: '0',
    },
    titleText: {
        textAlign: 'left',
    },
    titleButtons: {
        textAlign: 'right',
    },
    profileItem: {
        textAlign: 'left',
    },
    profileValue: {
        paddingLeft: theme.spacing(1),
        textAlign: 'left',
        position: 'relative',
        width: '80%',
        '& span': {
            position: 'absolute',
            top: theme.spacing(0.3),
        },
    },
    groupDivider: {
        height: theme.spacing(1.5),
    },
    sessionDayTitle: {
        marginLeft: theme.spacing(1),
    },
    sessionDayTitleDivider: {
        marginTop: theme.spacing(3),
    },
    sessionListItem: {
        margin: theme.spacing(1, 0, 1, 0),
        padding: theme.spacing(2),
        display: 'flex',
        width: '50%',
    },
    sessionListContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    sessionListContent: {
        flex: '1 0 auto',
        textAlign: 'left',
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
    },
    sessionImageWrapper: {
        flex: '1 0 auto',
        textAlign: 'right',
        marginLeft: theme.spacing(1),
    },
    sessionImage: {
        width: '80px',
        height: '80px',
    },
    sessionLoadMore: {
        marginBottom: theme.spacing(3),
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
    fullHeight: {
        height: '100%',
    },
}))

interface PricingConditionCardProps {
    pricingCondition: PricingConditionModel
    onDelete: Function
    onEdit: Function
}
const PricingConditionCard: SFC<PricingConditionCardProps> = ({ pricingCondition, onDelete, onEdit }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const tenantId = useSelector((state: any) => state.login.value.user.id)
    const tenants = useSelector((state: any) => state.tenants.value)
    const tenant = tenants?.find((tenant: any) => tenant.id === pricingCondition.tenantId)

    const tags = useSelector((state: any) => state.tags.items.all.value)
    const userTag = tags?.find((tag: any) => tag.id === pricingCondition.userTag)
    const chargerTag = tags?.find((tag: any) => tag.id === pricingCondition.chargerTag)
    const locationTag = tags?.find((tag: any) => tag.id === pricingCondition.locationTag)
    const chargingProfileTag = tags?.find((tag: any) => tag.id === pricingCondition.chargingProfileTag)

    const tariffs = useSelector((state: any) => state.tariffs.items.value)
    const tariff = tariffs?.find((tariff: any) => tariff.id === pricingCondition.scheduleTariff)

    const connectorType = getLabelForChargerConnectorType(pricingCondition.connectorType, t)
    const country = getLabelForCountry(pricingCondition.country, t)

    const formatDate = (dateString: Date) => {
        const date = new Date(dateString)

        const day = date.getDate()
        const month = date.toLocaleString('en-GB', { month: 'long' }) // Full month name
        const year = date.getFullYear()
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')

        return `${day}. ${month} ${year}, ${hours}:${minutes}`
    }

    const getChargingUnits = () => {
        switch(pricingCondition.connectorType) {
            case ChargerConnectorType.Ac :
                return ' A';
            case ChargerConnectorType.Dc :
                return ' kW';
            default:
                return ''
        }
    }

    const [tabIndex, setTabIndex] = useState<number>(0)
    const pricingConditionValues = [
        // ['id', pricingCondition.id],
        [t('pricingConditions.name'), pricingCondition.name],
        [t('pricingConditions.description'), pricingCondition.description],
        [t('pricingConditions.priority'), pricingCondition.priority || '0'],
        [t('pricingConditions.maxSessionsPerUser'), !pricingCondition.maxSessionsPerUser || pricingCondition.maxSessionsPerUser == '0' ? 'Unrestricted' : pricingCondition.maxSessionsPerUser],
        [t('pricingConditions.connectorType'), connectorType],
        [t('pricingConditions.country'), country],
        [t('pricingConditions.validFrom'), formatDate(pricingCondition.validFrom)],
        [t('pricingConditions.validTo'), formatDate(pricingCondition.validTo)],
        [t('pricingConditions.priceForKwh'), !pricingCondition.priceForKwh || pricingCondition.priceForKwh == '0' ? 'Unrestricted' :  pricingCondition.priceForKwh + ' €'],
        [t('pricingConditions.limitPerSession'), !pricingCondition.limitPerSession || pricingCondition.limitPerSession == '0' ? 'Unrestricted' : pricingCondition.limitPerSession + ' kWh'],
        [t('pricingConditions.priceForMinuteOfParking'), !pricingCondition.priceForMinuteOfParking || pricingCondition.priceForMinuteOfParking == '0' ? 'Unrestricted' : pricingCondition.priceForMinuteOfParking + ' €'],
        [t('pricingConditions.amountOfFreeParking'), !pricingCondition.amountOfFreeParking || pricingCondition.amountOfFreeParking == '0' ? 'Unrestricted' : pricingCondition.amountOfFreeParking + ' min'],
        [t('pricingConditions.maxSpeedOfCharging'), !pricingCondition.maxSpeedOfCharging || pricingCondition.maxSpeedOfCharging == '0' ? 'Unrestricted' : pricingCondition.maxSpeedOfCharging + getChargingUnits() ],
        [t('pricingConditions.maximumDurationOfCharging'), !pricingCondition.maximumDurationOfCharging || pricingCondition.maximumDurationOfCharging == '0' ? 'Unrestricted' : pricingCondition.maximumDurationOfCharging + ' min'],
        [t('pricingConditions.userTag'), userTag ? userTag.name : pricingCondition.userTag],
        [t('pricingConditions.chargerTag'), chargerTag ? chargerTag.name : pricingCondition.chargerTag],
        [t('pricingConditions.locationTag'), locationTag ? locationTag.name : pricingCondition.locationTag],
        [
            t('pricingConditions.chargingProfileTag'),
            chargingProfileTag ? chargingProfileTag.name : pricingCondition.chargingProfileTag,
        ],
        [t('pricingConditions.scheduleTariff'), tariff ? tariff.name : pricingCondition.scheduleTariff],
        tenantId && tenantId === mainTenantId
            ? [t('common.tenant'), tenant?.login ? tenant.login : pricingCondition.tenantId]
            : [],
    ]

    return (
        <div className={classes.cardWrapper}>
            <Paper elevation={1} className={classes.card}>
                <div className={classes.cardWrapperInner}>
                    <Grid item xs={12} container className={classes.titleWrapper}>
                        <Grid item xs={6} className={classes.titleText}>
                            <Typography variant="h2" style={{ padding: 0 }}>
                                {pricingCondition.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.titleButtons}>
                            <IconButton onClick={() => onDelete()}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton onClick={() => onEdit()}>
                                <EditIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <div className={classes.tabContentWrapper}>
                        <TabPanel value={tabIndex} index={0} className={classes.tabContent}>
                            <Grid container className={classes.fullHeight}>
                                {pricingConditionValues.map((x: any, index) =>
                                    x.length === 2 ? (
                                        <React.Fragment key={index}>
                                            <Grid item sm={4} className={classes.profileItem}>
                                                <Typography variant="subtitle2">{x[0]}</Typography>
                                            </Grid>
                                            <Grid item sm={8} className={classes.profileValue}>
                                                <Typography variant="caption" component="div">
                                                    {x[1] && x[1] !== '' ? x[1] : '\u00A0'}
                                                </Typography>
                                            </Grid>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment key={index}>
                                            <Grid item sm={12} className={classes.groupDivider} />
                                            {x.length === 1 && (
                                                <Grid item sm={12} className={classes.profileItem}>
                                                    <Typography variant="h6">{x[0]}</Typography>
                                                </Grid>
                                            )}
                                        </React.Fragment>
                                    )
                                )}
                            </Grid>
                        </TabPanel>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default PricingConditionCard
