import {
    locationChargersReducer,
    locationsListUpdateReducer,
    locationsMapUpdateReducer,
    locationDetailUpdateReducer,
    locationSelectedIdUpdateReducer,
} from './location'
import { tariffReducer } from './tariffs'
import { locationWhitelistReducer } from './locationWhitelist'
import { combineReducers } from 'redux'
import {
    errorReducer,
    asyncActionReducer,
    asyncPagedActionReducer,
    valueHolderReducer,
} from './../../shared/redux/reducers/common'
import {
    FETCH_LIST_LOCATIONS,
    FETCH_MAP_LOCATIONS,
    FETCH_LOCATION_FOR_DETAIL,
    FETCH_LOCATION_WHITELIST,
    UPDATE_LOCATION_WHITELIST,
    SELECTED_LOCATION_ID,
    SEARCH_LOCATIONS,
    ADD_LOCATION,
    EDIT_LOCATION,
    DELETE_LOCATION,
    UPLOAD_LOCATION_PHOTOS,
    DELETE_LOCATION_PHOTO,
    ADD_CHARGER,
    EDIT_CHARGER,
    DELETE_CHARGER,
    RESET_CHARGER,
    UPDATE_CONNECTORS,
    UNLOCK_CONNECTOR,
    UPLOAD_CHARGER_PHOTOS,
    DELETE_CHARGER_PHOTO,
    UPLOAD_CHARGER_CSV,
    FETCH_USERS,
    SEARCH_USERS,
    ADD_USER,
    EDIT_USER,
    DELETE_USER,
    FETCH_TAGS,
    FETCH_TAGS_LOCATION_ALL,
    FETCH_TAGS_CHARGER_ALL,
    FETCH_TAGS_USER_ALL,
    SEARCH_PRICING_CONDITIONS,
    ADD_PRICING_CONDITIONS,
    EDIT_PRICING_CONDITIONS,
    DELETE_PRICING_CONDITIONS,
    FETCH_PRICING_CONDITIONS,
    SEARCH_TAGS,
    ADD_TAG,
    EDIT_TAG,
    DELETE_TAG,
    FETCH_SESSIONS,
    CHANGE_CONNECTOR_AVAILABILITY,
    LOGIN,
    MAP_TOKEN,
    FETCH_TARIFFS,
    ADD_TARIFF,
    EDIT_TARIFF,
    TOGGLE_ACTIVE_TARIFF,
    FETCH_TARIFFS_LOCATIONS,
    FETCH_SESSIONS_HISTORY,
    SEARCH_SESSIONS,
    FILTER_BY_CONNECTOR,
    FILTER_BY_SOCKET,
    FILTER_BY_USER,
    FILTER_BY_INIT_SOURCE,
    SORT_BY_PROPERTY,
    SORT_BY_ORDER,
    SET_PAYMENT_SETTINGS,
    FETCHS_LOGS,
    FILTER_BY_CHARGER_ID,
    FILTER_BY_TYPE,
    FILTER_BY_FROM,
    FILTER_BY_TILL,
    FILTER_HEARTHBEAT,
    FILTER_RAW,
    FILTER_BY_INVOICE_ONLY,
    RESEND_INVOICE,
    FETCH_CHARGER_CONFIGURATION,
    CHANGE_CHARGER_CONFIGURATION,
    FETCH_REPORTS,
    FETCH_CHARGER_INTEGRATIONS,
    FETCH_TENANTS,
} from '../const'

export default combineReducers({
    tenants: asyncActionReducer(FETCH_TENANTS),
    reports: asyncActionReducer(FETCH_REPORTS),
    users: combineReducers({
        items: asyncActionReducer(FETCH_USERS, []),
        add: asyncActionReducer(ADD_USER),
        edit: asyncActionReducer(EDIT_USER),
        delete: asyncActionReducer(DELETE_USER),
        search: valueHolderReducer(SEARCH_USERS, ''),
        sessions: asyncPagedActionReducer(FETCH_SESSIONS),
    }),
    tags: combineReducers({
        items: combineReducers({
            all: asyncActionReducer(FETCH_TAGS, []),
            location: asyncActionReducer(FETCH_TAGS_LOCATION_ALL, []),
            charger: asyncActionReducer(FETCH_TAGS_CHARGER_ALL, []),
            user: asyncActionReducer(FETCH_TAGS_USER_ALL, []),
        }),
        search: valueHolderReducer(SEARCH_TAGS, ''),
        add: asyncActionReducer(ADD_TAG),
        edit: asyncActionReducer(EDIT_TAG),
        delete: asyncActionReducer(DELETE_TAG),
    }),
    pricingConditions: combineReducers({
        items: asyncActionReducer(FETCH_PRICING_CONDITIONS, []),
        search: valueHolderReducer(SEARCH_PRICING_CONDITIONS, ''),
        add: asyncActionReducer(ADD_PRICING_CONDITIONS),
        edit: asyncActionReducer(EDIT_PRICING_CONDITIONS),
        delete: asyncActionReducer(DELETE_PRICING_CONDITIONS),
    }),
    locations: combineReducers({
        listItems: asyncPagedActionReducer(FETCH_LIST_LOCATIONS, locationsListUpdateReducer),
        mapItems: asyncActionReducer(FETCH_MAP_LOCATIONS, [], locationsMapUpdateReducer),
        detail: asyncActionReducer(FETCH_LOCATION_FOR_DETAIL, null, locationDetailUpdateReducer),
        selectedId: valueHolderReducer(SELECTED_LOCATION_ID, null, locationSelectedIdUpdateReducer),
        add: asyncActionReducer(ADD_LOCATION),
        edit: asyncActionReducer(EDIT_LOCATION),
        delete: asyncActionReducer(DELETE_LOCATION),
        uploadPhotos: asyncActionReducer(UPLOAD_LOCATION_PHOTOS),
        deletePhoto: asyncActionReducer(DELETE_LOCATION_PHOTO),
        search: valueHolderReducer(SEARCH_LOCATIONS, ''),
        chargers: combineReducers({
            add: asyncActionReducer(ADD_CHARGER),
            edit: asyncActionReducer(EDIT_CHARGER),
            delete: asyncActionReducer(DELETE_CHARGER),
            updateConnectors: asyncActionReducer(UPDATE_CONNECTORS),
            uploadPhotos: asyncActionReducer(UPLOAD_CHARGER_PHOTOS),
            deletePhoto: asyncActionReducer(DELETE_CHARGER_PHOTO),
            resetCharger: asyncActionReducer(RESET_CHARGER),
            changeConnectorAvailability: asyncActionReducer(CHANGE_CONNECTOR_AVAILABILITY),
            unlockConnector: asyncActionReducer(UNLOCK_CONNECTOR),
            uploadChargerCsv: asyncActionReducer(UPLOAD_CHARGER_CSV),
        }),
        editedLocationChargers: locationChargersReducer(),
    }),
    locationWhitelist: combineReducers({
        items: asyncActionReducer(FETCH_LOCATION_WHITELIST, null),
        update: asyncActionReducer(UPDATE_LOCATION_WHITELIST, null, locationWhitelistReducer()),
    }),
    chargerIntegrations: combineReducers({
        items: asyncActionReducer(FETCH_CHARGER_INTEGRATIONS, null),
    }),
    tariffs: combineReducers({
        items: asyncActionReducer(FETCH_TARIFFS, null, tariffReducer()),
        add: asyncActionReducer(ADD_TARIFF),
        edit: asyncActionReducer(EDIT_TARIFF),
        toggleActive: asyncActionReducer(TOGGLE_ACTIVE_TARIFF),
        assignedLocations: asyncActionReducer(FETCH_TARIFFS_LOCATIONS),
    }),
    sessions: combineReducers({
        items: asyncPagedActionReducer(FETCH_SESSIONS_HISTORY),
        filter: combineReducers({
            search: valueHolderReducer(SEARCH_SESSIONS, ''),
            connectorType: valueHolderReducer(FILTER_BY_CONNECTOR),
            socketType: valueHolderReducer(FILTER_BY_SOCKET),
            userType: valueHolderReducer(FILTER_BY_USER),
            chargingInitSource: valueHolderReducer(FILTER_BY_INIT_SOURCE),
            showOnlyWithInvoice: valueHolderReducer(FILTER_BY_INVOICE_ONLY, false),
        }),
        sort: combineReducers({
            property: valueHolderReducer(SORT_BY_PROPERTY, 'startedAt'),
            order: valueHolderReducer(SORT_BY_ORDER, 'desc'),
        }),
        resendInvoice: asyncActionReducer(RESEND_INVOICE)
    }),
    login: valueHolderReducer(LOGIN, { isLoggedIn: false, user: null }),
    mapToken: valueHolderReducer(MAP_TOKEN),
    error: errorReducer(),
    paymentSettings: valueHolderReducer(SET_PAYMENT_SETTINGS),
    logs: combineReducers({
        items: asyncActionReducer(FETCHS_LOGS),
        filter: combineReducers({
            chargerId: valueHolderReducer(FILTER_BY_CHARGER_ID),
            type: valueHolderReducer(FILTER_BY_TYPE),
            from: valueHolderReducer(FILTER_BY_FROM),
            till: valueHolderReducer(FILTER_BY_TILL),
            showHeartbeat: valueHolderReducer(FILTER_HEARTHBEAT, true),
            showRaw: valueHolderReducer(FILTER_RAW, true),
        }),
    }),
    chargerConfiguration: combineReducers({
        items: asyncActionReducer(FETCH_CHARGER_CONFIGURATION),
        update: asyncActionReducer(CHANGE_CHARGER_CONFIGURATION),
    }),
})
