import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { logsStyles } from './LogsStyles'
import LogsFilter from './LogsFilter/LogsFilter'
import LogsTable from './LogsTable/LogsTable'

export default function Logs() {
    const classes = logsStyles()
    const { t } = useTranslation()

    return (
        <>
            <Grid container>
                <Grid item xs={12} className={classes.headerWrapper}>
                    <Typography align="left" variant="h3">
                        {t('logs.title')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <LogsFilter />
                </Grid>
                <Grid item sm={12}>
                    <LogsTable />
                </Grid>
            </Grid>
        </>
    )
}
