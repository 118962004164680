import { ChargerConnectorType } from "./charger";

export type PricingConditionModel = {
  id: string;
  name: string;
  description: string;
  priority: string;
  validFrom: Date;
  validTo: Date;
  userTag: string;
  chargerTag: string;
  locationTag: string;
  chargingProfileTag: string;
  scheduleTariff?: string;
  connectorType?: ChargerConnectorType | null;
  priceForKwh: string;
  limitPerSession: string;
  maxSpeedOfCharging: string;
  priceForMinuteOfParking: string;
  amountOfFreeParking: string;
  maximumDurationOfCharging: string;
  tenantId: string;
  country: CountryType;
  maxSessionsPerUser: string;
};

export enum CountryType {
  SK,
  CZ,
}

export function getLabelForCountry(type: CountryType, t: any) {
  const foundType = getCountryTypes(t).find((x) => x.id === type);
  return foundType ? foundType.label : t("common.unknown");
}

export function getCountryTypes(t: any) {
  return [
    {
      id: CountryType.SK,
      label: "Slovakia",
    },
    {
      id: CountryType.CZ,
      label: "Czech Republic",
    },
  ];
}
