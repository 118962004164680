import React, { SFC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    makeStyles,
    Drawer,
    Grid,
    Typography,
    IconButton,
    CircularProgress,
    Link,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { ExpandableText } from '../../../shared/components/ExpandableText'
import { useTranslation } from 'react-i18next'
import ChargerList from './ChargerList'
import { LocationListItem, Location } from '../../../shared/types/location'
import { Charger } from '../../../shared/types/charger'
import { useCalcWidthToQuarter } from '../../../utils/ui'
import LocationWhitelistEditDialog from '../locationWhitelistEditDialog/LocationWhitelistEditDialog'
import LocationAddEditDialog from '../locationAddEditDialog/LocationAddEditDialog'
import ChargerAddEditDialog from '../chargerAddEditDialog/ChargerAddEditDialog'
import ChargerSettingsDialog from '../chargerSettingsDialog/ChargerSettingsDialog'
import ChargerQRCodeDialog from '../chargerQRCodeDialog/ChargerQRCodeDialog'
import { DeleteConfirmationDialog } from '../../../shared/components/deleteConfirmationDialog'
import ImagesManager from '../../../shared/components/imagesManager'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PersonIcon from '@material-ui/icons/Person'
import {
    deleteLocation,
    uploadPhotosForLocation,
    deleteLocationPhoto,
    deleteCharger,
    fetchLocationForDetail,
} from '../../../redux/actions/locations'
import { promiseError } from '../../../shared/redux/actions/common'
import { ReadOnlyEditor } from '../../../shared/components/ReadOnlyEditor'
import { updateLocationWhitelist } from '../../../redux/actions/locationWhitelist'
import { getMainTenantId } from '../../../config'

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        top: '64px',
        height: 'calc(100% - 64px)',
    },
    contents: {
        padding: theme.spacing(3, 2),
    },
    contentsProgress: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imagesManagerWrapper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    title: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    titleInfo: {
        flex: '1 0 auto',
        maxWidth: '80%',
    },
    locationImage: {
        width: '100%',
        height: theme.spacing(10),
    },
    locationField: {
        textAlign: 'left',
        marginBottom: theme.spacing(2),
    },
    chargersCount: {
        textAlign: 'left',
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
    },
    addCharger: {
        textAlign: 'right',
        paddingBottom: theme.spacing(1),
    },
    detailLabel: {
        textAlign: 'left',
        display: 'flex',
    },
    detailValue: {
        textAlign: 'right',
    },
    expansionPanel: {
        boxShadow: 'none',
    },
    expansionPanelSummary: {
        padding: 0,
        minHeight: 0,
        '& .MuiExpansionPanelSummary-content': {
            margin: 0,
        },
        '& .MuiExpansionPanelSummary-expandIcon': {
            padding: 0,
        },
    },
    expansionPanelDetails: {
        padding: 0,
    },
}))

interface LocationDetailProps {
    onClose: Function
    locationId?: string
}
const LocationDetail: SFC<LocationDetailProps> = ({ onClose, locationId }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const calcWidthToQuarter = useCalcWidthToQuarter('locationDrawerPaper')

    const dispatch = useDispatch()

    const locationDetail = useSelector((state: any) => state.locations.detail.value)
    const locationDetailExecuted = useSelector((state: any) => state.locations.detail.executed)

    const [dialogOpen, setDialogOpen] = useState({
        chargerSettings: false,
        editLocation: false,
        editLocationWhitelist: false,
        QRCode: false,
        deleteLocation: false,
    })
    const [dialogModel, setDialogModel] = useState<any>()

    const [initialLoadInProgress, setInitialLoadInProgress] = useState<boolean>(false)
    const [locationFromEdit, setLocationFromEdit] = useState<LocationListItem>()
    // const [locationWhitelist, setLocationWhitelist] = useState<string[]>()

    const prepareFullAdress = (index: any) => {
        if (index === 0) {
            return (
                ', ' + locationToDisplay.street + ' ' + locationToDisplay.houseNum + ', ' + locationToDisplay.postalCode
            )
        } else {
            return ''
        }
    }

    const deleteExecuted = useSelector((state: any) => state.locations.delete.executed)

    const chargers = useSelector((state: any) => state.locations.editedLocationChargers.chargers)
    const [chargerAddEditState, setChargerAddEditState] = useState({
        open: false,
        isAdd: false,
        initialModel: {} as Charger,
    })

    const [chargerToDelete, setChargerToDelete] = useState<Charger>()
    const chargerDeleteExecuted = useSelector((state: any) => state.locations.chargers.delete.executed)

    const onEditDialogClose = (editedLocation?: Location) => {
        setDialogOpen({ ...dialogOpen, editLocation: false })
        if (locationDetail && editedLocation) {
            setLocationFromEdit({
                ...locationDetail,
                ...editedLocation,
            })
        }
    }

    const onEditWhitelistDialogClose = (whitelist?: string[]) => {
        setDialogOpen({ ...dialogOpen, editLocationWhitelist: false })
        if (whitelist) {
            dispatch(updateLocationWhitelist(locationDetail.id, whitelist))
        }
    }

    const onSettingsDialogClose = () => {
        setDialogOpen({ ...dialogOpen, chargerSettings: false })
    }

    const onQRCodeDialogClose = () => {
        setDialogOpen({ ...dialogOpen, QRCode: false })
    }
    
    const onChargerAddEditDialogClose = (charger?: Charger) => {
        setChargerAddEditState({
            ...chargerAddEditState,
            open: false,
        })
    }

    const locationToDisplay: LocationListItem = locationFromEdit ?? locationDetail ?? ({ images: [] } as any)

    const tenantId = useSelector((state: any) => state.login.value.user.id)

    const tenants = useSelector((state: any) => state.tenants.value)
    const tenant = tenants?.find((tenant: any) => tenant.id === locationToDisplay.tenantId);

    const locationFields = [
        [t('locations.address'), locationToDisplay.city],
        tenantId && tenantId === getMainTenantId() ? [t('common.tenant'), tenant?.login ? tenant.login : locationToDisplay.tenantId] : [],
        [t('common.description'), locationToDisplay.description],
    ]
    useEffect(() => {
        if (locationId && (!locationDetail || locationId !== locationDetail.id) && !initialLoadInProgress) {
            setInitialLoadInProgress(true)
            dispatch(fetchLocationForDetail(locationId))
        }
    }, [dispatch, locationId, locationDetail, initialLoadInProgress])

    useEffect(() => {
        if (locationDetailExecuted) {
            setInitialLoadInProgress(false)
        }
    }, [locationDetailExecuted])

    useEffect(() => {
        if (locationId && locationFromEdit && locationFromEdit.id !== locationId) {
            setLocationFromEdit(undefined)
        }
    }, [locationId, locationFromEdit])
    
    return (
        <>
            <Drawer
                open={Boolean(locationId)}
                PaperProps={{
                    elevation: 8,
                    ref: calcWidthToQuarter,
                    id: 'locationDrawerPaper',
                    className: classes.drawerPaper,
                }}
                anchor="right"
                variant="persistent"
            >
                {initialLoadInProgress ? (
                    <div className={classes.contentsProgress}>
                        <CircularProgress />
                    </div>
                ) : (
                    <Grid container spacing={0} className={classes.contents}>
                        <Grid item xs={12} className={classes.title}>
                            <div className={classes.titleInfo}>
                                <Typography variant="h5">{locationToDisplay.name}</Typography>
                                <Typography component="div" variant="caption">
                                    {t('locations.chargersAvailable', {
                                        countTotal: locationToDisplay.chargerCount,
                                        countAvailable:
                                            locationToDisplay.chargerCount - locationToDisplay.chargersInUseCount,
                                    })}
                                </Typography>
                            </div>
                            <div>
                                <IconButton
                                    onClick={() => setDialogOpen({ ...dialogOpen, editLocationWhitelist: true })}
                                >
                                    <PersonIcon fontSize="small" />
                                </IconButton>
                                <IconButton onClick={() => setDialogOpen({ ...dialogOpen, editLocation: true })}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton onClick={() => setDialogOpen({ ...dialogOpen, deleteLocation: true })}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                                <IconButton onClick={() => onClose()}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid item xs={12} className={classes.imagesManagerWrapper}>
                            <ImagesManager
                                images={locationToDisplay.images}
                                imageClassName={classes.locationImage}
                                imageCols={4}
                                uploadAction={async (files) => {
                                    await dispatch(uploadPhotosForLocation(locationToDisplay, files))
                                }}
                                uploadError={() => dispatch(promiseError(t('common.uploadError')))}
                                uploadProgressStateSelector={(state: any) => state.locations.uploadPhotos}
                                deleteAction={async (image) =>
                                    await dispatch(deleteLocationPhoto(locationToDisplay, image))
                                }
                                deleteProgressStateSelector={(state: any) => state.locations.deletePhoto}
                            />
                        </Grid>
                        {locationFields.map(
                            (field, index) =>
                                field[1] && (
                                    <Grid key={index} item container xs={12} className={classes.locationField}>
                                        {field[0] === t('common.description') ? (
                                            <ExpansionPanel className={classes.expansionPanel}>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    className={classes.expansionPanelSummary}
                                                >
                                                    <Grid item xs={12} className={classes.detailLabel}>
                                                        <Typography variant="subtitle2">{field[0]}</Typography>
                                                    </Grid>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                                                    <Typography align="justify" variant="caption" noWrap={false}>
                                                        <ReadOnlyEditor value={field[1]} />
                                                    </Typography>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        ) : (
                                            <>
                                                <Grid item xs={12} className={classes.detailLabel}>
                                                    <Typography variant="subtitle2">{field[0]}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ExpandableText
                                                        typographyProps={{
                                                            align: 'justify',
                                                            variant: 'caption',
                                                        }}
                                                    >
                                                        {field[1] + prepareFullAdress(index)}
                                                    </ExpandableText>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                )
                        )}
                        <Grid item xs={6} className={classes.chargersCount}>
                            <Typography variant="caption">
                                {t('locations.chargersCountSimple', {
                                    count: locationToDisplay.chargerCount,
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.addCharger}>
                            <Link
                                component="button"
                                onClick={() => {
                                    setChargerAddEditState({
                                        open: true,
                                        isAdd: true,
                                        initialModel: {
                                            locationId: locationToDisplay.id,
                                            tenantId: locationToDisplay.tenantId
                                        } as Charger,
                                    })
                                }}
                            >
                                {t('locations.addCharger')}
                            </Link>
                        </Grid>
                        <Grid item xs={12}>
                            <ChargerList
                                chargers={chargers}
                                onDelete={setChargerToDelete}
                                onEdit={(chargerToEdit) => {
                                    setChargerAddEditState({
                                        open: true,
                                        isAdd: false,
                                        initialModel: {
                                            ...chargerToEdit,
                                            tenantId: locationToDisplay?.tenantId || ''
                                        }
                                    })
                                }}
                                onSettings={(charger) => {
                                    setDialogModel(charger)
                                    setDialogOpen({ ...dialogOpen, chargerSettings: true })
                                }}
                                onQRCode={(charger) => {
                                    setDialogModel(charger)
                                    setDialogOpen({ ...dialogOpen, QRCode: true })
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </Drawer>

            <ChargerAddEditDialog onClose={onChargerAddEditDialogClose} {...chargerAddEditState} />
            {dialogOpen.chargerSettings && (
                <ChargerSettingsDialog open={true} onClose={onSettingsDialogClose} initialModel={dialogModel} />
            )}
            {dialogOpen.QRCode && (
                <ChargerQRCodeDialog open={true} onClose={onQRCodeDialogClose} initialModel={dialogModel} />
            )}

            <LocationWhitelistEditDialog
                open={dialogOpen.editLocationWhitelist}
                // onCancel={() => setDialogOpen({ ...dialogOpen, editLocationWhitelist: false })}
                // onConfirm={async (whitelist) => {
                //     dispatch(updateLocationWhitelist(locationDetail.id, whitelist))
                //     setDialogOpen({ ...dialogOpen, editLocationWhitelist: false })
                // }}
                onClose={onEditWhitelistDialogClose}
                initialModel={locationToDisplay}
            />
            <LocationAddEditDialog
                open={dialogOpen.editLocation}
                isAdd={false}
                onClose={onEditDialogClose}
                initialModel={locationToDisplay}
            />
            <DeleteConfirmationDialog
                open={dialogOpen.deleteLocation}
                onConfirm={async () => {
                    await dispatch(deleteLocation(locationToDisplay))
                    setDialogOpen({ ...dialogOpen, deleteLocation: false })
                }}
                onCancel={() => setDialogOpen({ ...dialogOpen, deleteLocation: false })}
                inProgress={!deleteExecuted}
            />
            <DeleteConfirmationDialog
                open={Boolean(chargerToDelete)}
                onConfirm={async () => {
                    const chargerToDeleteDefined = chargerToDelete as Charger
                    await dispatch(deleteCharger(chargerToDeleteDefined))
                    await dispatch(fetchLocationForDetail(locationToDisplay.id as string))
                    setChargerToDelete(undefined)
                }}
                onCancel={() => setChargerToDelete(undefined)}
                inProgress={!chargerDeleteExecuted}
            />
        </>
    )
}

export default LocationDetail
