import { getMainTenantId } from '../../config'
import { asyncActionCreator } from '../../shared/redux/actions/common'
import { getJson } from '../../shared/utils/api'
import { FETCH_REPORTS } from '../const'

export const fetchReports = (tenantId: string) => {
    if (tenantId === getMainTenantId()) {
        return asyncActionCreator(FETCH_REPORTS, () => getJson(`report/all`))
    } else {
        return asyncActionCreator(FETCH_REPORTS, () => getJson(`report/${tenantId}`))
    }
}