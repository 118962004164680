import React, { useState, useRef, useEffect } from 'react'
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw, ContentBlock, ContentState } from 'draft-js'
import { RichEditorStyles } from './RichEditorStyles'
import { updateStyles } from './RichEditorUtils'
import {
    FormatBold,
    FormatItalic,
    FormatUnderlined,
    FormatListBulleted,
    FormatListNumbered,
    FormatQuote,
} from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

const INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
]

const StyleButton = (props: any) => {
    const onToggle = (e: any) => {
        e.preventDefault()
        props.onToggle(props.style)
    }

    const getButton = (label: string) => {
        switch (label) {
            case 'Bold':
                return <FormatBold style={{ height: '22px' }} />
            case 'Italic':
                return <FormatItalic style={{ height: '22px' }} />
            case 'Underline':
                return <FormatUnderlined style={{ height: '20px' }} />
            case 'Blockquote':
                return <FormatQuote style={{ height: '28px' }} />
            case 'UL':
                return <FormatListBulleted style={{ height: '23px' }} />
            case 'OL':
                return <FormatListNumbered style={{ height: '22px' }} />
            default:
                return <span className={className + ' RichEditor-textButton'}>{label}</span>
        }
    }

    let className = 'RichEditor-styleButton'
    if (props.active) {
        className += ' RichEditor-activeButton'
    }

    return (
        <IconButton className={className} onClick={onToggle}>
            {getButton(props.label)}
        </IconButton>
    )
}

const InlineStyleControls = (props: any) => {
    var currentStyle = props.editorState.getCurrentInlineStyle()
    return (
        <>
            {INLINE_STYLES.map((type) => (
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            ))}
        </>
    )
}

const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: 'Blockquote', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
]

const BlockStyleControls = (props: any) => {
    const { editorState } = props
    const selection = editorState.getSelection()
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()

    return (
        <>
            {BLOCK_TYPES.map((type) => (
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            ))}
        </>
    )
}

function getBlockStyle(block: ContentBlock) {
    switch (block.getType()) {
        case 'blockquote':
            return 'RichEditor-blockquote'
        default:
            return ''
    }
}

export const RichEditor = (props: any) => {
    const classes = RichEditorStyles()
    const editor = useRef<any>()
    const [styles, setStyles] = useState<any>({
        label:
            'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-filled',
        span: 'MuiFormLabel-asterisk MuiInputLabel-asterisk',
        editorWrapper:
            'RichEditor-editor MuiInputBase-formControl MuiInputBase-root MuiFilledInput-root MuiInputBase-fullWidth MuiFilledInput-underline MuiFilledInput-multiline',
        isError: false,
    })
    const [dirty, setDirty] = useState<boolean>(false)

    const [editorState, setEditorState] = useState(() => {
        if (props.initialValue) {
            try {
                return EditorState.createWithContent(convertFromRaw(JSON.parse(props.initialValue)))
            } catch (e) {
                return EditorState.createWithContent(ContentState.createFromText(props.initialValue))
            }
        } else {
            return EditorState.createEmpty()
        }
    })

    if (editorState.getCurrentContent().hasText() && styles.label.indexOf('MuiInputLabel-shrink') === -1) {
        setStyles({ ...styles, label: styles.label + ' MuiInputLabel-shrink' })
    }

    const onFocus = () => {
        if (editor?.current != null) {
            editor.current.focus()
        }
    }

    const onChange = (editorState: EditorState) => {
        setStyles(updateStyles(editorState, styles, props.required, props.beforeSubmit, dirty))
        setEditorState(editorState)
        props.onChange(props.property, JSON.stringify(convertToRaw(editorState.getCurrentContent())))
        if (editorState.getCurrentContent().hasText() && !dirty) {
            setDirty(true)
        }
    }

    const handleKeyCommand = (command: string) => {
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
            onChange(newState)
            return 'handled'
        }
        return 'not-handled'
    }

    const onTab = (e: any) => {
        const maxDepth = 4
        onChange(RichUtils.onTab(e, editorState, maxDepth))
    }

    const toggleBlockType = (blockType: any) => {
        onChange(RichUtils.toggleBlockType(editorState, blockType))
    }

    const toggleInlineStyle = (inlineStyle: any) => {
        onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
    }

    useEffect(() => {
        if (props.required) {
            setStyles(updateStyles(editorState, styles, props.required, props.beforeSubmit, dirty))
        }
    }, [editorState, styles, props.required, props.beforeSubmit, dirty])

    return (
        <div className={classes.richEditorRoot + ' MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth'}>
            <label className={styles.label}>
                {props.label}
                {props.required && <span className={styles.span}> *</span>}
            </label>
            <div className={styles.editorWrapper} onClick={onFocus}>
                <Editor
                    ref={editor}
                    blockStyleFn={getBlockStyle}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={onChange}
                    onTab={onTab}
                    spellCheck={true}
                />
            </div>
            {styles.isError && (
                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">Required</p>
            )}
            <div className="RichEditor-controls">
                <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
                <BlockStyleControls editorState={editorState} onToggle={toggleBlockType} />
            </div>
        </div>
    )
}
