export const LOGIN = 'LOGIN'
export const ERROR_DISMISS = 'ERROR_DISMISS'
export const PROMISE_ERROR = 'PROMISE_ERROR'

export const FETCH_LIST_LOCATIONS = 'FETCH_LIST_LOCATIONS'
export const FETCH_MAP_LOCATIONS = 'FETCH_MAP_LOCATIONS'
export const FETCH_LOCATION_FOR_DETAIL = 'FETCH_LOCATION_FOR_DETAIL'
export const SEARCH_LOCATIONS = 'SEARCH_LOCATIONS'
export const SELECTED_LOCATION_ID = 'SELECTED_LOCATION_ID'
export const ADD_LOCATION = 'ADD_LOCATION'
export const EDIT_LOCATION = 'EDIT_LOCATION'
export const DELETE_LOCATION = 'DELETE_LOCATION'
export const UPLOAD_LOCATION_PHOTOS = 'UPLOAD_LOCATION_PHOTOS'
export const DELETE_LOCATION_PHOTO = 'DELETE_LOCATION_PHOTO'

export const FETCH_LOCATION_WHITELIST = 'FETCH_LOCATION_WHITELIST'
export const UPDATE_LOCATION_WHITELIST = 'UPDATE_LOCATION_WHITELIST'

export const ADD_CHARGER = 'ADD_CHARGER'
export const EDIT_CHARGER = 'EDIT_CHARGER'
export const DELETE_CHARGER = 'DELETE_CHARGER'
export const RESET_CHARGER = 'RESET_CHARGER'
export const UPLOAD_CHARGER_PHOTOS = 'UPLOAD_CHARGER_PHOTOS'
export const DELETE_CHARGER_PHOTO = 'DELETE_CHARGER_PHOTO'
export const UPLOAD_CHARGER_CSV = 'UPLOAD_CHARGER_CSV'
export const UPDATE_CONNECTORS = 'UPDATE_CONNECTORS'
export const CHANGE_CONNECTOR_AVAILABILITY = 'CHANGE_CONNECTOR_AVAILABILITY'
export const UPDATE_CONNECTOR_AVAILABILITY = 'UPDATE_CONNECTOR_AVAILABILITY'
export const UNLOCK_CONNECTOR = 'UNLOCK_CONNECTOR'
export const FETCH_CHARGER_INTEGRATIONS = 'FETCH_CHARGER_INTEGRATIONS'

export const FETCH_USERS = 'FETCH_USERS'
export const SEARCH_USERS = 'SEARCH_USERS'
export const ADD_USER = 'ADD_USER'
export const EDIT_USER = 'EDIT_USER'
export const DELETE_USER = 'DELETE_USER'
export const FETCH_SESSIONS = 'FETCH_SESSIONS'

export const FETCH_TAGS = 'FETCH_TAGS'
export const FETCH_TAGS_LOCATION_ALL = 'FETCH_TAGS_LOCATION_ALL'
export const FETCH_TAGS_LOCATION = 'FETCH_TAGS_LOCATION'
export const FETCH_TAGS_CHARGER_ALL = 'FETCH_TAGS_CHARGER_ALL'
export const FETCH_TAGS_CHARGER = 'FETCH_TAGS_CHARGER'
export const FETCH_TAGS_USER_ALL = 'FETCH_TAGS_USER_ALL'
export const FETCH_TAGS_USER = 'FETCH_TAGS_USER'
export const SEARCH_TAGS = 'SEARCH_TAGS'
export const ADD_TAG = 'ADD_TAG'
export const EDIT_TAG = 'EDIT_TAG'
export const DELETE_TAG = 'DELETE_TAG'

export const ADD_PRICING_CONDITIONS = 'ADD_PRICING_CONDITIONS'
export const EDIT_PRICING_CONDITIONS = 'EDIT_PRICING_CONDITIONS'
export const DELETE_PRICING_CONDITIONS = 'DELETE_PRICING_CONDITIONS'
export const FETCH_PRICING_CONDITIONS = 'FETCH_PRICING_CONDITIONS'
export const SEARCH_PRICING_CONDITIONS = 'SEARCH_PRICING_CONDITIONS'

export const FETCH_TARIFFS = 'FETCH_TARIFFS'
export const FETCH_TARIFFS_LOCATIONS = 'FETCH_TARIFFS_LOCATIONS'
export const ADD_TARIFF = 'ADD_TARIFF'
export const EDIT_TARIFF = 'EDIT_TARIFF'
export const TOGGLE_ACTIVE_TARIFF = 'TOGGLE_ACTIVE_TARIFF'

export const FETCH_SESSIONS_HISTORY = 'FETCH_SESSIONS_HISTORY'
export const SEARCH_SESSIONS = 'SEARCH_SESSIONS'
export const FILTER_BY_CONNECTOR = 'FILTER_BY_CONNECTOR'
export const FILTER_BY_SOCKET = 'FILTER_BY_SOCKET'
export const FILTER_BY_USER = 'FILTER_BY_USER'
export const FILTER_BY_INIT_SOURCE = 'FILTER_BY_INIT_SOURCE'
export const SORT_BY_PROPERTY = 'SORT_BY_PROPERTY'
export const SORT_BY_ORDER = 'SORT_BY_ORDER'
export const SET_PAYMENT_SETTINGS = 'SET_PAYMENT_SETTINGS'

export const FETCHS_LOGS = 'FETCHS_LOGS'
export const FILTER_BY_TYPE = 'FILTER_BY_TYPE'
export const FILTER_BY_FROM = 'FILTER_BY_FROM'
export const FILTER_BY_TILL = 'FILTER_BY_TILL'
export const FILTER_HEARTHBEAT = 'FILTER_HEARTHBEAT'
export const FILTER_RAW = 'FILTER_RAW'
export const FILTER_BY_CHARGER_ID = 'FILTER_BY_CHARGER_ID'
export const FILTER_BY_INVOICE_ONLY = 'FILTER_BY_INVOICE_ONLY'
export const RESEND_INVOICE = 'RESEND_INVOICE'

export const FETCH_CHARGER_CONFIGURATION = 'FETCH_CHARGER_CONFIGURATION'
export const CHANGE_CHARGER_CONFIGURATION = 'CHANGE_CHARGER_CONFIGURATION'

export const MAP_TOKEN = 'MAP_TOKEN'

export const ADMIN_PROFILE_UPDATE = 'UPDATE_ADMIN_PROFILE'
export const ADMIN_PROFILE_PASSWORD_UPDATE = 'ADMIN_PROFILE_PASSWORD_UPDATE'
export const ADMIN_PROFILE_LOGO_UPDATE = 'ADMIN_PROFILE_LOGO_UPDATE'
export const ADMIN_PROFILE_LOGO_DELETE = 'ADMIN_PROFILE_LOGO_DELETE'
export const FETCH_TENANTS = 'FETCH_TENANTS'
export const FETCH_REPORTS = 'FETCH_REPORTS'
