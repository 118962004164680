import { asyncActionCreator, valueHolderActionCreator } from './../../shared/redux/actions/common'
import {
    FETCHS_LOGS,
    FILTER_BY_CHARGER_ID,
    FILTER_BY_TYPE,
    FILTER_BY_FROM,
    FILTER_BY_TILL,
    FILTER_HEARTHBEAT,
    FILTER_RAW
} from '../const'
import { getJson } from '../../shared/utils/api'

export const filterByChargerId = (chargerId: any) => {
    return valueHolderActionCreator(FILTER_BY_CHARGER_ID, chargerId)
}

export const filterByType = (type: any) => {
    return valueHolderActionCreator(FILTER_BY_TYPE, type)
}

export const filterByFrom = (from: any) => {
    return valueHolderActionCreator(FILTER_BY_FROM, from)
}

export const filterByTill = (till: any) => {
    return valueHolderActionCreator(FILTER_BY_TILL, till)
}

export const filterHearthBeat = (showHearthBeat: any) => {
    return valueHolderActionCreator(FILTER_HEARTHBEAT, showHearthBeat)
}

export const filterShowRaw = (showRaw: any) => {
    return valueHolderActionCreator(FILTER_RAW, showRaw)
} 

export const fetchLogs = (page?: number, rowsPerPage?: number, filter?: any) => {
    const url = 'DiagnosticsAdmin/diagnostic?'+getFetchLogsUrl(page, rowsPerPage, filter);

    return asyncActionCreator(FETCHS_LOGS, () => getJson(url))
}

export const getFetchLogsUrl = (page?: number, rowsPerPage?: number, filter?: any) => {
    let url = ''
    if (filter) {
        if (filter.chargerId.value) {
            url += `chargerId=${filter.chargerId.value}&`
        }
        if (filter.type.value) {
            url += `type=${filter.type.value}&`
        }
        if (filter.from.value) {
            url += `from=${filter.from.value.toDateString()}&`
        }
        if (filter.till.value) {
            url += `till=${filter.till.value.toDateString()}&`
        }
        if (filter.showHeartbeat.value != null) {
            url += `showHeartbeat=${filter.showHeartbeat.value}&`
        } else {
            url += `showHeartbeat=true&`
        }
        if (filter.showHeartbeat.value != null) {
            url += `showRaw=${filter.showRaw.value}&`
        } else {
            url += `showRaw=true&`
        }
    }
    url += `page=${page}&`
    url += `pageSize=${rowsPerPage}&`

    return url;
}

