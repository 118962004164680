import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Button, MenuItem, Menu, Grid } from '@material-ui/core'
import logo from './../../static/logo.png'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { useTranslation } from 'react-i18next'
import { logout } from '../../shared/redux/actions/common'
import { removeToken } from '../../config'

const useStyles = makeStyles((theme) => ({
    user: {
        marginLeft: 'auto',
    },
    userMenuItem: {
        paddingRight: theme.spacing(10),
    },
}))

const AppBar = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null)

    const dispatch = useDispatch()
    const loginState = useSelector((state: any) => state.login.value)

    const handleMenuClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSignOut = () => {
        removeToken()
        dispatch(logout())
        handleClose()
    }

    return (
        <Paper square>
            <Grid container spacing={0}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Toolbar disableGutters>
                        <img src={logo} alt="" />
                        <Button
                            className={classes.user}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                            endIcon={anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        >
                            {loginState.user.login}
                        </Button>
                        <Menu
                            id="simple-menu"
                            getContentAnchorEl={null}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem className={classes.userMenuItem} onClick={handleSignOut}>
                                {t('app.signOut')}
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </Grid>
                <Grid item xs={1} />
            </Grid>
        </Paper>
    )
}

export default AppBar
