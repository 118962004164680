import React, { SFC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { UserModel } from '../../../shared/types/user'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import {
    Grid,
    Paper,
    IconButton,
    Button,
    Tabs,
    Typography,
    Tab,
    CircularProgress,
    Card,
    CardContent,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import QueueIcon from '@material-ui/icons/Queue'
import { TabPanel } from '../../../shared/components/tabPanel'
import { fetchSessionsForUser } from '../../../redux/actions/users'
import { ChargingSession } from '../../../shared/types/location'
import moment, { Moment } from 'moment'
import { Image } from '../../../shared/components/Image'
import { NoData } from '../../../shared/components/NoData'
import { consumedKwh } from '../../../shared/utils/display'
import { exportFile } from '../../../shared/utils/export'
import { config, getToken } from '../../../config'
import { getOffset } from '../../../shared/utils/localTime'
import UserAddEditDialog from '../userAddEditDialog/UserAddEditDialog'

const useStyles = makeStyles((theme) => ({
    cardWrapper: {
        height: '100%',
        width: `calc(100% - ${theme.spacing(1)})`,
        paddingLeft: theme.spacing(1),
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    cardWrapperInner: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    tabContentWrapper: {
        flexGrow: 1,
        overflowY: 'auto',
        height: '100%',
        padding: theme.spacing(3),
    },
    tabContent: {
        height: '100%',
    },
    card: {
        height: '100%',
    },
    titleWrapper: {
        alignItems: 'center',
        padding: theme.spacing(2, 3),
        flex: '0',
    },
    titleText: {
        textAlign: 'left',
    },
    titleButtons: {
        textAlign: 'right',
    },
    profileItem: {
        textAlign: 'left',
    },
    profileValue: {
        paddingLeft: theme.spacing(1),
        textAlign: 'left',
        position: 'relative',
        width: '80%',
        '& span': {
            position: 'absolute',
            top: theme.spacing(0.3),
        },
    },
    groupDivider: {
        height: theme.spacing(1.5),
    },
    sessionDayTitle: {
        marginLeft: theme.spacing(1),
    },
    sessionDayTitleDivider: {
        marginTop: theme.spacing(3),
    },
    sessionListItem: {
        margin: theme.spacing(1, 0, 1, 0),
        padding: theme.spacing(2),
        display: 'flex',
        width: '50%',
    },
    sessionListContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    sessionListContent: {
        flex: '1 0 auto',
        textAlign: 'left',
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
    },
    sessionImageWrapper: {
        flex: '1 0 auto',
        textAlign: 'right',
        marginLeft: theme.spacing(1),
    },
    sessionImage: {
        width: '80px',
        height: '80px',
    },
    sessionLoadMore: {
        marginBottom: theme.spacing(3),
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
    fullHeight: {
        height: '100%',
    },
}))

interface SessionCardProps {
    session: ChargingSession
}
const SessionCard: SFC<SessionCardProps> = React.memo(({ session }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const onExport = () => {
        exportFile(config.proxy, config.api, `ChargingSession/invoice/${session.id}`, getToken(), getOffset())
    }

    const formatDuration = () => {
        const duration = moment.duration(moment(session.endedAt).diff(moment(session.startedAt)))
        return moment()
            .hours(duration.hours())
            .minutes(duration.minutes())
            .seconds(duration.seconds())
            .format('H[h] m[m] s[s]')
    }

    return (
        <Card className={classes.sessionListItem} elevation={1}>
            <div className={classes.sessionListContentWrapper}>
                <CardContent className={classes.sessionListContent}>
                    <Typography variant="h6">{session.location?.name}</Typography>
                    <Typography component="div" variant="caption">
                        {t('users.sessionStart', {
                            time: moment(session.startedAt).format('LT'),
                        })}
                    </Typography>
                    <Typography component="div" variant="caption">
                        {session.endedAt
                            ? t('users.sessionDuration', {
                                  duration: formatDuration(),
                                  consumedKwh: consumedKwh(session),
                              })
                            : t('users.sessionInProgress')}
                    </Typography>
                </CardContent>
            </div>
            <div className={classes.sessionImageWrapper}>
                <IconButton className={classes.exportButton} onClick={onExport} disabled={!session.hasInvoice}>
                    <PictureAsPdfIcon />
                </IconButton>
                <Image
                    className={classes.sessionImage}
                    imageClassName={classes.sessionImage}
                    url={session.location.images?.length > 0 ? session.location.images[0].url : undefined}
                />
            </div>
        </Card>
    )
})

interface UserCardProps {
    user: UserModel
    onDelete: Function
    onEdit: Function
}
const UserCard: SFC<UserCardProps> = ({ user, onDelete, onEdit }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const sessionsState = useSelector((state: any) => state.users.sessions)

    const [tabIndex, setTabIndex] = useState<number>(0)
    const userValues = [
        [t('users.firstName'), user.firstName],
        [t('users.lastName'), user.lastName],
        [t('users.email'), user.email],
        [t('users.phone'), user.phone],
        [],
        [t('users.classProfiles'), t('users.standard')],
        [t('users.rfid'), user.rfid],
        [t('users.carLicensePlate'), user.carLicensePlate],
        [t('users.address')],
        [t('users.street'), user.street],
        [t('users.city'), user.city],
        [t('users.zip'), user.zip],
        [t('users.state'), user.country],
        [t('users.billingInformation')],
        [t('users.companyId'), user.companyId],
        [t('users.taxId'), user.taxId],
        [t('users.vatId'), user.vatId],
        [t('users.card')],
        [t('users.cardType'), user.cardType],
        [t('users.cardNumber'), user.cardNumber ? `**** **** **** ${user.cardNumber}` : ''],
        [t('users.expirationDate'), user.cardExpiration],
    ]
    
    const [addedUser, setAddedUser] = useState<UserModel>()
    const [addEditDialogState, setAddEditDialogState] = useState({
        open: false,
        isAdd: true,
        onClose: (user: UserModel) => {},
        initialModel: undefined as UserModel | undefined,
    })

    const openAddDialog = () => {
        setAddEditDialogState({
            open: true,
            isAdd: true,
            onClose: onAddDialogClose,
            initialModel: user,
        })
    }
    const onAddDialogClose = (user?: UserModel) => {
        setAddEditDialogState({ ...addEditDialogState, open: false })
        if (user) {
            setAddedUser(user)
        }
    }

    const sessionsWithDate: any[] = []
    let lastStartedAtDay: Moment
    sessionsState.data.forEach((x: ChargingSession) => {
        const currentStartedAtDay = moment(x.startedAt).startOf('day')
        if (!currentStartedAtDay.isSame(lastStartedAtDay)) {
            sessionsWithDate.push({ date: currentStartedAtDay.format('LL'), value: null })
            lastStartedAtDay = currentStartedAtDay
        }

        sessionsWithDate.push({ date: null, value: x })
    })

    useEffect(() => {
        if (tabIndex === 1 && sessionsState.executed && sessionsState.key !== user.id) {
            dispatch(fetchSessionsForUser(user, sessionsState))
        }
    }, [dispatch, tabIndex, user, sessionsState])

    return (
        <div className={classes.cardWrapper}>
            <Paper elevation={1} className={classes.card}>
                <div className={classes.cardWrapperInner}>
                    <Grid item xs={12} container className={classes.titleWrapper}>
                        <Grid item xs={6} className={classes.titleText}>
                            <Typography variant="h2" style={{ padding: 0 }}>
                                {user.firstName} {user.lastName}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.titleButtons}>
                            <IconButton onClick={() => openAddDialog()}>
                                <QueueIcon />
                            </IconButton>
                            {addEditDialogState.open && <UserAddEditDialog {...addEditDialogState} />}
                            <IconButton onClick={() => onDelete()}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton onClick={() => onEdit()}>
                                <EditIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <div>
                        <Tabs value={tabIndex} onChange={(event: any, newValue: number) => setTabIndex(newValue)}>
                            <Tab label={t('users.profile')} color="primary" />
                            <Tab label={t('users.history')} />
                        </Tabs>
                    </div>
                    <div className={classes.tabContentWrapper}>
                        <TabPanel value={tabIndex} index={0} className={classes.tabContent}>
                            <Grid container className={classes.fullHeight}>
                                {userValues.map((x: any, index) =>
                                    x.length === 2 ? (
                                        <React.Fragment key={index}>
                                            <Grid item sm={2} className={classes.profileItem}>
                                                <Typography variant="subtitle2">{x[0]}</Typography>
                                            </Grid>
                                            <Grid item sm={10} className={classes.profileValue}>
                                                <Typography variant="caption" component="div">
                                                    {x[1] && x[1] !== '' ? x[1] : '\u00A0'}
                                                </Typography>
                                            </Grid>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment key={index}>
                                            <Grid item sm={12} className={classes.groupDivider} />
                                            {x.length === 1 && (
                                                <Grid item sm={12} className={classes.profileItem}>
                                                    <Typography variant="h6">{x[0]}</Typography>
                                                </Grid>
                                            )}
                                        </React.Fragment>
                                    )
                                )}
                            </Grid>
                        </TabPanel>

                        <TabPanel value={tabIndex} index={1} className={classes.tabContent}>
                            {sessionsWithDate.map((sessionWithDate: any, index) =>
                                sessionWithDate.date ? (
                                    <Typography
                                        variant="subtitle2"
                                        key={sessionWithDate.date}
                                        className={`${classes.sessionDayTitle} ${
                                            index > 0 ? classes.sessionDayTitleDivider : ''
                                        }`}
                                    >
                                        {sessionWithDate.date}
                                    </Typography>
                                ) : (
                                    <SessionCard key={sessionWithDate.value.id} session={sessionWithDate.value} />
                                )
                            )}

                            {sessionsState.hasMorePages && sessionsState.executed && (
                                <Button
                                    className={classes.sessionLoadMore}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        dispatch(fetchSessionsForUser(user, sessionsState))
                                    }}
                                >
                                    {t('common.loadMore')}
                                </Button>
                            )}
                            {!sessionsState.executed ? (
                                <CircularProgress />
                            ) : (
                                sessionsWithDate.length === 0 && <NoData text={t('users.noSessions')} />
                            )}
                        </TabPanel>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default UserCard
