import React, { SFC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Dialog,
    DialogTitle,
    makeStyles,
    DialogContent,
    DialogActions,
    Grid,
    MenuItem,
    FormControl,
    FormLabel,
    Select,
    InputLabel,
    TextField,
    InputAdornment,
} from '@material-ui/core'
import { Form } from 'react-final-form'
import { TextField as MuiTextField } from 'mui-rff'
import { CountryType, PricingConditionModel } from '../../../shared/types/pricingCondition'
import { ValidationErrors } from 'final-form'
import { useDispatch, useSelector } from 'react-redux'
import { setRequiredErrors } from '../../../utils/ui'
import { DialogConfirmButton } from '../../../shared/components/dialogConfirmButton'
import { getMainTenantId } from '../../../config'
import { addPricingCondition, editPricingCondition } from '../../../redux/actions/pricingConditions'
import { TariffModel } from '../../../shared/types/tariff'
import { fetchTariffs } from '../../../redux/actions/tariffs'
import { fetchTags } from '../../../redux/actions/tags'
import { TagModel, TagType } from '../../../shared/types/tag'
import { AdminEditModel, fetchTenants } from '../../../redux/actions/tenants'
import { DialogProgress } from '../../../shared/components/dialogProgress'
import { ChargerConnectorType } from '../../../shared/types/charger'
import { DateTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const mainTenantId = getMainTenantId()

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiFilledInput-root': {
            borderRadius: theme.spacing(0.5),
        },
        '& .MuiFilledInput-root::before': {
            border: 0,
        },
    },
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'hidden',
    },
}))

interface PricingConditionAddEditDialogProps {
    open: boolean
    isAdd: boolean
    onClose(pricingCondition?: PricingConditionModel): void
    initialModel?: PricingConditionModel
}
const PricingConditionAddEditDialog: SFC<PricingConditionAddEditDialogProps> = ({
    open,
    isAdd,
    onClose,
    initialModel,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const tariffsState = useSelector((state: any) => state.tariffs.items)
    const tagsState = useSelector((state: any) => state.tags.items.all)
    const tenantsState = useSelector((state: any) => state.tenants)
    const tenantId = useSelector((state: any) => state.login.value.user.id)

    // set some defaults
    const [validFrom, setValidFrom] = useState<Date>(initialModel?.validFrom || new Date())
    const [validTo, setValidTo] = useState<Date>(initialModel?.validTo || new Date(2099, 11, 31))
    const [tenant, setTenant] = useState<string>(tenantId != mainTenantId ? tenantId : '')

    const [locationTag, setLocationTag] = useState<string>('')
    const [chargerTag, setChargerTag] = useState<string>('')
    const [userTag, setUserTag] = useState<string>('')
    const [chargingProfileTag, setChargingProfileTag] = useState<string>('')
    const [scheduleTariff, setScheduleTariff] = useState<string>('')
    const [priority, setPriority] = useState<string>(initialModel?.priority || '50')
    const [isMaxSessionsDisabled, setIsMaxSessionsDisabled] = useState(true);
    const [priceForKwh, setPriceForKwh] = useState<string>(initialModel?.priceForKwh ? initialModel.priceForKwh : '');
    const [priceForMinuteOfParking, setPriceForMinuteOfParking] = useState<string>(initialModel?.priceForMinuteOfParking ? initialModel.priceForMinuteOfParking : '');

    const stateSelector = isAdd
        ? (state: any) => state.pricingConditions.add
        : (state: any) => state.pricingConditions.edit

    const [connectorType, setConnectorType] = useState<ChargerConnectorType | null>(initialModel?.connectorType ?? null)
    const [country, setCountry] = useState<CountryType>(initialModel?.country || CountryType.SK)

    const connectorTypes = [
        { value: '', label: <em>{t('common.any')}</em> }, // Add empty option
        ...Object.keys(ChargerConnectorType)
            .filter((key) => isNaN(Number(key))) // Filter out numeric values
            .map((key) => ({
                value: ChargerConnectorType[key as keyof typeof ChargerConnectorType],
                label: key,
            })),
    ]

    const countries = Object.keys(CountryType)
        .filter((key) => isNaN(Number(key))) // Filter out numeric values
        .map((key) => ({
            value: CountryType[key as keyof typeof CountryType],
            label: key,
        }))

    const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCountry(event.target.value as CountryType)
    }

    const handleConnectorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value === ChargerConnectorType.Ac || event.target.value === ChargerConnectorType.Dc) {
            setConnectorType(event.target.value as ChargerConnectorType | null);
        } else {
            setConnectorType(null);
        }
    }

    const handleTenantChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTenant(event.target.value as string)
        setLocationTag('')
        setChargerTag('')
        setUserTag('')
        setChargingProfileTag('')
        setScheduleTariff('')
    }

    const handleValidFrom = (value: MaterialUiPickersDate) => {
        if (value !== null) {
            const dateFrom = value.toDate()
            setValidFrom(dateFrom)
        }
    }

    const handleValidTo = (value: MaterialUiPickersDate) => {
        if (value) {
            const dateTo = value.toDate()
            if (dateTo < new Date(validFrom)) {
                const adjustedDate = new Date(validFrom)
                setValidTo(adjustedDate)
            } else {
                setValidTo(dateTo)
            }
        }
    }

    const isSessionFree = () => {
        if ((!priceForMinuteOfParking || priceForMinuteOfParking === "0") && (!priceForKwh || priceForKwh === "0")) {
            return true
        } 
        return false
    }

    useEffect(() => {
        dispatch(fetchTariffs(tenantId))
        dispatch(fetchTags())
        dispatch(fetchTenants())
    }, [dispatch, tenantId])

    useEffect(() => {
        if (initialModel) {
            setTenant(initialModel.tenantId as string)
            setLocationTag(initialModel.locationTag)
            setChargerTag(initialModel.chargerTag)
            setUserTag(initialModel.userTag)
            setChargingProfileTag(initialModel.chargingProfileTag)
            setScheduleTariff(initialModel?.scheduleTariff || '')

            // prevent zero values
            initialModel.maxSessionsPerUser = !initialModel.maxSessionsPerUser || initialModel.maxSessionsPerUser == '0' ? '' : initialModel.maxSessionsPerUser
            setPriceForKwh(!initialModel.priceForKwh || initialModel.priceForKwh == '0' ? '' : initialModel.priceForKwh)
            setPriceForMinuteOfParking(!initialModel.priceForMinuteOfParking || initialModel.priceForMinuteOfParking == '0' ? '' : initialModel.priceForMinuteOfParking)
            initialModel.limitPerSession = !initialModel.limitPerSession || initialModel.limitPerSession == '0' ? '' : initialModel.limitPerSession
            initialModel.maxSpeedOfCharging = !initialModel.maxSpeedOfCharging || initialModel.maxSpeedOfCharging == '0' ? '' : initialModel.maxSpeedOfCharging
            initialModel.amountOfFreeParking = !initialModel.amountOfFreeParking || initialModel.amountOfFreeParking == '0'
                ? ''
                : initialModel.amountOfFreeParking
            initialModel.maximumDurationOfCharging = !initialModel.maximumDurationOfCharging || initialModel.maximumDurationOfCharging == '0'
                ? ''
                : initialModel.maximumDurationOfCharging

            setIsMaxSessionsDisabled(!isSessionFree())

        }
    }, [initialModel])

    useEffect(() => {
        setIsMaxSessionsDisabled(!isSessionFree())
    }, [priceForKwh, priceForMinuteOfParking])


    return (
        <Dialog className={classes.dialog} open={open}>
            <DialogProgress stateSelector={stateSelector} />
            <DialogTitle>
                {isAdd ? t('pricingConditions.newPricingConditions') : t('pricingConditions.editPricingCondition')}
            </DialogTitle>

            <Form
                onSubmit={async (model) => {

                    model = {
                        ...model,
                        validFrom: validFrom,
                        validTo: validTo,
                        connectorType: connectorType,
                        tenantId: tenant,
                        country: country,
                        locationTag: locationTag,
                        userTag: userTag,
                        chargerTag: chargerTag,
                        chargingProfileTag: chargingProfileTag,
                        scheduleTariff: scheduleTariff,
                        priority: priority,
                        maxSpeedOfCharging: connectorType === null || model.maxSpeedOfCharging === "" ? '0' : model.maxSpeedOfCharging,
                        priceForKwh: priceForKwh === "" ? "0" : priceForKwh,
                        priceForMinuteOfParking: priceForMinuteOfParking === "" ? "0" : priceForMinuteOfParking,
                        limitPerSession: model.limitPerSession === "" ? '0' : model.limitPerSession,
                        amountOfFreeParking: model.amountOfFreeParking === "" ? '0' : model.amountOfFreeParking,
                        maximumDurationOfCharging: model.maximumDurationOfCharging === "" ? '0' : model.maximumDurationOfCharging,
                        maxSessionsPerUser: isMaxSessionsDisabled || model.maxSessionsPerUser === "" ? '0' : model.maxSessionsPerUser,
                    }
                    const result: any = await dispatch(
                        isAdd
                            ? addPricingCondition(model as PricingConditionModel)
                            : editPricingCondition(model as PricingConditionModel)
                    )
                    if (result) {
                        onClose(result)
                    }
                }}
                initialValues={initialModel}
                validate={(model: any): ValidationErrors => {
                    model = {
                        ...model,
                        validFrom: validFrom,
                        validTo: validTo,
                        connectorType: connectorType,
                        tenantId: tenant,
                        country: country,
                        locationTag: locationTag,
                        userTag: userTag,
                        chargerTag: chargerTag,
                        chargingProfileTag: chargingProfileTag,
                        scheduleTariff: scheduleTariff,
                        priority: priority,
                    }
                    const result: any = {}
                    setRequiredErrors(result, model, ['name', 'priority', 'validFrom', 'validTo'], t('common.required'))
                    return result
                }}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                        <DialogContent>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        required
                                        name="name"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('pricingConditions.name')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiTextField
                                        name="description"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('pricingConditions.description')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="filled" fullWidth>
                                        <FormLabel>{t('pricingConditions.validFrom')}</FormLabel>
                                        <DateTimePicker format="LLL" value={validFrom} onChange={handleValidFrom} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="filled" fullWidth>
                                        <FormLabel>{t('pricingConditions.validTo')}</FormLabel>
                                        <DateTimePicker
                                            format="LLL"
                                            minDate={validFrom}
                                            value={validTo}
                                            onChange={handleValidTo}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        name="priority"
                                        value={priority}
                                        onChange={(event) => setPriority(event.target.value)}
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                            min: 0,
                                            max: 500,
                                        }}
                                        type="number"
                                        variant="filled"
                                        label={t('pricingConditions.priority')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiTextField
                                        name="maxSessionsPerUser"
                                        fullWidth
                                        disabled={isMaxSessionsDisabled}
                                        inputProps={{
                                            maxLength: 128,
                                            min: 0,
                                        }}
                                        type="number"
                                        variant="filled"
                                        label={t('pricingConditions.maxSessionsPerUser')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel>{t('pricingConditions.connectorType')}</InputLabel>
                                        <Select value={connectorType} onChange={handleConnectorChange}>
                                            {connectorTypes.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="filled" fullWidth required>
                                        <InputLabel>{t('pricingConditions.country')}</InputLabel>
                                        <Select value={country} onChange={handleCountryChange}>
                                            {countries.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid hidden={!(tenantId && tenantId === mainTenantId)} item xs={12}>
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel>{t('common.tenant')}</InputLabel>
                                        <Select value={tenant} onChange={handleTenantChange}>
                                            <MenuItem value="">
                                                <em>{t('common.none')}</em>
                                            </MenuItem>
                                            {tenantsState.value
                                                ?.filter((item: AdminEditModel) => item.id !== mainTenantId)
                                                .map((item: AdminEditModel) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.login}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel>{t('pricingConditions.scheduleTariff')}</InputLabel>
                                        <Select
                                            name="scheduleTariff"
                                            value={scheduleTariff}
                                            onChange={(event) => setScheduleTariff(event.target.value as string)}
                                        >
                                            <MenuItem value="">
                                                <em>{t('common.none')}</em>
                                            </MenuItem>
                                            {tariffsState.value
                                                ?.filter((item: TariffModel) => item.active && item.tenantId == tenant)
                                                .map((item: TariffModel) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="priceForKwh"
                                        value={priceForKwh}
                                        onChange={(event) => setPriceForKwh(event.target.value)}
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                            min: 0,
                                        }}
                                        type="number"
                                        variant="filled"
                                        label={t('pricingConditions.priceForKwh')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>, // Units here
                                        }}
                                    />

                                    {/* <MuiTextField
                                        name="priceForKwh"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                            min: 0,
                                        }}
                                        type="number"
                                        variant="filled"
                                        label={t('pricingConditions.priceForKwh')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>, // Units here
                                        }}
                                    /> */}
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiTextField
                                        name="limitPerSession"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                            min: 0,
                                        }}
                                        type="number"
                                        variant="filled"
                                        label={t('pricingConditions.limitPerSession')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">kWh</InputAdornment>, // Units here
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="priceForMinuteOfParking"
                                        value={priceForMinuteOfParking}
                                        onChange={(event) => setPriceForMinuteOfParking(event.target.value)}
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                            min: 0,
                                        }}
                                        type="number"
                                        variant="filled"
                                        label={t('pricingConditions.priceForMinuteOfParking')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>, // Units here
                                        }}
                                    />

                                    {/* <MuiTextField
                                        name="priceForMinuteOfParking"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                            min: 0,
                                        }}
                                        type="number"
                                        variant="filled"
                                        label={t('pricingConditions.priceForMinuteOfParking')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>, // Units here
                                        }}
                                    /> */}
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiTextField
                                        name="amountOfFreeParking"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                            min: 0,
                                        }}
                                        type="number"
                                        variant="filled"
                                        label={t('pricingConditions.amountOfFreeParking')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">min</InputAdornment>, // Units here
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiTextField
                                        name="maxSpeedOfCharging"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                            min: 0,
                                        }}
                                        type="number"
                                        variant="filled"
                                        label={connectorType === ChargerConnectorType.Dc ? t('pricingConditions.maxPowerOfCharging') : t('pricingConditions.maxSpeedOfCharging')}
                                        disabled={connectorType === null}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{connectorType === ChargerConnectorType.Dc ? 'kW' : 'A'}</InputAdornment>, // Units here
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiTextField
                                        name="maximumDurationOfCharging"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                            min: 0,
                                        }}
                                        type="number"
                                        variant="filled"
                                        label={t('pricingConditions.maximumDurationOfCharging')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">min</InputAdornment>, // Units here
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel>{t('pricingConditions.chargerTag')}</InputLabel>
                                        <Select
                                            name="chargerTag"
                                            value={chargerTag}
                                            onChange={(event) => setChargerTag(event.target.value as string)}
                                        >
                                            <MenuItem value="">
                                                <em>{t('common.none')}</em>
                                            </MenuItem>
                                            {tagsState.value
                                                ?.filter(
                                                    (item: TagModel) =>
                                                        item.type === TagType.Charger && item.tenantId === tenant
                                                )
                                                .map((item: TagModel) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel>{t('pricingConditions.userTag')}</InputLabel>
                                        <Select
                                            name="userTag"
                                            value={userTag}
                                            onChange={(event) => setUserTag(event.target.value as string)}
                                        >
                                            <MenuItem value="">
                                                <em>{t('common.none')}</em>
                                            </MenuItem>
                                            {tagsState.value
                                                ?.filter(
                                                    (item: TagModel) =>
                                                        item.type === TagType.User && item.tenantId === tenant
                                                )
                                                .map((item: TagModel) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel>{t('pricingConditions.chargingProfileTag')}</InputLabel>
                                        <Select
                                            name="chargingProfileTag"
                                            value={chargingProfileTag}
                                            onChange={(event) => setChargingProfileTag(event.target.value as string)}
                                        >
                                            <MenuItem value="">
                                                <em>{t('common.none')}</em>
                                            </MenuItem>
                                            {tagsState.value
                                                ?.filter(
                                                    (item: TagModel) =>
                                                        item.type === TagType.ChargingProfile &&
                                                        item.tenantId === tenant
                                                )
                                                .map((item: TagModel) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="filled" fullWidth>
                                        <InputLabel>{t('pricingConditions.locationTag')}</InputLabel>
                                        <Select
                                            name="locationTag"
                                            value={locationTag}
                                            onChange={(event) => setLocationTag(event.target.value as string)}
                                        >
                                            <MenuItem value="">
                                                <em>{t('common.none')}</em>
                                            </MenuItem>
                                            {tagsState.value
                                                ?.filter(
                                                    (item: TagModel) =>
                                                        item.type === TagType.Location && item.tenantId === tenant
                                                )
                                                .map((item: TagModel) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={() => {
                                    onClose()
                                }}
                                color="primary"
                            >
                                {t('common.cancel')}
                            </Button>
                            <DialogConfirmButton
                                label={isAdd ? t('common.create') : t('common.change')}
                                stateSelector={stateSelector}
                            />
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    )
}

export default PricingConditionAddEditDialog
