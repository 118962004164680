import { makeStyles } from '@material-ui/core'
export const adminSettingsStyles = makeStyles((theme) => ({
    headerWrapper: {
        padding: theme.spacing(1),
        marginBottom: '20px',
    },
    image: {
        margin: '10px 0',
    },
    section: {
        marginTop: '10px',
        marginBottom: '20px',
        width: '100%',
    },
    passwordSection: {
        marginTop: '10px',
        marginBottom: '20px',
        width: '100%',
        height: '55px',
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    input: {
        display: 'none',
    },
    currPass: {
        position: 'absolute',
        marginRight: 'auto',
    },
    newPass: {
        position: 'absolute',
    },
    saveBtn: {
        justifyContent: 'start',
        marginTop: '10px',
    },
    logo: {
      maxHeight: '58px',
    },
    texts: {
        width: '100%',
        maxHeight: '650px',
        overflowY: 'auto',
    },
    textWrapper: {
        marginLeft: 'auto',
    },
    inputIconRight: {
        color: 'white',
        opacity: '0.3',
        marginBottom: '14px',
        marginTop: '20px',
        height: '17px',
        float: 'right',
        marginRight: '5px',
        filter: 'brightness(0)',
    },
    button: {
        marginRight: '10px',
    },
    passwordWrapper: {
      position: 'relative',
    }
}))
