import { makeStyles } from '@material-ui/core'

export const hubjectStyles = makeStyles((theme: any) => {
  return {
    headerWrapper: {
      padding: theme.spacing(1),
    },
    fullloadButton: {
    },
    content: {
      height: '91vh',
      overflow: 'auto',
    },
  }
})

