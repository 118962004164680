import { TagUser } from "./tag"

export type UserModel = {
    active: boolean
    chargingSessionToken: string
    confirmed: boolean
    customer: string
    customerId: string
    id: string
    firstName: string
    lastName: string
    email: string
    password: string
    phone: string
    rfid: string
    cars: Array<any>
    profileImage: string
    carLicensePlate: string | null
    street: string | null
    zip: string | null
    city: string | null
    country: string | null
    companyId: string | null
    taxId: string | null
    vatId: string | null
    cardType: string | null
    cardNumber: string | null
    cardExpiration: string | null
    paymentRequestId: string | null
	showHiddenLocation: boolean
    tenants: string
    tags: TagUser[]
}

export enum UserClassProfile {
    Standard,
    Anonymous,
}