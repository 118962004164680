import { makeStyles } from '@material-ui/core'

export const filterStyles = makeStyles((theme: any) => {
    return {
        formWrapper: {
            justifyContent: 'space-between',
            height: theme.history.filterHeight,
            alignItems: 'center'
        },
        searchWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        filter: {
            paddingLeft: theme.spacing(1),
        },
        filterButtonWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: theme.spacing(1)
        },
    }
})

