import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles, Typography, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PricingConditionList from './pricingConditionList/PricingConditionList'
import PricingConditionCard from './pricingConditionCard/PricingConditionCard'
import { useCalcHeightToBottom } from '../../utils/ui'
import PricingConditionAddEditDialog from './pricingConditionAddEditDialog/PricingConditionAddEditDialog'
import { DeleteConfirmationDialog } from '../../shared/components/deleteConfirmationDialog'
import { PricingConditionModel } from '../../shared/types/pricingCondition'
import { deletePricingCondition, fetchPricingConditions } from '../../redux/actions/pricingConditions'
import { fetchTariffs } from '../../redux/actions/tariffs'
import { fetchTags } from '../../redux/actions/tags'
import { fetchTenants } from '../../redux/actions/tenants'

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        padding: theme.spacing(1),
    },
    actionButtonWrapper: {
        padding: theme.spacing(1, 0),
        textAlign: 'right',
    },
    listWrapper: {
        paddingTop: theme.spacing(2),
    },
    cardWrapper: {
        paddingTop: theme.spacing(2),
        width: '100%',
    },
}))

export default function PricingConditions() {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const tenantId = useSelector((state: any) => state.login.value.user.id)
    const [selectedPricingCondition, setSelectedPricingCondition] = useState<PricingConditionModel>()

    const [addEditDialogState, setAddEditDialogState] = useState({
        open: false,
        isAdd: true,
        onClose: (pricingCondition: PricingConditionModel) => {},
        initialModel: undefined as PricingConditionModel | undefined,
    })

    const [addedPricingCondition, setAddedPricingCondition] = useState<PricingConditionModel>()
    const [editedPricingCondition, setEditedPricingCondition] = useState<PricingConditionModel>()
    const [pricingConditionToDelete, setPricingConditionToDelete] = useState<PricingConditionModel>()

    const pricingConditions = useSelector((state: any) => state.pricingConditions.items.value)
    const deletedPricingCondition = useSelector((state: any) => state.pricingConditions.delete.value)
    const pricingConditionDeleteExecuted = useSelector((state: any) => state.pricingConditions.delete.executed)

    const pricingConditionCardHeightFill = useCalcHeightToBottom('pricingConditionsCardWrapper')

    const openAddDialog = () => {
        setAddEditDialogState({
            open: true,
            isAdd: true,
            onClose: onAddDialogClose,
            initialModel: undefined,
        })
    }

    const onAddDialogClose = (pricingCondition?: PricingConditionModel) => {
        setAddEditDialogState({ ...addEditDialogState, open: false })
        if (pricingCondition) {
            setAddedPricingCondition(pricingCondition)
        }
    }

    const onEditDialogClose = (pricingCondition?: PricingConditionModel) => {
        setAddEditDialogState({ ...addEditDialogState, open: false })
        if (pricingCondition) {
            setEditedPricingCondition(pricingCondition)
        }
    }

    const onPricingConditionSelected = (pricingCondition: PricingConditionModel) => {
        setSelectedPricingCondition(pricingCondition)
    }

    const onPricingConditionDelete = (pricingCondition: PricingConditionModel) => {
        setPricingConditionToDelete(pricingCondition)
    }

    const onPricingConditionEdit = (pricingCondition: PricingConditionModel) => {
        setAddEditDialogState({
            open: true,
            isAdd: false,
            onClose: onEditDialogClose,
            initialModel: pricingCondition,
        })
    }
    const handleRemovePricingCondition = async () => {
        const pricingConditionToDeleteDefined = pricingConditionToDelete as PricingConditionModel
        await dispatch(deletePricingCondition(pricingConditionToDeleteDefined))
        setPricingConditionToDelete(undefined)
    }

    useEffect(() => {
        dispatch(fetchPricingConditions())
    }, [dispatch, addedPricingCondition, editedPricingCondition, deletedPricingCondition])

    useEffect(() => {
        dispatch(fetchTariffs(tenantId))
        dispatch(fetchTags())
        dispatch(fetchTenants())
    }, [dispatch, tenantId])

    useEffect(() => {
        if (pricingConditions.length > 0) {
            if (selectedPricingCondition != null) {
                const pricingConditionToSelect = pricingConditions.find(
                    (x: any) => x.id === selectedPricingCondition.id
                )
                if (pricingConditionToSelect != null) {
                    setSelectedPricingCondition(pricingConditionToSelect)
                } else {
                    setSelectedPricingCondition(pricingConditions[0])
                }
            } else {
                setSelectedPricingCondition(pricingConditions[0])
            }
        } else {
            setSelectedPricingCondition(undefined)
        }
    }, [pricingConditions, selectedPricingCondition])

    return (
        <>
            <Grid container spacing={0}>
                <Grid item sm={2} className={classes.headerWrapper}>
                    <Typography align="left" variant="h3">
                        {t('pricingConditions.title')}
                    </Typography>
                </Grid>
                <Grid item sm={10} className={classes.actionButtonWrapper}>
                    <Button
                        onClick={() => {
                            openAddDialog()
                        }}
                        variant="contained"
                        color="primary"
                    >
                        {t('pricingConditions.newPricingConditions')}
                    </Button>
                    {addEditDialogState.open && <PricingConditionAddEditDialog {...addEditDialogState} />}
                </Grid>
                <Grid item sm={4} className={classes.listWrapper}>
                    <PricingConditionList
                        selectedPricingCondition={selectedPricingCondition}
                        pricingConditions={pricingConditions}
                        onPricingConditionSelected={onPricingConditionSelected}
                    />
                </Grid>
                <Grid item sm={8} className={classes.cardWrapper}>
                    <div id="pricingConditionCardWrapper" ref={pricingConditionCardHeightFill}>
                        {selectedPricingCondition && (
                            <PricingConditionCard
                                pricingCondition={selectedPricingCondition}
                                onDelete={() => onPricingConditionDelete(selectedPricingCondition)}
                                onEdit={() => onPricingConditionEdit(selectedPricingCondition)}
                            />
                        )}
                    </div>
                </Grid>
            </Grid>
            <DeleteConfirmationDialog
                open={Boolean(pricingConditionToDelete)}
                onConfirm={async () => {
                    handleRemovePricingCondition()
                }}
                content={t('pricingConditions.deleteContent')}
                onCancel={() => setPricingConditionToDelete(undefined)}
                inProgress={!pricingConditionDeleteExecuted}
            />
        </>
    )
}
