import { EditorState } from 'draft-js'

export const validate = (editorState: EditorState, beforeSubmit?: boolean, dirty?: boolean) => {
    let isValid = true
    if (dirty || !beforeSubmit) {
        isValid = editorState.getCurrentContent().hasText()
    }
    return isValid
}

export const updateStyles = (editorState: EditorState, styles: any, require: boolean, beforeSubmit?: boolean, dirty?: boolean) => {
    let newStyles: any = styles
    if (editorState.getSelection().getHasFocus()) {
        if (newStyles.label.indexOf('MuiInputLabel-shrink') === -1) {
            newStyles = { ...newStyles, label: newStyles.label + ' MuiInputLabel-shrink' }
        }
        if (newStyles.label.indexOf('Mui-focused') === -1) {
            newStyles = {
                ...newStyles,
                label: newStyles.label + ' Mui-focused',
                span: newStyles.span + ' Mui-focused',
                editorWrapper: newStyles.editorWrapper + ' Mui-focused',
            }
        }
    } else {
        if (!editorState.getCurrentContent().hasText() && newStyles.label.indexOf('MuiInputLabel-shrink') !== -1) {
            newStyles = {
                ...newStyles, label: newStyles.label.replace(' MuiInputLabel-shrink', '')
            }
        }
        if (newStyles.label.indexOf('Mui-focused') !== -1) {
            newStyles = {
                ...newStyles,
                label: newStyles.label.replace(' Mui-focused', ''),
                span: newStyles.span.replace(' Mui-focused', ''),
                editorWrapper: newStyles.editorWrapper.replace(' Mui-focused', ''),
            }
        }
    }
    if (require && !validate(editorState, beforeSubmit, dirty)) {
        if (newStyles.label.indexOf('Mui-error') === -1) {
            newStyles = {
                label: newStyles.label.replace(' MuiInputLabel-shrink', '') + ' Mui-error',
                span: newStyles.span + ' Mui-error',
                editorWrapper: newStyles.editorWrapper + ' Mui-error',
                isError: true
            }
        }
    } else if (newStyles.label.indexOf('Mui-error') !== -1) {
        newStyles = {
            isError: false,
            label: newStyles.label.replace(' Mui-error', ''),
            span: newStyles.span.replace(' Mui-error', ''),
            editorWrapper: newStyles.editorWrapper.replace(' Mui-error', ''),
        }
    }
    if (editorState.getCurrentContent().hasText() && newStyles.label.indexOf('MuiInputLabel-shrink') === -1) {
        newStyles = { ...newStyles, label: newStyles.label + ' MuiInputLabel-shrink' }
    }
    return newStyles
}