import { makeStyles } from '@material-ui/core'

export const tableStyles = makeStyles((theme: any) => {
    const height = `100vh - 64px - ${theme.spacing(6)}px - ${theme.history.filterHeight} - ${theme.history.titleHeight} - ${theme.history.buttonWrapperHeight} - ${theme.history.paginationHeight} - 10px`;
    return {
        paper: {
            height: `calc(${height})`,
            marginTop: theme.spacing(2),
        },
        table: {
            overflowY: 'visible',
            overflowX: 'visible',
            tableLayout: 'auto',
            '& td, th': {
                paddingTop: theme.spacing(0.25),
                paddingBottom: theme.spacing(0.25),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                width: 'fit-content'
            },
            '@media only screen and (min-width: 960px)': {
                width: '200vw'
            },
            '@media only screen and (min-width: 1100px)': {
                width: '175vw'
            },
            '@media only screen and (min-width: 1280px)': {
                width: '150vw'
            },
            '@media only screen and (min-width: 1540px)': {
                width: '125vw'
            },
            '@media only screen and (min-width: 1920px)': {
                width: '100vw'
            },
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        pagination: {
            height: theme.history.paginationHeight
        },
        circularProgressCell: {
            padding: 0,
            borderBottom: 'none',
        },
        circularProgressWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: `calc(${height} - 80px)`
        }
    }
})