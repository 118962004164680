import React from 'react'
import Typography from '@material-ui/core/Typography'
import HistoryFilter from './HistoryFilter/HistoryFilter'
import HistoryTable from './HistoryTable/HistoryTable'
import { historyStyles } from './HistoryStyles'
import { Button, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { queryBuilder } from '../../redux/actions/sessions'
import { exportFile } from '../../shared/utils/export'
import { config, getToken } from '../../config'

export default function History() {
  const classes = historyStyles()
  const { t } = useTranslation()
  const filter = useSelector((state: any) => state.sessions.filter)
  const sort = useSelector((state: any) => state.sessions.sort)

  const exportTable = () => {
    exportFile(config.proxy, config.api, `ChargingSession/history/csv`, getToken(), queryBuilder(filter, sort))
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.headerWrapper}>
          <Typography align="left" variant="h3">{t('history.title')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <HistoryFilter />
        </Grid>
        <Grid item sm={12}>
          <HistoryTable />
        </Grid>
        <Button
          className={classes.exportButton}
          variant="contained"
          color="primary"
          onClick={exportTable}
        >
          {t('history.exportTable')}
        </Button>
      </Grid>
    </>
  );
}