import { makeStyles } from '@material-ui/core'
export const tariffsStyles = makeStyles((theme) => ({
    headerWrapper: {
        padding: theme.spacing(1),
    },
    actionButtonWrapper: {
        padding: theme.spacing(1, 0),
        textAlign: 'right',
    },
    list: {
        marginTop: theme.spacing(3),
        overflowY: 'auto',
        flexFlow: 'column nowrap',
        overflowX: 'hidden'
    },
    listItem: {
        width: '100%',
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(0.125),
        marginLeft: theme.spacing(0.125),
        minHeight: '88px',
        maxHeight: '88px',
    },
    inputSwitch: {
        marginRight: '15px',
    },
    contentsProgress: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    noMargin: {
        margin: '0px'
    }
}))

export const tariffDialogStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiFilledInput-root': {
            borderRadius: theme.spacing(0.5),
        },
        '& .MuiFilledInput-root::before': {
            border: 0,
        },
        '& .MuiButton-containedSecondary:hover': {
            color: '#ffffff',
        },
        '& .MuiDialog-paper': {
            maxWidth: '620px !important',
        },
        '& ..MuiIconButton-root': {
            paddingTop: '15px',
        },
    },
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
    },
    fullWidth: {
        width: '100%',
    },
    titleCell: {
        width: '100px',
    },
    dialogButton: {
        margin: theme.spacing(3, 0),
    },
    deleteIcon: {
        marginTop: '5px',
    },
    deleteCell: {
        padding: '12px 8px 4px 8px !important',
    },
}))
