import React, { SFC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogTitle, makeStyles, DialogContent, DialogActions, Grid, MenuItem } from '@material-ui/core'
import { Form } from 'react-final-form'
import { Select, TextField } from 'mui-rff'
import { TagModel } from '../../../shared/types/tag'
import { ValidationErrors } from 'final-form'
import { useDispatch, useSelector } from 'react-redux'
import { addTag, editTag } from '../../../redux/actions/tags'
import { setRequiredErrors } from '../../../utils/ui'
import { DialogConfirmButton } from '../../../shared/components/dialogConfirmButton'
import { TagTypeSelect } from '../../../shared/components/tagTypeSelect'
import { getMainTenantId } from '../../../config'
import { AdminEditModel, fetchTenants } from '../../../redux/actions/tenants'
import { DialogProgress } from '../../../shared/components/dialogProgress'

const mainTenantId = getMainTenantId()

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiFilledInput-root': {
            borderRadius: theme.spacing(0.5),
        },
        '& .MuiFilledInput-root::before': {
            border: 0,
        },
    },
    dialogActions: {
        padding: theme.spacing(1, 3, 3, 3),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'hidden',
    },
}))

interface TagAddEditDialogProps {
    open: boolean
    isAdd: boolean
    onClose(tag?: TagModel): void
    initialModel?: TagModel
}
const TagAddEditDialog: SFC<TagAddEditDialogProps> = ({ open, isAdd, onClose, initialModel }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const tenantId = useSelector((state: any) => state.login.value.user.id)
    const tenantsState = useSelector((state: any) => state.tenants)

    const stateSelector = isAdd ? (state: any) => state.tags.add : (state: any) => state.tags.edit

    useEffect(() => {
        dispatch(fetchTenants())
    }, [dispatch])

    return (
        <Dialog className={classes.dialog} open={open}>
            <DialogProgress stateSelector={stateSelector} />
            <DialogTitle>{isAdd ? t('tags.newTag') : t('tags.editTag')}</DialogTitle>

            <Form
                onSubmit={async (model) => {
                    model = {
                        ...model,
                        tenantId: model.tenantId ? model.tenantId : tenantId
                    }
                    const result: any = await dispatch(isAdd ? addTag(model as TagModel) : editTag(model as TagModel))
                    if (result) {
                        onClose(result)
                    }
                }}
                initialValues={initialModel}
                validate={(model: any): ValidationErrors => {
                    const result: any = {}

                    let requiredFields = ['name', 'type']
                    if (tenantId && tenantId === mainTenantId) {
                        requiredFields.push('tenantId')
                    }
                    setRequiredErrors(result, model, requiredFields, t('common.required'))
                    return result
                }}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                        <DialogContent>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        name="name"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('tags.name')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TagTypeSelect
                                        name="type"
                                        required
                                        label={t('tags.type')}
                                        variant="filled"
                                        startValue={initialModel?.type}
                                    ></TagTypeSelect>
                                </Grid>
                                <Grid hidden={!(tenantId && tenantId === mainTenantId)} item xs={12}>
                                    <Select
                                        required
                                        name="tenantId"
                                        variant="filled"
                                        label={t('common.tenant')}
                                    >
                                        {tenantsState.value
                                            ?.filter((item: AdminEditModel) => item.id !== mainTenantId)
                                            .map((item: AdminEditModel) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.login}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="description"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 128,
                                        }}
                                        type="text"
                                        variant="filled"
                                        label={t('tags.description')}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={() => {
                                    onClose()
                                }}
                                color="primary"
                            >
                                {t('common.cancel')}
                            </Button>
                            <DialogConfirmButton
                                label={isAdd ? t('common.create') : t('common.change')}
                                stateSelector={stateSelector}
                            />
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    )
}

export default TagAddEditDialog
