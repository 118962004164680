import React, { useState } from 'react'
import { Form } from 'react-final-form'
import Typography from '@material-ui/core/Typography'
import { Button, Grid, Checkbox } from '@material-ui/core'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import { filterStyles } from './FilterStyles'
import { useSelector, useDispatch } from 'react-redux'
import {
    filterByChargerId,
    filterHearthBeat,
    filterByFrom,
    filterByTill,
    filterByType,
    filterShowRaw,
getFetchLogsUrl,
} from '../../../redux/actions/logs'
import DatePicker from 'react-datepicker'
import { OptionsType, ValueType } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { exportFile } from '../../../shared/utils/export'
import { config, getToken } from '../../../config'
import { getOffset } from '../../../shared/utils/localTime'

export default function LogsFilter() {
    const { t } = useTranslation()
    const classes = filterStyles()
    const dispatch = useDispatch()

    const logsFilter = useSelector((state: any) => state.logs.filter)
    const allLogs = useSelector((state: any) => state.logs.items)

    const [startDate, setStartDate] = useState(logsFilter.from.value)
    const [endDate, setEndDate] = useState(logsFilter.till.value)
    const [chargerId, setChargerId] = useState(logsFilter.chargerId.value || '')
    const [type, setType] = useState(logsFilter.type.value)
    const [showHeartbeat, setShowHeartbeat] = useState(logsFilter.showHeartbeat.value)
    const [showRaw, setShowRaw] = useState(logsFilter.showRaw.value)

    const onFormSubmit = () => {
        dispatch(filterByChargerId(chargerId))
        dispatch(filterByType(type))
        dispatch(filterByFrom(startDate))
        dispatch(filterByTill(endDate))
        dispatch(filterHearthBeat(showHeartbeat))
        dispatch(filterShowRaw(showRaw))
    }
    const handleFilterReset = () => {
        setChargerId('')
        setType('')
        setStartDate(null)
        setEndDate(null)
        setShowHeartbeat(true)

        dispatch(filterByChargerId(null))
        dispatch(filterByType(''))
        dispatch(filterByFrom(null))
        dispatch(filterByTill(null))
        dispatch(filterHearthBeat(true))
        dispatch(filterShowRaw(true))
    }

    const handleFilteredDataDownload = () => {
        const url = 'DiagnosticsAdmin/diagnostic/txt?' + getFetchLogsUrl(1, allLogs.value.count, logsFilter)
        exportFile(config.proxy, config.api, url, getToken(), getOffset())
    }

    const handleChargerIdChanged = (newChargerId: any) => {
        setChargerId(newChargerId.target.value)
    }

    const handleTypeChanged = (option: ValueType<LogTypeOption>) => {
        const type = option ? (option as LogTypeOption).value : ''
        setType(type)
        dispatch(filterByType(type))
    }

    const handleSearchFromChange = (newFrom: any) => {
        setStartDate(newFrom)
        dispatch(filterByFrom(newFrom))
    }

    const handleSearchTillChange = (newTill: any) => {
        setEndDate(newTill)
        dispatch(filterByTill(newTill))
    }

    const handleShowHeartbeatChange = () => {
        setShowHeartbeat(!showHeartbeat)
        dispatch(filterHearthBeat(!showHeartbeat))
    }

    const handleShowRawChange = () => {
        setShowRaw(!showRaw)
        dispatch(filterShowRaw(!showRaw))
    }

    interface LogTypeOption {
        value: string
        label: string
    }

    // Taken from c# enum ChargingStations.Domain.CentralSystem.Shared.MessageType
    const logTypeOptions: OptionsType<LogTypeOption> = [
        { value: 'None', label: 'None' },
        { value: 'Response', label: 'Response' },
        { value: 'Authorize', label: 'Authorize' },
        { value: 'BootNotification', label: 'BootNotification' },
        { value: 'Heartbeat', label: 'Heartbeat' },
        { value: 'StatusNotification', label: 'StatusNotification' },
        { value: 'MeterValues', label: 'MeterValues' },
        { value: 'StartTransaction', label: 'StartTransaction' },
        { value: 'StopTransaction', label: 'StopTransaction' },
        { value: 'RemoteStopTransaction', label: 'RemoteStopTransaction' },
        { value: 'RemoteStartTransaction', label: 'RemoteStartTransaction' },
        { value: 'ReopenTransaction', label: 'ReopenTransaction' },
        { value: 'Reset', label: 'Reset' },
        { value: 'UnlockConnector', label: 'UnlockConnector' },
        { value: 'ChangeAvailability', label: 'ChangeAvailability' },
        { value: 'SendLocalList', label: 'SendLocalList' },
        { value: 'GetDiagnostics', label: 'GetDiagnostics' },
        { value: 'GetConfiguration', label: 'GetConfiguration' },
        { value: 'DiagnosticsStatusNotification', label: 'DiagnosticsStatusNotification' },
        { value: 'ChangeConfiguration', label: 'ChangeConfiguration' },
        { value: 'SetChargingProfile', label: 'SetChargingProfile' },
        { value: 'SystemMaintenance', label: 'SystemMaintenance' },
        { value: 'Payment', label: 'Payment' },
        { value: 'Connection', label: 'Connection' },
        { value: 'Payload', label: 'Payload' },
        { value: 'TransactionStorage', label: 'TransactionStorage' },
        { value: 'Hubject', label: 'Hubject' },
        { value: 'Raw', label: 'Raw' },
        { value: 'InternalError', label: 'InternalError' },
        { value: 'C_to_B_Request', label: 'C_to_B_Request' },
        { value: 'B_to_C_Request', label: 'B_to_C_Request' },
        { value: 'C_to_B_Response', label: 'C_to_B_Response' },
        { value: 'B_to_C_Response', label: 'B_to_C_Response' },
    ]

    return (
        <Grid container className={classes.formWrapper}>
            <Grid item xs={12}>
                <Form
                    onSubmit={onFormSubmit}
                    render={({ handleSubmit, form }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container>
                                <Grid item xs={3} className={classes.filter}>
                                    <input
                                        id="chargerId"
                                        className={classes.searchField}
                                        type="text"
                                        name="chargerId"
                                        placeholder={t('logs.chargerId')}
                                        onChange={handleChargerIdChanged}
                                        value={chargerId}
                                    />
                                </Grid>
                                <Grid item xs={3} className={classes.filter}>
                                    <CreatableSelect
                                        // isMulti
                                        isClearable
                                        name="type"
                                        options={logTypeOptions}
                                        className={classes.searchField}
                                        onChange={handleTypeChanged}
                                        value={logTypeOptions.find((v) => v.value === type) || null}
                                    />
                                </Grid>
                                <Grid item xs={1} className={classes.filterTexts}>
                                    <Typography align="left" variant="inherit" className={classes.filterTexts}>
                                        {t('logs.from')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.filter}>
                                    <DatePicker
                                        className={classes.searchField}
                                        onChange={handleSearchFromChange}
                                        selected={startDate}
                                        dateFormatCalendar="dd/MM/yyyy"
                                        placeholderText="dd/MM/yyyy"
                                    />
                                </Grid>
                                <Grid item xs={1} className={classes.filterTexts}>
                                    <Typography align="left" variant="inherit" className={classes.filterTexts}>
                                        {t('logs.to')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} className={classes.filter}>
                                    <DatePicker
                                        className={classes.searchField}
                                        onChange={handleSearchTillChange}
                                        selected={endDate}
                                        dateFormatCalendar="dd/MM/yyyy"
                                        placeholderText="dd/MM/yyyy"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container style={{ paddingTop: '8px' }}>
                                <Grid item xs={3} className={classes.filter}>
                                    <Typography align="left" variant="inherit">
                                        {t('logs.showHeartbeat')}
                                        <Checkbox
                                            name="showHiddenLocation"
                                            checked={showHeartbeat}
                                            onChange={handleShowHeartbeatChange}
                                            style={{
                                                color: '#6100ED',
                                                marginBottom: '4px',
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.filter}>
                                    <Typography align="left" variant="inherit">
                                        {t('logs.showRaw')}
                                        <Checkbox
                                            name="showHiddenLocation"
                                            checked={showRaw}
                                            onChange={handleShowRawChange}
                                            style={{
                                                color: '#6100ED',
                                                marginBottom: '4px',
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} className={classes.filterButtonWrapper}>
                                    <Button onClick={handleFilterReset} variant="contained" color="secondary">
                                        {t('logs.clearFilter')}
                                    </Button>
                                </Grid>
                                <Grid item xs={1} className={classes.filterButtonWrapper}>
                                    <Button variant="contained" color="primary" type="submit">
                                        {t('history.filter')}
                                    </Button>
                                </Grid>
                                <Grid item xs={4} className={classes.filterButtonWrapper}>
                                    <Button onClick={handleFilteredDataDownload} variant="contained" color="primary">
                                        {t('logs.download')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
            </Grid>
        </Grid>
    )
}
