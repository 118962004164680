import React, { SFC, useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LocationModel, LocationInfo } from '../../shared/types/location'
import { LocationMarker, AddMarker } from './Markers'
import { GoogleMap, LoadScript, MarkerClusterer } from '@react-google-maps/api'
import { makeStyles } from '@material-ui/core'
import { Coordinates } from '../../shared/types/common'
import { fetchMapLocations } from '../../redux/actions/locations'

const useStyles = makeStyles((theme) => ({
    map: {
        height: '100%',
        width: `calc(100% - ${theme.spacing(1)})`,
        paddingLeft: theme.spacing(1),
    },
}))

const defaultSettings = {
    center: {
        lat: 48.144892,
        lng: 17.107137,
    },
    zoom: 16,
}

interface MapProps {
    center?: Coordinates
    onCenterChanged(center: Coordinates): void
    onLocationSelected(location: LocationInfo): void
    adding: boolean
    onAddEnd(model?: LocationModel): void
}
const Map: SFC<MapProps> = ({ center, onCenterChanged, onLocationSelected, adding, onAddEnd }) => {
    const classes = useStyles()
    const mapRef = useRef<any>(null)

    const dispatch = useDispatch()

    const mapToken = useSelector((state: any) => state.mapToken.value)
    const tenantId = useSelector((state: any) => state.login.value.user.id)
    const locationsState = useSelector((state: any) => state.locations.mapItems)
    const search = useSelector((state: any) => state.locations.search.value)
    const [initialized, setInitialized] = useState<boolean>(false)

    const setMapBounds = useCallback(() => {
        if (locationsState.value.length === 1) {
            const map = mapRef.current
            map.setCenter(
                new (window as any).google.maps.LatLng(
                    locationsState.value[0].latitude,
                    locationsState.value[0].longitude
                )
            )
            map.setZoom(defaultSettings.zoom)
        } else if (locationsState.value.length > 0) {
            const map = mapRef.current
            const bounds = new (window as any).google.maps.LatLngBounds()
            locationsState.value.forEach((x: LocationInfo) =>
                bounds.extend(new (window as any).google.maps.LatLng(x.latitude, x.longitude))
            )

            map.fitBounds(bounds)
        }
    }, [locationsState.value])

    const initializeMapIfNecessary = useCallback(() => {
        if (!initialized && locationsState.executed && mapRef.current) {
            const map = mapRef.current
            if (locationsState.value.length === 0) {
                map.setCenter(
                    new (window as any).google.maps.LatLng(defaultSettings.center.lat, defaultSettings.center.lng)
                )
                map.setZoom(defaultSettings.zoom)
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((x) => {
                        map.setCenter(new (window as any).google.maps.LatLng(x.coords.latitude, x.coords.longitude))
                        map.setZoom(defaultSettings.zoom)
                    })
                }
            } else {
                setMapBounds()
            }
        }
        setInitialized(true)
    }, [initialized, locationsState.executed, locationsState.value, mapRef, setMapBounds])

    const onLoad = (map: any) => {
        mapRef.current = map
        initializeMapIfNecessary()
    }

    useEffect(() => {
        initializeMapIfNecessary()
    }, [initializeMapIfNecessary, locationsState, mapRef])

    useEffect(() => {
        if (mapRef.current) setMapBounds()
    }, [locationsState.value, setMapBounds])

    useEffect(() => {
        if (initialized && center && mapRef.current) {
            const currentCenter = mapRef.current.getCenter().toJSON()
            if (currentCenter.lat !== center.lat || currentCenter.lng !== center.lng) {
                mapRef.current.setCenter(new (window as any).google.maps.LatLng(center.lat, center.lng))
                mapRef.current.setZoom(defaultSettings.zoom)
            }
        }
    }, [center, mapRef, initialized])

    useEffect(() => {
        dispatch(fetchMapLocations(search, tenantId))
    }, [dispatch, search, tenantId])

    return (
        <div className={classes.map}>
            <LoadScript googleMapsApiKey={mapToken}>
                <GoogleMap
                    onLoad={onLoad}
                    onCenterChanged={() => {
                        if (mapRef.current) {
                            onCenterChanged(mapRef.current.getCenter().toJSON())
                        }
                    }}
                    options={{
                        fullscreenControl: false,
                        mapTypeControl: false,
                        streetViewControl: false,
                    }}
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                >
                    {adding && center ? (
                        <AddMarker {...center} onAddEnd={onAddEnd} />
                    ) : (
                        <MarkerClusterer
                            maxZoom={14}
                            calculator={(markers: any) => ({ text: markers.length, index: 6, title: '' })}
                        >
                            {(clusterer) =>
                                locationsState.value.map((location: LocationInfo) => (
                                    <LocationMarker
                                        key={location.id}
                                        location={location}
                                        onClick={() => onLocationSelected(location)}
                                        clusterer={clusterer}
                                    />
                                ))
                            }
                        </MarkerClusterer>
                    )}
                </GoogleMap>
            </LoadScript>
        </div>
    )
}

export default Map
