import { makeStyles } from '@material-ui/core'

export const historyStyles = makeStyles((theme: any) => {
  return {
    headerWrapper: {
      padding: theme.spacing(1),
      height: theme.history.titleHeight,
    },
    exportButton: {
      position: 'absolute',
      left: '50%',
      right: '50%',
      bottom: '10px',
      width: 'fit-content',
    },
    exportButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: theme.history.buttonWrapperHeight,
    },
  }
})

