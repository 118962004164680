import { makeStyles } from '@material-ui/core'

export const filterStyles = makeStyles((theme: any) => {
    return {
        formWrapper: {
            justifyContent: 'space-between',
            height: theme.logs.filterHeight,
            alignItems: 'center',
        },
        searchWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        filter: {
            paddingLeft: theme.spacing(1),
        },
        filterTexts: {
            paddingTop: '10px',
            paddingLeft: theme.spacing(1),
        },
        filterButtonWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: theme.spacing(1),
        },
        datePicker: {
            width: '100px',
        },
        searchField: {
            width: '95%',
            color: '#000000',
            '& ::placeholder': {
                color: '#ededed',
            },
            border: '1px solid #EBEBEB',
            borderRadius: '4px',
            backgroundColor: '#F5F5F5',
            height: '35px',
            marginRight: '10px',
        },
    }
})
