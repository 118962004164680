import React, { SFC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, CircularProgress, Card, CardContent, Typography, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SearchBar from '../../../core/searchBar/SearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { searchTags } from '../../../redux/actions/tags'
import { useCalcHeightToBottomPartialList } from '../../../utils/ui'
import { NoData } from '../../../shared/components/NoData'
import { PricingConditionModel } from '../../../shared/types/pricingCondition'

const useStyles = makeStyles((theme) => ({
    list: {
        overflowY: 'auto',
        marginTop: theme.spacing(3),
    },
    listItem: {
        margin: theme.spacing(1, 1, 2, 1),
        display: 'flex',
        '& .Mui-selected': {
            borderBottom: `3px solid ${theme.palette.primary.main}`,
            paddingBottom: theme.spacing(2) - 3,
        },
    },
    listItemButton: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'start',
        padding: theme.spacing(2),
    },
    listContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    listContent: {
        flex: '1 0 auto',
        textAlign: 'left',
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
    },
    search: {
        paddingLeft: theme.spacing(1),
    },
    progress: {
        textAlign: 'center',
    },
    dataInfoWrapper: {
        textAlign: 'center',
        paddingLeft: theme.spacing(1),
    },
}))

interface PricingConditionListProps {
    pricingConditions: PricingConditionModel[]
    selectedPricingCondition?: PricingConditionModel
    onPricingConditionSelected(pricingCondition: PricingConditionModel): void
}
const PricingConditionList: SFC<PricingConditionListProps> = ({ pricingConditions, selectedPricingCondition, onPricingConditionSelected }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const pricingConditionsExecuted = useSelector((state: any) => state.pricingConditions.items.executed)

    const search = useSelector((state: any) => state.pricingConditions.search.value)

    const handleSearchChanged = (search: string) => {
        dispatch(searchTags(search))
    }

    const listHeightFill = useCalcHeightToBottomPartialList('listWrapper')

    return (
        <>
            <Grid container>
                <Grid item xs={10} className={classes.search}>
                    <SearchBar placeholder={t('pricingConditions.search')} value={search} onChange={handleSearchChanged} />
                </Grid>
                <Grid item xs={2} className={classes.progress}>
                    {!pricingConditionsExecuted && <CircularProgress size={30} />}
                </Grid>
                <Grid id="listWrapper" item xs={12} className={classes.list} ref={listHeightFill}>
                    {pricingConditionsExecuted && pricingConditions.length === 0 ? (
                        <div className={classes.dataInfoWrapper}>
                            <NoData text={t('pricingConditions.noPricingConditions')} />
                        </div>
                    ) : (
                        pricingConditions.map((pricingCondition) => (
                            <Card key={pricingCondition.id} className={classes.listItem} elevation={1}>
                                <ListItem
                                    button
                                    selected={pricingCondition === selectedPricingCondition}
                                    onClick={() => onPricingConditionSelected(pricingCondition)}
                                    className={classes.listItemButton}
                                >
                                    <div className={classes.listContentWrapper}>
                                        <CardContent className={classes.listContent}>
                                            <Typography variant="h6">{pricingCondition.name}</Typography>
                                        </CardContent>
                                    </div>
                                </ListItem>
                            </Card>
                        ))
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default PricingConditionList
