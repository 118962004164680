import { Grid, Snackbar, SnackbarOrigin, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { adminSettingsStyles } from './AdminSettingsStyles'
import Button from '@material-ui/core/Button'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { deleteLogo, updateAdmin, updateLogo, updatePassword } from '../../redux/actions/tenants'
import { setUser } from '../../shared/redux/actions/common'
import { config } from '../../config'
import EyeIcon from '../../shared/static/icon-eye-white.svg'
import EyeOffIcon from '../../shared/static/icon-eye-off-white.svg'

const allowedTypes = ['image/jpeg', 'image/png', 'image/gif']
const validatePass = (pass: string) => /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(pass)

const AdminSettings = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const classes = adminSettingsStyles()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [successMsg, setSuccessMsg] = useState<string>()
    const admin = useSelector((state: any) => state.login.value.user)
    const adminUserAboutPage = useSelector((state: any) => state.login.value.user.aboutPage)
    const adminContact = useSelector((state: any) => state.login.value.user.contact)
    const adminLogo = useSelector((state: any) => state.login.value.user.logo)
    const adminTexts = useSelector((state: any) => state.login.value.user.texts)
    const id = useSelector((state: any) => state.login.value.user.id)
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [aboutPage, setAboutPage] = useState(adminUserAboutPage || '')
    const [contact, setContact] = useState(adminContact || '')
    const [texts, setTexts] = useState(adminTexts || '')
    const [file, setFile] = useState<any | null>(null) // Change "null" to use "logo" url when backend is ready
    const [preview, setPreview] = useState<any | null>(null)
    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>()
    const [showNewPassword, setShowNewPassword] = useState<boolean>()

    const snackBar = (msg: string, onClose: () => void, type: 'error' | 'success') => {
        const position =
            type === 'success'
                ? { vertical: 'top', horizontal: 'center' }
                : { vertical: 'bottom', horizontal: 'center' }
        return (
            <Snackbar anchorOrigin={position as SnackbarOrigin} open={true} autoHideDuration={5000} onClose={onClose}>
                <Alert onClose={onClose} severity={type}>
                    {msg}
                </Alert>
            </Snackbar>
        )
    }
    const handleFileChange = (ev: any) => {
        const file = ev.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                if (!allowedTypes.includes(file.type)) {
                    setErrorMessage(t('adminSettings.errorLogoType'))
                    return
                }

                setFile(file)
                setPreview(URL.createObjectURL(file))
            }
            reader.readAsArrayBuffer(file)
        }
    }

    const handleDeleteLogo = () => {
        setFile(null)
        setPreview(null)
    }

    const changeNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword)
    }

    const changeCurrentPasswordVisibility = () => {
        setShowCurrentPassword(!showCurrentPassword)
    }

    const handleSave = async () => {
        if (aboutPage && !aboutPage.startsWith('https://') && !aboutPage.startsWith('http://')) {
            setErrorMessage('About page must starts with https:// or http://')
            return
        }
        // if (currentPassword) {
        //     if (!validatePass(newPassword)) {
        //         setErrorMessage(t('adminSettings.errorPassValidation'))
        //         return
        //     }
        // }

        if (aboutPage !== adminUserAboutPage || texts !== adminTexts || contact !== adminContact) {
            const adminBody = {
                ...admin,
                aboutPage,
                contact,
                texts,
            }
            delete adminBody['password']

            const updatedUser = await updateAdmin(id, adminBody).catch(() =>
                setErrorMessage(t('adminSettings.errorUpdatingAbout'))
            )
            if (updatedUser) dispatch(setUser(updatedUser))
        }

        if (file) {
            const logo = await updateLogo(file).catch(() => setErrorMessage(t('adminSettings.errorUpdatingLogo')))
            if (logo) dispatch(setUser({ ...admin, logo }))
        }

        if (newPassword) {
            const data = {
                currentPassword,
                newPassword,
            }
            await updatePassword(data).catch(() => setErrorMessage(t('adminSettings.errorUpdatingPass')))
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} className={classes.headerWrapper}>
                    <Typography align="left" variant="h3">
                        {t('adminSettings.title')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    <Grid className={classes.headerWrapper}>
                        <Typography align="left" variant="h6">
                            {t('adminSettings.changePass')}
                        </Typography>
                    </Grid>
                    <Grid container className={classes.passwordSection}>
                        <Grid item xs={6} className={classes.passwordWrapper}>
                            <Grid>
                                <TextField
                                    name="Current password"
                                    className={classes.currPass}
                                    type={currentPassword && !showCurrentPassword ? 'password' : 'text'}
                                    variant="filled"
                                    label={t('adminSettings.currentPass')}
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />
                            </Grid>
                            {currentPassword ? (
                                <Grid item>
                                    <div onClick={changeCurrentPasswordVisibility}>
                                        {showCurrentPassword ? (
                                            <img alt="Eye" src={EyeIcon} className={classes.inputIconRight} />
                                        ) : (
                                            <img alt="Eye" src={EyeOffIcon} className={classes.inputIconRight} />
                                        )}
                                    </div>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                        <Grid item xs={6} className={classes.passwordWrapper}>
                            <Grid>
                                <TextField
                                    disabled={!currentPassword}
                                    className={classes.newPass}
                                    name="New password"
                                    type={newPassword && !showNewPassword ? 'password' : 'text'}
                                    variant="filled"
                                    label={t('adminSettings.newPass')}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </Grid>
                            {newPassword ? (
                                <Grid item>
                                    <div onClick={changeNewPasswordVisibility}>
                                        {showNewPassword ? (
                                            <img alt="Eye" src={EyeIcon} className={classes.inputIconRight} />
                                        ) : (
                                            <img alt="Eye" src={EyeOffIcon} className={classes.inputIconRight} />
                                        )}
                                    </div>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Grid>
                    <Grid className={classes.headerWrapper}>
                        <Typography align="left" variant="h6">
                            Custom logo and about page
                        </Typography>
                    </Grid>
                    <Grid className={classes.image}>
                        {file || adminLogo?.url ? (
                            <img
                                alt=""
                                src={(file && preview) || `${config.api}${adminLogo?.url}`}
                                className={classes.logo}
                            />
                        ) : (
                            ''
                        )}
                    </Grid>
                    <Grid className={classes.section}>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="contained-button-file">
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                component="span"
                                startIcon={<PhotoCamera />}
                            >
                                {t('adminSettings.upload')}
                            </Button>
                        </label>
                        {file || adminLogo?.url ? (
                            <label htmlFor="contained-button-file">
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    color="secondary"
                                    component="span"
                                    onClick={() => {
                                        if (adminLogo?.url) {
                                            dispatch(deleteLogo(adminLogo?.id))
                                            dispatch(setUser({ ...admin, logo: {} }))
                                        }
                                        if (file) handleDeleteLogo()
                                    }}
                                >
                                    {t('adminSettings.delete')}
                                </Button>
                            </label>
                        ) : (
                            ''
                        )}
                    </Grid>
                    <Grid className={classes.section}>
                        <TextField
                            className={classes.texts}
                            name="about"
                            inputProps={{
                                maxLength: 1000,
                            }}
                            type="text"
                            variant="filled"
                            label={t('adminSettings.about')}
                            placeholder="https://"
                            value={aboutPage}
                            onChange={(e) => setAboutPage(e.target.value)}
                        />
                    </Grid>
                    <Grid className={classes.section}>
                        <TextField
                            className={classes.texts}
                            name="about"
                            inputProps={{
                                maxLength: 1000,
                            }}
                            type="text"
                            variant="filled"
                            label={t('adminSettings.contact')}
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                        />
                    </Grid>
                    <Button
                        className={classes.saveBtn}
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={handleSave}
                    >
                        {t('adminSettings.save')}
                    </Button>
                </Grid>
                <Grid item xs={7} className={classes.textWrapper}>
                    <Grid className={classes.headerWrapper}>
                        <Typography align="left" variant="h6">
                            Custom texts
                        </Typography>
                    </Grid>
                    <Grid className={classes.section}>
                        <TextField
                            multiline
                            className={classes.texts}
                            name="texts"
                            type="text"
                            variant="filled"
                            value={texts}
                            onChange={(e) => setTexts(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {errorMessage && snackBar(errorMessage, () => setErrorMessage(undefined), 'error')}
            {successMsg && !errorMessage && snackBar(successMsg, () => setSuccessMsg(undefined), 'success')}
        </>
    )
}

export default AdminSettings
