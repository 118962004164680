import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import store from './redux/store'
import ErrorDialog from './core/errorDialog/ErrorDialog'
import { CircularProgress, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import AppRouter from './AppRouter'
import { theme } from './shared/theme/theme'
import MomentUtils from '@date-io/moment'
import './app.scss'

const useStyles = makeStyles((theme) => ({
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}))

const cssVariables = {
    history: {
        filterHeight: '80px',
        titleHeight: '41px',
        paginationHeight: '52px',
        buttonWrapperHeight: '36px',
    },
    logs: {
        filterHeight: '80px',
        titleHeight: '41px',
        paginationHeight: '52px',
        buttonWrapperHeight: '36px',
    },
}

export default function App() {
    const classes = useStyles()
    return (
        <Suspense fallback={<CircularProgress className={classes.loader} />}>
            <ThemeProvider theme={{ ...theme, ...cssVariables }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Provider store={store}>
                        <div className="App">
                            <AppRouter />
                            <ErrorDialog />
                        </div>
                    </Provider>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </Suspense>
    )
}
