export type TariffModel = {
	id: string;
	name: string;
	note: string;
	active: boolean;
	type: number;
	rows: TariffRowModel[];
	tenantId: string;
};

export type TariffRowModel = {
	id?: string;
	tariffId?: string;
	dayFrom: Day;
	from: any | null;
	dayTo: Day;
	to: any | null;
};

export enum Day {
	Monday = 1,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday,
	Sunday
}