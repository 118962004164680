import { asyncActionCreator } from './../../shared/redux/actions/common'
import { FETCH_TARIFFS, ADD_TARIFF, EDIT_TARIFF, TOGGLE_ACTIVE_TARIFF, FETCH_TARIFFS_LOCATIONS } from '../const'
import { getJson, sendJson, patchProperty } from '../../shared/utils/api'
import { TariffModel } from '../../shared/types/tariff'

export const addTariff = (tariff: TariffModel) => {
    return asyncActionCreator(
        ADD_TARIFF,
        () =>
            sendJson('tariff', 'POST', {
                ...tariff,
            }),
        {},
        [['0', 'tariffs.tariffWithThatNameExists']]
    )
}

export const editTariff = (tariff: TariffModel) => {
    return asyncActionCreator(
        EDIT_TARIFF,
        () =>
            sendJson(`tariff/${tariff.id}`, 'PATCH', [
                patchProperty('name', tariff.name),
                patchProperty('note', tariff.note),
                patchProperty('active', tariff.active),
                patchProperty('type', tariff.type),
                patchProperty('tenantId', tariff.tenantId),
                patchProperty('rows', tariff.rows),
            ]),
        {},
        [['1', 'tariffs.tariffsIntersectionOfORws']]
    )
}

export const toggleActiveTariff = (tariffId: string, active: boolean) => {
    return asyncActionCreator(
        TOGGLE_ACTIVE_TARIFF,
        () => sendJson(`tariff/${tariffId}?active=${!active}`, 'POST'),
        { tariffId },
        [
            ['0', 'tariffs.tariffAssignedToMoreLocations'],
            ['1', 'tariffs.tariffsIntersectionOfORws'],
        ]
    )
}

export const fetchTariffsPricingConditions = (tariffId: string) => {
    return asyncActionCreator(FETCH_TARIFFS_LOCATIONS, () => getJson(`tariff/${tariffId}/pricingconditions`))
}

export const fetchTariffs = (tenantId: string) => {
    let url = `tariff?tenantId=${tenantId}`

    return asyncActionCreator(FETCH_TARIFFS, () => getJson(url))
}
